export const en = {
  rival_rules: {
    rival_rules: 'Rival Rules',
    rivals: 'Rivals',
    rival: 'Rival',
    new_rival_rule: 'New Rival Rule',
    add_new_rule: 'Add New Rule',
    set_rival_rules: 'Set Rival Rule',
    remove_rival_rules: 'Remove Rival Rule',
    add_new_rival: 'Add rival',
    rival_time_window: 'Rival Time Window',
    loading_rules: 'Loading Rival Rules',
    loading: 'Loading',
    no_rules: 'No Rival Rules',
    no_rules_description: 'There are no Rival Rules present in the system. Rules allow you to perform actions based on your main rivals.',
    amount_of_rivals: 'Number of Rivals',
    general: 'General',
    display_name: 'Display Name',
    display_name_placeholder: 'Max 20 Characters',
    all_rivals_selected_title: 'All rivals are already selected',
    all_rivals_selected: 'You can configure the carrier in the list below. Are you missing any carriers? Please contact support.',
    no_rivals_available_title: 'No carriers available',
    no_rivals_available: 'There are no carriers available to select as rivals. Please contact support if you think this is incorrect.',
    unique_rival_rule_name_needed: 'Rule should have a unique name',
    rival_rule_empty_list: 'Rule should have at least one rival rule attached to it',
    ow_rt: 'OW/RT',
    one_way: 'One Way',
    round_trip: 'Round Trip',
    no_preference: 'No Preference',
    time_window: 'Time Window',
    stopover: 'Stopover',
    loading_rivals: 'Loading...',
  },
};
