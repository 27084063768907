import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { PricingTactic } from '@/modules/shared/models/PricingTacticManagement';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface SetPricingTacticPayload extends BaseFlightActionPayload {
  cabinCode: CabinCode;
  pricingTactic: PricingTactic;
}

export class SetPricingTacticAction implements FlightAction<SetPricingTacticPayload> {
  public actionType: FlightActionType = FlightActionType.setPricingTactic;
  public label = t('pricing_tactic');
  public value = PricingTactic.Manual;
  public cabinCode: CabinCode;

  constructor(cabinCode?: CabinCode, pricingTactic?: PricingTactic) {
    this.cabinCode = cabinCode;
    this.value = pricingTactic ?? PricingTactic.Manual;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.pricingTactic,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.setPricingTactic,
        field: FilterFieldField.pricingTactic,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.pricingTactic,
        uniqueField: this.cabinCode,
        isValueValid: () => true,
        componentName: ComponentName.PricingTacticSelect,
        value: this.value,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
    this.cabinCode = action.uniqueField as CabinCode;
  }

  public setPayload(payload: SetPricingTacticPayload): void {
    this.value = payload.pricingTactic;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): SetPricingTacticPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      pricingTactic: this.value,
    };
  }
}
