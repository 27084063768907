import { cloneDeep, isEqual, isFinite, isNil } from 'lodash-es';
import { Ref, computed, ref } from 'vue';

import { EddyBaseError } from '@/modules/api/base-client';
import { rivalRulesService } from '@/modules/rules/rival-rules/api/rival-rules.service';
import { RivalRuleDetailModel, RivalRuleModel } from '@/modules/rules/rival-rules/models/rival-rule.model';
import { MessageService } from '@/modules/shared';
import { ErrorCode } from '@/modules/shared/types/error-codes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

const rivalRules = new Map<number, RivalRuleModel>();

interface RivalRulesState {
  isLoadingRivalRule: boolean;
  isLoadingRivalRules: boolean;
  rivalRule: RivalRuleModel;
  rivalRules: RivalRuleModel[];
  rivalRuleUnchanged?: RivalRuleModel;
}

const globalRivalRuleState: Ref<RivalRulesState> = ref({
  isLoadingRivalRule: false,
  isLoadingRivalRules: false,
  rivalRule: undefined,
  rivalRules: [],
  rivalRuleUnchanged: undefined,
});

export function useGlobalRivalRule(): ReturnType<typeof rivalRuleLoader> {
  return rivalRuleLoader(globalRivalRuleState);
}

export function useRivalRule(): ReturnType<typeof rivalRuleLoader> {
  const rivalRuleState = ref<RivalRulesState>({
    isLoadingRivalRule: false,
    isLoadingRivalRules: false,
    rivalRule: undefined,
    rivalRules: [],
    rivalRuleUnchanged: undefined,
  });

  return rivalRuleLoader(rivalRuleState);
}

/**
 * A loader that helps to manage a Rival Rule. It has a local state, so calling this function will create a new state, managed by that instance.
 * @returns composable functions and computed properties related to managing the state of a Rival Rule
 */
function rivalRuleLoader(rivalRuleState: Ref<RivalRulesState>) {
  /**
   * Gets all Rival Rules from the API.
   * @returns RivalRuleModel[]
   */
  async function getAll(): Promise<RivalRuleModel[]> {
    try {
      rivalRuleState.value.isLoadingRivalRules = true;
      const response = await rivalRulesService.getAll();
      rivalRuleState.value.rivalRules = response;

      return response;
    } catch (e) {
      MessageService.failedRequest();
    } finally {
      rivalRuleState.value.isLoadingRivalRules = false;
    }
  }

  function updateRivalRuleState(rule: RivalRuleModel): void {
    rivalRuleState.value.rivalRule = rule;
    rivalRuleState.value.rivalRuleUnchanged = cloneDeep(rule);
  }

  async function loadRivalRule(id: number, forceNetwork = false): Promise<void> {
    if (!forceNetwork && rivalRules.has(id)) {
      const rule = rivalRules.get(id);
      updateRivalRuleState(rule as RivalRuleModel);
    } else {
      try {
        const rule = await rivalRulesService.getById(id);
        updateRivalRuleState(rule);
        rivalRules.set(id, rule);
      } catch (e) {
        MessageService.failedRequest();
      }
    }
  }

  /**
   * Revert the changes made by loading a fresh version of the Rule from the API.
   */
  async function revertChanges(): Promise<void> {
    if (!isNil(rivalRuleState.value.rivalRule)) {
      await loadRivalRule(rivalRuleState.value.rivalRule.id, true);
    }
  }

  /** Checks if the model has been changed, and returns true if that's the case  */
  function hasChanges(): boolean {
    return !isEqual(rivalRuleState.value.rivalRule, rivalRuleState.value.rivalRuleUnchanged);
  }

  function removeRuleByIndex(index: number): void {
    rivalRuleState.value.rivalRule.details.splice(index, 1);
  }

  /**
   * Creates a new, empty Rival Rule Model.
   */
  function loadNewRivalRule(): void {
    rivalRuleState.value.rivalRule = RivalRuleModel.new();
    rivalRuleState.value.rivalRuleUnchanged = undefined;
  }

  function addNewRivalRuleDetails(rivalCarrierCode?: string): void {
    if (!rivalCarrierCode) {
      MessageService.error(t('form.validate_name'));
      return;
    }
    // Add them to the top of the list so the user sees them first when the list is becoming long.
    rivalRuleState.value.rivalRule.details.unshift(
      new RivalRuleDetailModel({
        rivalCarrierCode,
        rivalTimeWindowLower: -240,
        rivalTimeWindowUpper: 240,
      }),
    );
  }

  async function copyRivalRule(rivalRuleToBeCopied: RivalRuleModel, name: string): Promise<RivalRuleModel> {
    rivalRuleState.value.rivalRule = cloneDeep(rivalRuleToBeCopied);

    // Delete id so we create a new rival rule (POST) // TODO: (KB) with POST you always create a new :shrug:
    delete rivalRuleState.value.rivalRule.id;
    rivalRuleState.value.rivalRule.name = name;

    return await saveRivalRule();
  }

  async function saveRivalRule(): Promise<RivalRuleModel | undefined> {
    try {
      rivalRuleState.value.isLoadingRivalRule = true;
      if (!rivalRuleState.value.rivalRule.name) {
        MessageService.error(t('form.validate_name'));
        return Promise.reject();
      }

      let response;

      if (isFinite(rivalRuleState.value.rivalRule.id)) {
        response = await rivalRulesService.update(rivalRuleState.value.rivalRule.toDto());
      } else {
        response = await rivalRulesService.create(rivalRuleState.value.rivalRule.toDto());
      }
      if (!response) {
        throw new Error();
      }

      rivalRuleState.value.rivalRule = response;
      rivalRuleState.value.rivalRuleUnchanged = cloneDeep(response);
      MessageService.savedSuccessfully(rivalRuleState.value.rivalRule.name);

      return response;
    } catch (error) {
      const err = error as EddyBaseError;
      if (err.response.data.errors.find((error) => error.errorCode === ErrorCode.UniqueName)) {
        MessageService.error(t('rival_rules.unique_rival_rule_name_needed'));
      } else if (err.response.data.errors.find((error) => error.errorCode === ErrorCode.EmptyList)) {
        MessageService.error(t('rival_rules.rival_rule_empty_list'));
      } else {
        MessageService.failedRequest();
      }
    } finally {
      rivalRuleState.value.isLoadingRivalRule = false;
    }
  }

  return {
    isLoadingRivalRule: computed(() => rivalRuleState.value.isLoadingRivalRule),
    isLoadingRivalRules: computed(() => rivalRuleState.value.isLoadingRivalRules),
    rivalRule: computed(() => rivalRuleState.value.rivalRule),
    rivalRules: computed(() => rivalRuleState.value.rivalRules),
    isNew: computed(
      () => !(rivalRuleState.value.rivalRule && isFinite(rivalRuleState.value.rivalRule.id) && rivalRuleState.value.rivalRule.id > 0),
    ),
    getAll,
    loadRivalRule,
    revertChanges,
    copyRivalRule,
    saveRivalRule,
    hasChanges,
    removeRuleByIndex,
    addNewRivalRuleDetails,
    loadNewRivalRule,
  };
}
