import { ApiClient, EddyBaseError } from '@/modules/api/base-client';
import { MessageService } from '@/modules/shared';
import { ErrorCode } from '@/modules/shared/types/error-codes';
import { i18n } from '@/plugins/i18n';

import { BulkRouteClusterAssignment, IRouteClusterService, RouteClusterAssignments } from './route-cluster.contracts';

const { t } = i18n.global;

export class RouteClusterService implements IRouteClusterService {
  private basePath = 'routes';
  private clustersPath = 'clusters';
  public async get(routeId: number): Promise<RouteClusterAssignments> {
    try {
      const urlString = this.basePath + `/${routeId}/clusters`;

      return await ApiClient.get<RouteClusterAssignments>(urlString);
    } catch (error) {
      const errors = error as EddyBaseError;

      if (errors.response.status === 404) {
        return {
          cabins: [],
        };
      } else {
        throw errors;
      }
    }
  }

  public async getAll(routeIds: number[]): Promise<RouteClusterAssignments[]> {
    const urlString = `${this.basePath}/${this.clustersPath}`;
    return await ApiClient.post<RouteClusterAssignments[]>(urlString, routeIds);
  }

  public async update(routeId: number, clusterAssignments: RouteClusterAssignments): Promise<RouteClusterAssignments> {
    try {
      const urlString = `${this.basePath}/${routeId}/${this.clustersPath}`;

      const response = await ApiClient.put<RouteClusterAssignments>(urlString, clusterAssignments);
      MessageService.savedSuccessfully(t('cluster_assignments'));
      return response;
    } catch (error) {
      const err = error as EddyBaseError;
      if (err.response.data.errors.find((error) => error.errorCode === ErrorCode.DuplicateRouteClusterAssignment)) {
        MessageService.error(
          err.response.data.errors.find((error) => error.errorCode === ErrorCode.DuplicateRouteClusterAssignment).uiMessage,
        );
        return clusterAssignments;
      } else {
        throw error;
      }
    }
  }

  public async bulkCleanAndAssign(routeIds: number[], routeClusterAssignments?: RouteClusterAssignments): Promise<void> {
    const bulkRouteClusterAssignment: BulkRouteClusterAssignment = { routeIds, cabins: routeClusterAssignments?.cabins || [] };
    try {
      const urlString = `${this.basePath}/${this.clustersPath}/bulk-clean-and-assign`;
      await ApiClient.put<void>(urlString, bulkRouteClusterAssignment);
    } catch (error) {
      const err = error as EddyBaseError;
      if (err.response.data.errors.find((error) => error.errorCode === ErrorCode.IllegalRouteClusterAssignment)) {
        MessageService.error(
          err.response.data.errors.find((error) => error.errorCode === ErrorCode.IllegalRouteClusterAssignment).uiMessage,
        );
        return;
      } else {
        throw error;
      }
    }
  }
}

export const routeClusterService = new RouteClusterService();
