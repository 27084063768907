import { FilterFieldField, RouteFilterType } from '@/modules/api/shared-contracts';
import { ControlSettings } from '@/modules/user-settings/api/user/user.contracts';

/**
 * The default user config in case the user initializes the app for the first time.
 */

export const defaultUserControlFilterSettings: ControlSettings = {
  routeFilterType: RouteFilterType.origin_destination,
  filters: [
    FilterFieldField.dayOfWeek,
    FilterFieldField.origin,
    FilterFieldField.destination,
    FilterFieldField.invertSearch,
    FilterFieldField.departureDateRange,
    FilterFieldField.tagId,
    FilterFieldField.userId,
    FilterFieldField.aircraftType,
    FilterFieldField.flightNumberRange,
  ],
  crossfilterState: [],
  competitors: [],
};
