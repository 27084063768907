export enum PricingTactic {
  Manual = 'manual',
  Continuous = 'continuous',
}

export const PricingIncrementAggregations = [1, 2, 5];

export interface PricingIncrementAggregation {
  value: number;
}

export enum TacticAdjustmentValue {
  MINIMUM = -3,
  MINUSMINUS = -2,
  MINUS = -1,
  AVERAGE = 0,
  PLUS = +1,
  PLUSPLUS = +2,
  MAXIMUM = +3,
}
