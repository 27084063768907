import { RouteRecordRaw } from 'vue-router';

import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const EXTERNAL_ROUTES: RouteRecordRaw[] = [
  {
    path: '/external',
    redirect: `/external/${RouteName.login}`,
    component: () => import('../views/ExternalBase.vue'),
    children: [
      {
        path: RouteName.login,
        name: RouteName.login,
        meta: {
          title: t('external.titles.login'),
        },
        component: () => import('../views/LoginPage.vue'),
      },
    ],
  },
];
