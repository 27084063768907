import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const DATA_IMPORT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/data-import',
    name: RouteName.dataImport,
    meta: {
      authorities: [Authority.DataImportUpdate],
      title: t('data_import.titles.data_import'),
    },
    component: () => import('../views/DataImportView.vue'),
  },
];
