import { orderBy, uniqBy } from 'lodash-es';

import { FilterFieldField } from '@/modules/api/shared-contracts';
import { RouteModel } from '@/modules/route-management/api/routes/routes.contracts';
import { useMarketInfoStore } from '@/modules/route-management/store/market-info.store';

export enum ControlFilterType {
  departure_date_range = 'departureDateRange',
  day_of_week = 'dayOfWeek',
  tags = 'tags',
  users = 'users',
  origin_destination_hub = 'originDestinationHub',
  flightNumber = 'flightNumber',
  aircraft_type = 'aircraftType',
  route_group = 'routeGroup',
  carrier_code = 'carrierCode',
  optimization_profile = 'optimizationProfile',
  optimization_tactic = 'optimizationTactic',
  autopilot = 'autopilot',
  cluster = 'cluster',
  eventName = 'eventName',
  eventCluster = 'eventCluster',
  stops = 'stops',
}

export type fieldType = FilterFieldField.origin | FilterFieldField.destination;

export function filteredAirports(fil: fieldType, selected?: string[]): RouteModel[] {
  const marketInfoStore = useMarketInfoStore();

  const filteredField = fil === FilterFieldField.origin ? FilterFieldField.destination : FilterFieldField.origin;
  let airports: RouteModel[] = [];
  if (!selected || selected.length === 0) {
    airports = orderBy(uniqBy(marketInfoStore.routes, fil), fil);
  } else {
    selected.forEach((selection) => {
      const matches = marketInfoStore.routes.filter((airport: RouteModel) => airport[fil] === selection);
      airports.push(...matches);
    });
    airports = orderBy(uniqBy(airports, filteredField), filteredField);
  }
  return airports;
}

export function coupleInvertedSearchIndex(userFilters: FilterFieldField[]): FilterFieldField[] {
  // This is to make sure the invertSearch filter is added to the ondhub filter selection, on the correct position
  const invertSearchIndex = userFilters.findIndex((filter: FilterFieldField) => filter === FilterFieldField.invertSearch);
  const oriDestHubIndex = Math.max(
    userFilters.findIndex((filter: FilterFieldField) => filter === FilterFieldField.destination),
    userFilters.findIndex((filter: FilterFieldField) => filter === FilterFieldField.flightPath),
  );
  // When it is not in the userFilters but the destination or flightpath filter does exist, we add it to the list
  if (invertSearchIndex === -1) {
    if (oriDestHubIndex > -1) {
      userFilters.splice(oriDestHubIndex + 1, 0, FilterFieldField.invertSearch);
    }
    // And when it already exists, we make sure it is in the correct position
  } else if (oriDestHubIndex !== -1 && invertSearchIndex !== oriDestHubIndex + 1) {
    // Remove invertSearch from the old location
    userFilters.splice(invertSearchIndex, 1);
    // Get the potential new index of the oriDestHubIndex;
    const newOriDestHubIndex = invertSearchIndex < oriDestHubIndex ? oriDestHubIndex - 1 : oriDestHubIndex;
    // And add the invertSearch directly after it
    userFilters.splice(newOriDestHubIndex + 1, 0, FilterFieldField.invertSearch);
  }
  return userFilters;
}
