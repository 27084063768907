export const en = {
  dashboard: {
    titles: {
      dashboard: 'Dashboard',
    },
    widgets: {
      total: {
        title: 'Total',
        description: 'Displays the total number of flights (e.g., new, cancelled, departing at a certain NDO).',
      },
      query_total: {
        title: 'Query Total',
        description: 'Displays the count of the results of a query created in Flight Control or Reporting.',
      },
      a_cap_load_factor_by_month: {
        title: 'aCap Load Factor by Month',
        description: 'A bar chart that displays the total authorized capacity load factor, grouped by month.',
      },
      bookings_by_month: {
        title: 'Bookings by Month',
        description: 'A bar chart that displays the total number of bookings, grouped by month.',
      },
      flights_by_month: {
        title: 'Flights by Month',
        description: 'A bar chart that displays the total number of scheduled, departed, cancelled and new flights, grouped by month.',
      },
      revenue_by_month: {
        title: 'Revenue by Month',
        description: 'A bar chart that displays the total revenue, grouped by month.',
      },
      s_cap_load_factor_by_month: {
        title: 'sCap Load Factor by Month',
        description: 'A bar chart that displays the total saleable capacity load factor, grouped by month.',
      },
      s_cap_revenue_by_month: {
        title: 'sCap Revenue by Month',
        description: '',
      },
      average_fare_by_month: {
        title: 'Average Fare by Month',
        description: 'A bar chart that displays the total average fare, grouped by month.',
      },
      authorized_capacity_by_month: {
        title: 'Authorized Capacity by Month',
        description: 'A bar chart that displays the total authorized capacity, grouped by month.',
      },
    },
    actions: {
      done_editing: 'Done Editing',
      save: 'Save',
      cancel: 'Cancel',
      add: 'Add',
      edit: 'Edit',
      toggle_fullscreen: 'Toggle fullscreen',
      add_widget: 'Add a Widget',
      configure_widget: 'Configure Widget',
    },
    status: {
      loading_view: 'Loading Dashboard...',
      not_available: 'N/A',
      empty: {
        title: "This dashboard doesn't have any widgets yet",
        description: 'Add one or more widgets to gain visibility into your flights performance and system status.',
      },
    },
    info: {
      widget_actions: 'Add, edit, move or resize your widgets',
    },
    labels: {
      first_dashboard: 'My First Dashboard',
      title: 'Title',
      total: 'Total',
      ond: 'OD',
      leg: 'Leg',
      filters: 'Filters',
      flight_numbers: 'Flight numbers',
      query: 'Query',
      show_pickup: 'Show Pickup',
      this_year: 'This Year',
      yesterdays_pickup: "Yesterday's Pickup",
      total_flights: 'Total Flights',
      flight_status: {
        cancelled: 'Cancelled',
        departed: 'Departed',
        new: 'New',
        scheduled: 'Scheduled',
      },
      total_widget_total_types: {
        new_flights: 'New Flights',
        cancelled_flights: 'Cancelled Flights',
        departing_today: 'Flights Today',
        departing_per_week: 'Flights This Week',
        departing_per_month: 'Flights This Month',
        departing_per_year: 'Flights This Year',
        flights_without_source_flight: 'Flights Without Source Flight',
      },
    },
  },
};
