import { RouteAction, RouteActionPayload, RouteActionType } from './route-actions-contracts';

export interface SetForecastLearningPayload extends RouteActionPayload {
  forecastLearning: boolean;
}

export class SetForecastLearningAction implements RouteAction<SetForecastLearningPayload> {
  public actionType: RouteActionType = RouteActionType.setForecastLearning;

  public forecastLearning: boolean;

  constructor(forecastLearning: boolean) {
    this.forecastLearning = forecastLearning;
  }

  public setPayload(payload: SetForecastLearningPayload): void {
    this.forecastLearning = payload.forecastLearning;
  }

  public getPayload(): SetForecastLearningPayload {
    return {
      actionType: this.actionType,
      forecastLearning: this.forecastLearning,
    };
  }
}
