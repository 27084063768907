import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { ITag } from '@/modules/tags';
import { useTagsStore } from '@/modules/tags/store/tags.store';

export const hubFilter: FilterFieldDefinition = {
  field: FilterFieldField.hub,
  type: FilterFieldType.in,
  value: [],
  componentDataOptions: [],
  componentTitle: true,
  transformToParams: (value: string[]) => value.join(','),
  transformFromParams: (value: string) => value.split(','),
};

export const flightPathFilter: FilterFieldDefinition = {
  field: FilterFieldField.flightPath,
  type: FilterFieldType.in,
  value: [],
  componentDataOptions: [],
  componentTitle: true,
  transformToParams: (value: string[]) => value.join(','),
  transformFromParams: (value: string) => value.split(','),
};

export const invertSearchFilter: FilterFieldDefinition = {
  field: FilterFieldField.invertSearch,
  type: FilterFieldType.equal,
  value: null,
  componentDataOptions: [],
  componentTitle: true,
  transformToParams: (value: boolean) => JSON.stringify(value),
  transformFromParams: (value: string) => JSON.parse(value),
};

export const carrierCodeFilter: FilterFieldDefinition = {
  field: FilterFieldField.carrierCode,
  type: FilterFieldType.in,
  value: [],
  componentDataOptions: [],
  componentTitle: true,
  transformToParams: (value: string[]) => value.join(','),
  transformFromParams: (value: string) => value.split(','),
};

export const autopilotFilter: FilterFieldDefinition = {
  field: FilterFieldField.autopilot,
  type: FilterFieldType.equal,
  value: null,
  componentDataOptions: [],
  componentTitle: true,
  transformToParams: (value: boolean) => JSON.stringify(value),
  transformFromParams: (value: string) => JSON.parse(value),
};

export const clusterFilter: FilterFieldDefinition = {
  field: FilterFieldField.cluster,
  type: FilterFieldType.in,
  value: [],
  componentDataOptions: [FilterFieldType.in, FilterFieldType.none],
  componentTitle: true,
  transformToParams: (value: string[]) => value.join(','),
  transformFromParams: (value: string): number[] => value.split(',').map(Number),
};

export const optimizationProfileFilter: FilterFieldDefinition = {
  field: FilterFieldField.optimizationProfile,
  type: FilterFieldType.in,
  value: [],
  transformToParams: (value: number[]): string => value.join(','),
  transformFromParams: (value: string): number[] => value.split(',').map(Number),
};

export const optimizationTacticFilter: FilterFieldDefinition = {
  field: FilterFieldField.optimizationTactic,
  type: FilterFieldType.in,
  value: [],
  transformToParams: (value: string[]) => value.join(','),
  transformFromParams: (value: string) => value.split(','),
};

export const tagFilter: FilterFieldDefinition = {
  field: FilterFieldField.tagId,
  type: FilterFieldType.in,
  componentDataOptions: [FilterFieldType.in, FilterFieldType.all, FilterFieldType.none, FilterFieldType.noneOf],
  componentTitle: true,
  value: [],
  generateCustomFilter: (item: FilterFieldDefinition) => ({
    field: item.field,
    type: item.type,
    value: item.value.map((tag: ITag) => tag.id),
  }),
  valueKey: 'id',
  componentValueIsString: false,
  multiSelect: true,
  transformToParams: (value: any[]) => (isNaN(value[0]) ? value.map((tag: ITag) => tag.id).join(',') : value.join(',')),
  transformFromParams: (value: string, filters, urlParams) => {
    const tagsStore = useTagsStore();

    if (urlParams['tagFieldType']) {
      const tagField = filters.find((ffd) => ffd.field === FilterFieldField.tagId);
      if (tagField) {
        tagField.type = urlParams['tagFieldType'] as FilterFieldType;
      }
    }

    return value.split(',').map((id: string) =>
      // This only works when all tags are already there?
      tagsStore.tags.find((tag) => tag.id === Number(id)),
    );
  },
};

export const routeGroupFilter: FilterFieldDefinition = {
  field: FilterFieldField.routeGroupId,
  type: FilterFieldType.in,
  componentTitle: true,
  value: [],
  componentDataOptions: [],
  transformToParams: (value: string[], type: FilterFieldType, urlParams): string => {
    urlParams['routeGroupFieldType'] = type;
    return value.join(',');
  },
  transformFromParams: (value: string, filters: FilterFieldDefinition[], urlParams): number[] => {
    if (urlParams['routeGroupFieldType']) {
      const routeGroupField = filters.find((ffd) => ffd.field === FilterFieldField.routeGroupId);
      if (routeGroupField) {
        routeGroupField.type = urlParams['routeGroupFieldType'] as FilterFieldType;
      }
    }
    return value.split(',').map((id: string) => Number(id));
  },
};

export const userFilter: FilterFieldDefinition = {
  field: FilterFieldField.userId,
  type: FilterFieldType.equal,
  value: undefined,
  componentDataOptions: [FilterFieldType.equal, FilterFieldType.none],
  transformFromParams: (value: string): number => Number(value),
};

export const aircraftTypeFilter: FilterFieldDefinition = {
  field: FilterFieldField.aircraftType,
  type: FilterFieldType.in,
  componentDataOptions: [],
  componentTitle: true,
  multiSelect: true,
  value: [],
  valueKey: 'type',
  disableSelectionGrouping: true,
  transformToParams: (value: string[]) => value.join(','),
  transformFromParams: (value: string) => value.split(','),
};

export const eventNameFilter: FilterFieldDefinition = {
  field: FilterFieldField.eventName,
  type: FilterFieldType.in,
  componentDataOptions: [FilterFieldType.in, FilterFieldType.all, FilterFieldType.none],
  value: [],
  transformToParams: (value: number[]) => value.join(','),
  transformFromParams: (value: string) => value.split(',').map(Number),
};

export const eventClusterFilter: FilterFieldDefinition = {
  field: FilterFieldField.eventCluster,
  type: FilterFieldType.in,
  value: [],
  componentDataOptions: [FilterFieldType.in, FilterFieldType.all, FilterFieldType.none],
  transformToParams: (value: string[]) => value.join(','),
  transformFromParams: (value: string) => value.split(','),
};

export const stopsFilter: FilterFieldDefinition = {
  field: FilterFieldField.stops,
  type: FilterFieldType.in,
  value: [],
  transformToParams: (value: number[]) => value.join(','),
  transformFromParams: (value: string) => value.split(',').map(Number),
};
