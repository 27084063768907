import { OptimizationProfileLevelDataImportAssignment } from '@/modules/data-import/api/data-import/data-import-contracts';

import { IOptimisationProfileService, IOptimisationProfiles } from './optimisation-profiles.contracts';
import { ApiClient } from '../../api/base-client';

export class OptimisationProfileService implements IOptimisationProfileService {
  private basePath = 'optimisation-profile';

  public async getAll(): Promise<IOptimisationProfiles> {
    return await ApiClient.get<IOptimisationProfiles>(`${this.basePath}`);
  }

  public async getForFlight(flightKey: string | undefined): Promise<IOptimisationProfiles> {
    return await ApiClient.get<IOptimisationProfiles>(`${this.basePath}`, { flightKey });
  }

  public async bulkAssign(request: OptimizationProfileLevelDataImportAssignment): Promise<void> {
    return await ApiClient.patch<void>(`${this.basePath}/level/bulk-assign`, request);
  }
}

export const optimisationProfileService = new OptimisationProfileService();
