<template>
  <el-form :model="form" :disabled="disabled" label-width="auto" class="m-2">
    <el-form-item :label="t('rival_rules.rival')" required>
      <carrier-code-select
        v-model="form.rivalCarrierCode"
        class="mt-1"
        :selected-values="[form.rivalCarrierCode]"
        :multiple="false"
        :options="carrierCodes"
        @on-change="onChange()"
      />
    </el-form-item>

    <el-form-item :label="t('rival_rules.time_window')" required>
      <el-slider
        v-model="form.rivalTimeWindow"
        :format-tooltip="formatTooltip"
        :min="sliderConfig.min"
        :max="sliderConfig.max"
        :step="sliderConfig.step"
        :marks="timeWindowMarks"
        range
        @update:model-value="onChange()"
      />
    </el-form-item>

    <el-form-item :label="t('rival_rules.ow_rt')" required>
      <el-radio-group v-model="form.fareType" @update:model-value="onChange()">
        <el-radio label="one-way" value="one-way">{{ t('rival_rules.one_way') }}</el-radio>
        <el-radio label="round-trip" value="round-trip">{{ t('rival_rules.round_trip') }}</el-radio>
        <el-radio label="no-preference" value="no-preference">{{ t('rival_rules.no_preference') }}</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item :label="t('rival_rules.stopover')">
      <el-select v-model="form.stopoverCounts" multiple collapse-tags clearable @update:model-value="onChange()">
        <el-option v-for="item in stopoverOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script lang="ts" setup>
export type RivalRuleDetailsListItemProps = {
  modelValue: RivalRuleDetailModel;
  carrierCodes: string[];
  disabled?: boolean;
};

import { range } from 'lodash-es';
import moment from 'moment';
import { CSSProperties, computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { SelectOption } from '@/models/element-ui';
import { RivalRuleDetailFareType } from '@/modules/rules/rival-rules/api/rival-rules.contracts';
import { RivalRuleDetailModel } from '@/modules/rules/rival-rules/models/rival-rule.model';
import CarrierCodeSelect from '@/modules/shared/components/form-controls/CarrierCodeSelect.vue';
import { DateTimeService } from '@/services/date-time.service';

const { t } = useI18n();

interface Mark {
  style: CSSProperties;
  label: string;
}
type Marks = Record<number, Mark | string>;

type RivalRuleDetailFareTypeUI = NonNullable<RivalRuleDetailFareType | 'no-preference'>;

interface RivalRuleDetailsListItemFormDto {
  rivalCarrierCode: string;
  rivalTimeWindow: number[];
  fareType: RivalRuleDetailFareTypeUI;
  stopoverCounts: number[];
}

const stopoverOptions: SelectOption<number>[] = [
  { label: 'Non-stop', value: 0 },
  { label: '1 Stop', value: 1 },
  { label: '2 Stops', value: 2 },
];

const props = withDefaults(defineProps<RivalRuleDetailsListItemProps>(), {
  carrierCodes: () => [],
  disabled: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', v: RivalRuleDetailModel): void;
}>();

const form = reactive<RivalRuleDetailsListItemFormDto>({
  rivalCarrierCode: '',
  rivalTimeWindow: [],
  fareType: 'no-preference',
  stopoverCounts: [],
});

watch(
  () => props.modelValue,
  (newValue) => {
    form.rivalCarrierCode = newValue.rivalCarrierCode;
    form.rivalTimeWindow = newValue.rivalTimeWindow;
    form.fareType = newValue?.fareType ?? 'no-preference';
    form.stopoverCounts = newValue.stopoverCounts ?? [];
  },
  { immediate: true },
);

function onChange(): void {
  const newModel = new RivalRuleDetailModel({
    ...props.modelValue,
    rivalCarrierCode: form.rivalCarrierCode,
    rivalTimeWindowLower: form.rivalTimeWindow[0],
    rivalTimeWindowUpper: form.rivalTimeWindow[1],
    fareType: form.fareType === 'no-preference' ? null : form.fareType,
    stopoverCounts: form.stopoverCounts,
  });

  emit('update:modelValue', newModel);
}

const HOUR_IN_MINUTES = 60;
const sliderConfig = reactive({
  min: -48 * HOUR_IN_MINUTES,
  max: 48 * HOUR_IN_MINUTES,
  step: 0.25 * HOUR_IN_MINUTES,
});

const timeWindowMarks = computed(
  (): Marks =>
    // Add step to max value to include the max value as a label
    range(sliderConfig.min, sliderConfig.max + sliderConfig.step, sliderConfig.step).reduce((acc: Marks, value): Marks => {
      if ((value / HOUR_IN_MINUTES) % (48 / 4) === 0) {
        acc[value] = {
          style: {
            fontSize: '11px',
            color: '#1989FA',
          },
          label: `${value / HOUR_IN_MINUTES}h`,
        };
      }

      return acc;
    }, {}),
);

function formatTooltip(value: number): string {
  return DateTimeService.getDurationInHoursAndMinutes(moment.duration(value, 'minutes'));
}

defineExpose({
  form,
});
</script>

<style lang="scss" scoped>
:deep(.el-slider__marks-text) {
  margin-top: 0.625rem;
}
</style>
