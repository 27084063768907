import { datadogRum } from '@datadog/browser-rum';

import { EddyEnvironment } from '@/environment';

export type RumConfig = {
  version: string;
  env: EddyEnvironment;
  releaseVersion?: string;
};

export function installDatadog(info: RumConfig): void {
  datadogRum.init({
    applicationId: '3a6c340c-2503-4454-8eb0-7dcd658d4b60',
    clientToken: 'pub65400537c66e0be7ec06ec12f2e887c0',
    site: 'datadoghq.eu',
    service: 'eddy-web',
    env: info.env,
    version: info.version || 'unknown',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [/https:\/\/.*\.eddy\.aero/],
    traceSampleRate: 100,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
  });

  datadogRum.setGlobalContextProperty('eddyReleaseVersion', info.releaseVersion ?? '');
}
