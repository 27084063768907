<template>
  <el-select
    :model-value="modelValue"
    :disabled="disabled"
    placeholder="Select"
    class="w-full"
    data-test="class-code-select"
    @change="onChange"
  >
    <el-option-group v-for="group in options" :key="group.label" :label="group.label">
      <el-option
        v-for="item in group.options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :data-test="$attrs['data-test'] + '-class-code-select-option'"
      >
        <k-class-code-item :class-code="item.value" :cabin-code="getCabinCode(group.label)" />
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

import KClassCodeItem from '@/modules/shared/components/KClassCodeItem.vue';
import { OptionGroup } from '@/modules/shared/types/select.interface';
import { useAppSettingsStore } from '@/store/modules/app-settings.store';

export default defineComponent({
  components: { KClassCodeItem },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<OptionGroup[]>,
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    onChange(newValue: string) {
      this.$emit('update:modelValue', newValue);
    },
    getCabinCode(cabinName: string): string {
      return useAppSettingsStore().cabinClassesWithOutTopClasses.filter((cabin) => cabin.name === cabinName)[0]?.code;
    },
  },
});
</script>
