import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { InventoryManagementSettings, inventoryManagementMap } from '@/models/InventoryManagement';
import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';
import { SystemConfiguration } from '@/modules/system-settings/api/system/system.contracts';
import { systemService } from '@/modules/system-settings/api/system/system.service';

export const useSystemStore = defineStore('system', () => {
  const _config: Ref<SystemConfiguration | undefined> = ref();

  const config: ComputedRef<SystemConfiguration | undefined> = computed(() => {
    if (_config.value) {
      const { carrierCodes, ...rest } = _config.value;

      return { ...rest, carrierCodes: carrierCodes.sort() };
    }

    return undefined;
  });

  const flightViewConfig: ComputedRef<InventoryManagementSettings | undefined> = computed(() =>
    _config.value ? inventoryManagementMap.get(_config.value.inventoryManagementType) : undefined,
  );

  async function get(): Promise<void> {
    try {
      _config.value = await systemService.getConfig();
    } catch (e) {
      logger.error(e as Error);
      MessageService.error('Failed to retrieve system configuration');
    }
  }

  function $reset(): void {
    _config.value = undefined;
  }

  return {
    flightViewConfig,
    config,
    get,
    $reset,
  };
});
