import { first, isEmpty } from 'lodash-es';

import { FlightFieldName } from '@/models/enums/flight/flight-field-name';
import { FlightDynamicPropertiesAllowed } from '@/models/FlightModel';
import { CompetitorFare } from '@/modules/api/flight/competitive-fares/competitive-fares-contracts';
import {
  FlightLegCabin,
  FlightLegModel,
  FlightLineCabin,
  FlightLineCabinClass,
  FlightLineModel,
  PostDepartureCabin,
  PostDepartureClass,
  SlimFlightLineModel,
  SourceFlight,
  SourceFlightCabin,
  SourceFlightClass,
} from '@/modules/api/flight/flight-contracts';
import { FlightAction } from '@/modules/flight-actions/api/flight-actions.contracts';

import { DateTimeService } from './date-time.service';

export class FlightService {
  public static getFlightNumbersFromFlightLine(flightLine: string): number[] {
    try {
      const numberMatches = flightLine.match(/\d{1,4}/g);
      if (!numberMatches || numberMatches.length === 0) {
        return [];
      }
      return numberMatches.map((match) => parseInt(match));
    } catch (error) {
      return [];
    }
  }

  public static hasUnsavedChanges(flightActions: Map<number, FlightAction<any>[]>): boolean {
    let hasUnsavedChanges = false;
    if (isEmpty(flightActions)) {
      return hasUnsavedChanges;
    }

    for (const [, value] of flightActions.entries()) {
      if (value.length > 0) {
        hasUnsavedChanges = true;
        break;
      }
    }

    return hasUnsavedChanges;
  }

  public static getMatchedCabin(data: FlightDynamicPropertiesAllowed | undefined, cabinCode: string): FlightLineCabin | undefined {
    if (!data || !data.cabins) {
      return undefined;
    }
    return data.cabins.find((flCabin: FlightLineCabin) => flCabin.code === cabinCode);
  }

  public static getMatchedLegCabin(data: FlightLegModel, cabinCode: string): FlightLegCabin | undefined {
    if (!data || !data.cabins) {
      return undefined;
    }
    return data.cabins.find((flCabin: FlightLegCabin) => flCabin.code === cabinCode);
  }

  public static getMatchedPostDepartureCabin(data: FlightDynamicPropertiesAllowed, cabinCode: string): PostDepartureCabin | undefined {
    if (!data || !data.postDeparture) {
      return undefined;
    }
    return data.postDeparture.cabins.find((flCabin: PostDepartureCabin) => flCabin.code === cabinCode);
  }

  public static getMatchedSourceFlightCabin(sourceFlight: SourceFlight, cabinCode: string): SourceFlightCabin | undefined {
    if (!sourceFlight) {
      return undefined;
    }
    return sourceFlight.cabins.find((cabin) => cabin.cabinCode === cabinCode);
  }

  public static getMatchedSourceFlightClass(sourceFlightCabin: SourceFlightCabin, classCode: string): SourceFlightClass | undefined {
    return sourceFlightCabin.classes.find((cls) => cls.classCode === classCode);
  }

  public static getMatchedClass(data: FlightLineCabin, classCode: string): FlightLineCabinClass | undefined {
    return data.classes.find((cls: FlightLineCabinClass) => cls.code === classCode);
  }

  public static getMatchedPostDepartureClass(data: PostDepartureCabin, classCode: string): PostDepartureClass | undefined {
    return data.classes.find((cls: PostDepartureClass) => cls.code === classCode);
  }

  public static getLafSaForCabin({
    cabinCode,
    flightLine,
  }: {
    cabinCode: string;
    flightLine: FlightDynamicPropertiesAllowed;
  }): number | undefined {
    const matchedCabin = FlightService.getMatchedCabin(flightLine, cabinCode);
    const lafClass = matchedCabin && matchedCabin.classes.find((cls) => cls.code === matchedCabin.lowestAvailableFareClass);
    if (lafClass) {
      return lafClass.seatAvailability;
    } else null;
  }

  public static getKeyByFields({ flightLine, fields }: { flightLine: SlimFlightLineModel; fields: FlightFieldName[] }): string {
    let key = '';
    for (const field of fields) {
      switch (field) {
        case FlightFieldName.DepartureDate:
          key += flightLine.departureDate;
          break;
        case FlightFieldName.Origin:
          key += flightLine.origin;
          break;
        case FlightFieldName.Destination:
          key += flightLine.destination;
          break;
        case FlightFieldName.DayOfWeek:
          key += FlightService.getDayOfWeek({ flightLine });
          break;
        default:
          break;
      }
    }
    return key;
  }

  public static getDayOfWeek({ flightLine }: { flightLine: SlimFlightLineModel }): string {
    return DateTimeService.formatDate({
      date: flightLine.departureDate,
      format: 'ddd',
    });
  }

  public static getSortedFares(competitiveFares: CompetitorFare[]): CompetitorFare[] {
    return [...competitiveFares].sort((a, b) => a.fare - b.fare);
  }

  public static getMinCompetitiveFareOfCabin(cabin: FlightLineCabin): number | null | undefined {
    return FlightService.getMinFareOfCabin(cabin, cabin?.competitiveFares ?? []);
  }

  public static getMinRivalFareOfCabin(cabin: FlightLineCabin): number | null | undefined {
    return FlightService.getMinFareOfCabin(cabin, cabin?.rivalFares ?? []);
  }

  public static getMinSpecificCarrierFareOfCabin(cabin: FlightLineCabin, carrier: string): number | undefined {
    if (!cabin || !cabin.competitiveFares || cabin.competitiveFares.length === 0 || !carrier) {
      return;
    }
    const carrierFares: CompetitorFare[] = FlightService.getSortedFares(cabin.competitiveFares).filter(
      (competitorFare: CompetitorFare) => competitorFare.carrier === carrier,
    );
    if (!carrierFares || carrierFares.length === 0) {
      return;
    }
    const lowestFare = first(carrierFares);
    return lowestFare && lowestFare.fare ? Math.round(lowestFare.fare) : undefined;
  }

  public static hasRealTimeDate(flight: FlightLineModel): boolean {
    return (
      (flight?.bookingsPickUps?.length ?? 0) > 0 &&
      flight?.bookingsPickUps?.[0].dayOffset === 0 &&
      (flight.bookingsPickUps[0].bookings > 0 || flight.bookingsPickUps[0].cancellations > 0)
    );
  }

  private static getMinFareOfCabin(cabin: FlightLineCabin, fares: CompetitorFare[]): number | null | undefined {
    if (!cabin || !fares || fares.length === 0) {
      return;
    }
    const lowestFare = first(FlightService.getSortedFares(fares));
    return lowestFare && (lowestFare.fare || lowestFare.fare === 0) ? Math.round(lowestFare.fare) : null;
  }
}
