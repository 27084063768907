import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { CalendarEvent, CalendarEventType } from '@/modules/route-management/api/calendar.contracts';
import { calendarService } from '@/modules/route-management/api/calendar.service';

export const useCalendarStore = defineStore('calendar', () => {
  const seasons: Ref<CalendarEvent[]> = ref([]);
  const selectedSeasonId: Ref<CalendarEvent['id'] | undefined> = ref();
  const selectedSeason: ComputedRef<CalendarEvent | undefined> = computed(() =>
    seasons.value.find(({ id }) => id === selectedSeasonId.value),
  );

  async function get(captureDate: string): Promise<void> {
    seasons.value = await calendarService.getBy(CalendarEventType.Season, captureDate);
  }

  function $reset(): void {
    seasons.value = [];
    selectedSeasonId.value = undefined;
  }

  return {
    selectedSeasonId,
    selectedSeason,
    seasons,
    get,
    $reset,
  };
});
