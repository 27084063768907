import firebase from 'firebase/app';
import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';
import 'firebase/storage';

import { VersionInfo } from '@/modules/docs/api/docs.contracts';
import { docsService } from '@/modules/docs/api/docs.service';
import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';
import { i18n } from '@/plugins/i18n';

import FirebaseStorageError = firebase.storage.FirebaseStorageError;

const { t } = i18n.global;

class UserGuideMapEntry {
  public ref: firebase.storage.Reference;
  public exists: boolean;

  constructor(ref: firebase.storage.Reference, exists: boolean = false) {
    this.ref = ref;
    this.exists = exists;
  }
}

// Image map consists of an image name (example.png) as the key and a blob path (blob:myaddres.local/superrandomstuff) as its value
export type ImageMap = Map<string, string>;

export const useDocsStore = defineStore('docs', () => {
  const versionsInfo: Ref<VersionInfo[]> = ref([]);
  const isLoadingVersions: Ref<boolean> = ref(false);
  const userGuidesMap: Ref<Map<string, UserGuideMapEntry>> = ref(new Map());
  const releaseNotesMap: Ref<Map<string, string>> = ref(new Map());
  const isLoadingReleaseNotes: Ref<boolean> = ref(false);
  const imagesPerVersionMap: Ref<Map<string, ImageMap>> = ref(new Map());

  let abortController: AbortController | undefined;

  async function get(): Promise<void> {
    try {
      isLoadingVersions.value = true;
      versionsInfo.value = await docsService.getStructure();
    } catch (e) {
      logger.error(e as Error);
    } finally {
      isLoadingVersions.value = false;
    }
  }

  async function checkIfUserGuideExistsForVersion(version: string): Promise<void> {
    const _storageRef = firebase.storage().ref(`user-guides/v${version}.pdf`);

    try {
      await _storageRef.getDownloadURL();
      userGuidesMap.value.set(version, new UserGuideMapEntry(_storageRef, true));
    } catch (e) {
      if ((e as FirebaseStorageError)?.code === 'storage/object-not-found') {
        userGuidesMap.value.set(version, new UserGuideMapEntry(_storageRef, false));
      } else {
        MessageService.failedRequest(t('docs.errors.failed_to_check_user_guide_for_version', { version }));
      }
    }
  }

  async function getImagesForVersion(version: string, images: string[]): Promise<void> {
    if (abortController) {
      abortController.abort();
    }

    abortController = new AbortController();

    if (!imagesPerVersionMap.value.has(version)) {
      imagesPerVersionMap.value.set(version, new Map());
    }

    const imagesMapForVersion = imagesPerVersionMap.value.get(version);
    const controller = abortController;

    if (imagesMapForVersion && controller) {
      await Promise.all(
        images.map(async (imageString) => {
          const blobString: string = await docsService.getImageAsBlobString(version, imageString, controller);
          imagesMapForVersion.set(imageString, blobString);
        }),
      );

      abortController = undefined;
    }
  }

  async function downloadUserGuide(version: string): Promise<void> {
    try {
      const _storageRef = userGuidesMap.value.get(version)?.ref;

      if (!_storageRef) {
        throw new Error(`No storageRef for User Guide version ${version}`);
      }

      // Open a new window/tab (depends on the User's browser settings) with the url to view the PDF
      const downloadUrl = await _storageRef.getDownloadURL();
      window.open(downloadUrl, '_blank');
    } catch (e) {
      MessageService.failedRequest(t('docs.errors.failed_to_create_pdf_url'));
    }
  }

  async function getReleaseNotesForVersion(version: string): Promise<void> {
    try {
      isLoadingReleaseNotes.value = true;
      const releaseNotes = await docsService.getReleaseNotesForVersion(version);

      releaseNotesMap.value.set(version, releaseNotes);
    } catch (e) {
      MessageService.failedRequest(t('docs.errors.failed_to_get_release_notes_for_version', { version }));
    } finally {
      isLoadingReleaseNotes.value = false;
    }
  }

  function $reset(): void {
    versionsInfo.value = [];
    isLoadingVersions.value = false;
    userGuidesMap.value = new Map();
    releaseNotesMap.value = new Map();
    isLoadingReleaseNotes.value = false;
    imagesPerVersionMap.value = new Map();
  }

  return {
    versionsInfo,
    isLoadingVersions,
    userGuidesMap,
    isLoadingReleaseNotes,
    releaseNotesMap,
    imagesPerVersionMap,
    getImagesForVersion,
    getReleaseNotesForVersion,
    downloadUserGuide,
    checkIfUserGuideExistsForVersion,
    get,
    $reset,
  };
});
