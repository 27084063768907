import { ApiClient } from '@/modules/api/base-client';
import { IUserService, UserConfigModelDTO } from '@/modules/user-settings/api/user/user.contracts';
import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';

export class UserService implements IUserService {
  public async changePassword(currentPassword: string, newPassword: string): Promise<void> {
    await ApiClient.post(`users/change-password`, {
      currentPassword,
      newPassword,
    });
  }

  public async getByEmail(email: string): Promise<UserModel> {
    return ApiClient.get<UserModel>(`users/search?email=${email}`);
  }

  public patchConfig(id: number, config: UserConfigModelDTO): Promise<UserConfigModelDTO> {
    return ApiClient.patch<UserConfigModelDTO>(`user-settings/${id}`, config);
  }
}

export const userService = new UserService();
