import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { ICluster, IClusterUpdatePayload, ICreateCluster } from '@/modules/cluster-management/api/cluster.contracts';
import { clusterService } from '@/modules/cluster-management/api/cluster.service';
import { MessageService } from '@/modules/shared';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const useClustersStore = defineStore('clusters', () => {
  const clustersMap: Ref<Map<number, ICluster>> = ref(new Map());
  const clusters: ComputedRef<ICluster[]> = computed(() => Array.from(clustersMap.value.values()));
  const isLoading: Ref<boolean> = ref(false);

  async function get(): Promise<void> {
    try {
      isLoading.value = true;
      const response = await clusterService.getAll();
      clustersMap.value = new Map(response.map((cluster) => [cluster.id, cluster]));
    } catch {
      MessageService.failedRequest(t('cluster_management.errors.failed_to_fetch_clusters'));
    } finally {
      isLoading.value = false;
    }
  }

  async function update(cluster: IClusterUpdatePayload): Promise<void> {
    try {
      isLoading.value = true;
      const updatedCluster = await clusterService.update(cluster.id, cluster);
      clustersMap.value.set(updatedCluster.id, updatedCluster);
    } catch {
      MessageService.failedRequest(t('cluster_management.errors.failed_to_update_cluster'));
    } finally {
      isLoading.value = false;
    }
  }

  async function create(cluster: ICreateCluster): Promise<void> {
    try {
      isLoading.value = true;
      const createdCluster = await clusterService.create(cluster);
      clustersMap.value.set(createdCluster.id, createdCluster);
    } catch {
      MessageService.failedRequest(t('cluster_management.errors.failed_to_create_cluster'));
    } finally {
      isLoading.value = false;
    }
  }

  function $reset(): void {
    clustersMap.value = new Map();
    isLoading.value = false;
  }

  return {
    update,
    create,
    clusters,
    clustersMap,
    isLoading,
    get,
    $reset,
  };
});
