import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { logger } from '@/modules/monitoring';
import { CreateRouteGroupPayload, RouteGroup } from '@/modules/route-management/api/route-groups.contracts';
import { routeGroupsService } from '@/modules/route-management/api/route-groups.service';
import { MessageService } from '@/modules/shared';

export const useRouteGroupsStore = defineStore('routeGroups', () => {
  const _routeGroups: Ref<RouteGroup[]> = ref([]);
  const routeGroups: ComputedRef<RouteGroup[]> = computed(() => _routeGroups.value.sort((a, b) => a.name.localeCompare(b.name)));
  const isLoading: Ref<boolean> = ref(false);

  async function get(): Promise<void> {
    try {
      isLoading.value = true;
      _routeGroups.value = await routeGroupsService.getAll();
    } catch (e) {
      logger.error(e as Error);
      MessageService.failedRequest();
    } finally {
      isLoading.value = false;
    }
  }

  async function create(routeGroup: CreateRouteGroupPayload): Promise<RouteGroup> {
    const newGroup = await routeGroupsService.post(routeGroup);
    _routeGroups.value = [...routeGroups.value, newGroup];
    return newGroup;
  }

  function $reset(): void {
    _routeGroups.value = [];
  }

  return {
    routeGroups,
    isLoading,
    get,
    create,
    $reset,
  };
});
