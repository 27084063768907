import { ApiClient } from '@/modules/api/base-client';
import { InsightName } from '@/modules/insights/configuration/insights-configuration';

export type Insight = {
  name: InsightName;
  url: string;
  order: number;
};

export class InsightsService implements IInsightsService {
  private _baseUrl = 'insights';

  public async getEnabledInsights(): Promise<Insight[]> {
    return ApiClient.get<Insight[]>(`${this._baseUrl}`);
  }

  public async getAvailableInsights(): Promise<Insight[]> {
    return ApiClient.get<Insight[]>(`${this._baseUrl}/available`);
  }
}

export const insightsService = new InsightsService();

interface IInsightsService {
  getEnabledInsights(): Promise<Insight[]>;
  getAvailableInsights(): Promise<Insight[]>;
}
