import { ApiClient } from '@/modules/api/base-client';
import { RivalRuleAssignmentPayload } from '@/modules/data-import/api/data-import/data-import-contracts';
import { RivalRuleModel } from '@/modules/rules/rival-rules/models/rival-rule.model';

import { IRivalRulesService, RivalRuleDto, RivalRuleUpdateDto } from './rival-rules.contracts';

/**
 * Communicates with the Production API for Rival Rules related calls
 */
export class RivalRulesService implements IRivalRulesService {
  private _baseUrl = 'rules/rival-rules';

  async create(payload: RivalRuleUpdateDto): Promise<RivalRuleModel> {
    const result = await ApiClient.post<RivalRuleDto>(this._baseUrl, payload);

    return RivalRuleModel.fromDto(result);
  }

  async getAll(): Promise<RivalRuleModel[]> {
    const dtos = await ApiClient.get<RivalRuleDto[]>(this._baseUrl);
    return RivalRuleModel.fromDtoList(dtos);
  }

  async getById(id: number): Promise<RivalRuleModel> {
    const dtos = await ApiClient.get<RivalRuleDto>(`${this._baseUrl}/${id}`);

    return RivalRuleModel.fromDto(dtos);
  }

  async update(payload: RivalRuleUpdateDto): Promise<RivalRuleModel> {
    const dto = await ApiClient.put<RivalRuleDto>(`${this._baseUrl}/${payload.id}`, payload);
    return RivalRuleModel.fromDto(dto);
  }

  async bulkAssign(request: RivalRuleAssignmentPayload): Promise<void> {
    return await ApiClient.patch<void>(`${this._baseUrl}/bulk-assign`, request);
  }
}

export const rivalRulesService = new RivalRulesService();
