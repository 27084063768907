import { en as LinkedClassRules } from '../linked-class-rules/locales/en';
import { en as RivalRules } from '../rival-rules/locales/en';

export const en = {
  ...LinkedClassRules,
  ...RivalRules,
  rules: {
    rules: 'Rules',
    rule_id: 'Rule Id',
    new_rule: 'New Rule',
  },
};
