import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface ResolveLeftOverCapacityPayload extends BaseFlightActionPayload {
  cabinCode: CabinCode | undefined;
}

export class ResolveLeftOverCapacityAction implements FlightAction<ResolveLeftOverCapacityPayload> {
  public actionType: FlightActionType = FlightActionType.resolveByMatchingOvb;
  public label = t('dynamic_filters.resolve_leftover');
  public value:
    | FlightActionType.resolveByMatchingOvb
    | FlightActionType.resolveByMatchingUnbalancedAdjustment
    | FlightActionType.resolveByIgnoring;
  public cabinCode: CabinCode | undefined;

  constructor(cabinCode: CabinCode | undefined, actionType?: FlightActionType) {
    this.cabinCode = cabinCode;
    if (actionType) {
      this.actionType = actionType;
    }
  }

  public get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.resolveLeftover,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        // TODO: Have to figure how type can change because of the value change
        flightActionType: FlightActionType.resolveByMatchingOvb,
        field: FilterFieldField.resolveLeftover,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.resolveLeftover,
        uniqueField: this.cabinCode,
        value: this.actionType,
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/ResolveLeftOverCapacityActionValueRenderer.vue'),
        ),
        componentName: ComponentName.ResolveLeftoverField,
        isValueValid: () => true,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.cabinCode = action.uniqueField as CabinCode;
    this.actionType = action.value as FlightActionType;
  }

  public getPayload(): ResolveLeftOverCapacityPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
    };
  }

  public setPayload(payload: ResolveLeftOverCapacityPayload): void {
    this.cabinCode = payload.cabinCode;
    this.actionType = payload.actionType;
  }
}
