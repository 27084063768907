import { FlightAction } from '@/modules/flight-actions/api/flight-actions.contracts';
import { TriggerScheduleModel } from '@/modules/flowfx/models/TriggerSchedule.model';
import { FlowTrigger, ScheduleBasis } from '@/modules/flowfx/types/flow.types';

export enum FlowProperties {
  CONDITION_ACTION = 'conditionAction',
  CREATED_BY = 'createdBy',
  CREATED_DATE_TIME = 'createdDateTime',
  ID = 'id',
  IS_ACTIVE = 'isActive',
  IS_DEFAULT = 'isDefault',
  LAST_MODIFIED_BY = 'lastModifiedBy',
  LAST_MODIFIED_DATE_TIME = 'lastModifiedDateTime',
  NAME = 'name',
  OWNED_BY = 'ownedBy',
  TRIGGER_TYPE = 'triggerType',
  ASSIGNED_ROUTES = 'assignedRoutes',
  IS_ARCHIVED = 'isArchived',
  TRIGGER_SCHEDULE = 'triggerSchedule',
}

export enum CheckOperator {
  AND = 'and',
  OR = 'or',
}

export enum ConditionOperator {
  EQUAL = 'equal',
  NOT_EQUAL = 'not_equal',
  GREATER_THAN = 'greater_than',
  SMALLER_THAN = 'less_than',
  GREATER_THAN_OR_EQUAL = 'greater_than_or_equal',
  SMALLER_THAN_OR_EQUAL = 'less_than_or_equal',
  BETWEEN = 'between',
  NOT_BETWEEN = 'not_between',
  IN = 'in',
  NOT_IN = 'not_in',
  INCREASED = 'increased_by',
  DECREASED = 'decreased_by',
  ANY = 'any',
  ALL = 'all',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'not_contains',
  NONE = 'none',
}

export function isMultiSelectConditionOperator(operator: ConditionOperator): boolean {
  return [ConditionOperator.IN, ConditionOperator.NOT_IN].includes(operator);
}

export enum ConditionDataType {
  STRING = 'string',
  NUMERIC = 'numeric',
  INTEGER = 'integer',
  DATE = 'date',
  TIME = 'time',
  BOOLEAN = 'boolean',
  FLOAT = 'float',
}

interface IUser {
  id: number;
  name: string;
}

export interface IAssignableRoute {
  routeId: number;
  automationId?: number;
}

export enum FlowActionFrequencyValue {
  ALWAYS = 'always',
  FLIGHT_LIFETIME = 'flight-lifetime',
  EVERY = 'every',
}

export enum FlowActionFrequencyUnit {
  HOURS = 'hours',
  DAYS = 'days',
}

export interface IFlowActionFrequency {
  value: FlowActionFrequencyValue;
  interval?: {
    number: number;
    unit: FlowActionFrequencyUnit;
  };
}

export interface IActionBlock {
  id?: string;
  name: string;
  actions: FlightAction<unknown>[];
  frequency?: IFlowActionFrequency;
}

export interface IConditionBlock {
  name: string;
  conditionChecks: IConditionCheck[];
}

export interface IFlowTriggerType {
  id: FlowTrigger;
  canBeScheduled: boolean;
  timesSelectorIsEnabled: boolean;
  canAddActionsToElseBranch: boolean;
  canBeDefault: boolean;
  doesNeedRouteAssignment: boolean;
  canBeCreatedWithoutActionBlock: boolean;
}

export interface ITriggerSchedule {
  isEnabled?: boolean;
  basis?: ScheduleBasis;
  /**
   * We use the ISO-8601 standard, where the first day of the week is Monday.
   */
  days?: number[];
  times?: string[];
}

/**
 * The update DTO for a condition check.
 */
export interface ICondition<T = unknown> {
  /**
   * The list of selected conditionNames.
   */
  field: string[];
  operator: ConditionOperator;
  value: ConditionValue<T>;
}

export type ConditionValue<T> = T | IConditionValueRange<T>;

export interface IConditionValueRange<T> {
  from: T;
  to: T;
}

export interface IConditionValueBooking {
  bookings: number;
  daysInPast: number;
}

export interface IConditionValueFareWithCarrier extends IConditionValueFare {
  /**
   * A valid 2-letter Carrier code or 'ANY' for any carrier.
   */
  carrier?: string | 'ANY';
}

export interface IConditionValuePerfBandPickup {
  performanceBandDeltaValue: number;
  days: number;
}

export interface IConditionValueFare {
  amount: number;
  unit: string;
}

/**
 * The ConditionOption from the API.
 */
export interface IConditionOption {
  name: string;
  dataType: ConditionDataType;
  /**
   * The list of operators that can be used for the condition.
   */
  operators: ConditionOperator[];
  classifiers?: Classifier[];
  /**
   * In some cases there are some options to choose from provided by the API.
   */
  values?: (string | number)[];
}

/**
 * A nested classifier within a condition option.
 * @example { name: "Cabin", classifiers: [{ name: "Y" }] }
 */
export interface Classifier {
  name: string;
  classifiers?: Classifier[];
}

export interface ICheck {
  operator: CheckOperator;
  conditions: (ICheck | ICondition)[];
}

export interface IConditionCheck {
  check?: ICheck;
  actionBlock: IActionBlock;
  conditionBlock?: IConditionBlock;
}

export interface IGetFlowsParams {
  isArchived?: boolean;
  triggerTypes?: FlowTrigger[];
}

export interface IFlow {
  id: number;
  name: string;
  isActive: boolean;
  isDefault: boolean;
  triggerType: FlowTrigger;
  conditionAction: IConditionCheck;
  createdBy: IUser;
  createdDateTime: string;
  lastModifiedBy: IUser;
  lastModifiedDateTime: string;
  ownedBy: IUser;
  assignedRoutes: number[];
  isArchived: boolean;
  triggerFrequency: TriggerFrequencyDTO;
  triggerTypeMetadata: IFlowTriggerType;
  triggerSchedule?: TriggerScheduleModel;
  automationRoutineList?: Routine[];
}

export type TriggerFrequencyDTO = {
  /**
   * ISO8601 formatted date time.
   * @example 2022-10-01T10:23:54
   */
  lastTriggered?: string;
  last24Hours: number;
  last7Days: number;
};

export type Routine = {
  id?: number;
  days: number[];
  times: string[];
  queryId: number;
  flightActionGroupIds: number[];
};

export interface IFlowCreatePayload {
  isActive: boolean;
  isDefault: boolean;
  name: string;
  triggerType: FlowTrigger;
  conditionAction: IConditionCheck;
  triggerSchedule?: ITriggerSchedule;
  automationRoutineList?: Routine[];
}

export interface IFlowUpdatePayload {
  id: number;
  name?: string;
  isActive?: boolean;
  isArchived?: boolean;
  isDefault?: boolean;
  triggerType?: string;
  conditionAction?: IConditionCheck;
  triggerSchedule?: ITriggerSchedule;
  assignedRoutes?: number[];
  automationRoutineList?: Routine[];
}

export interface IFlowCopyPayload {
  id: number;
  name: string;
}

export interface IFlowsService {
  create(payload: IFlowCreatePayload): Promise<IFlow>;
  copy(payload: IFlowCopyPayload): Promise<IFlow>;
}
