<template>
  <el-select
    :allow-create="false"
    :clearable="true"
    :disabled="disabled"
    :filter-method="filterOptions"
    :model-value="selectedValues"
    :multiple="multiple"
    :reserve-keyword="false"
    class="w-full"
    collapse-tags
    collapse-tags-tooltip
    data-dd-action-name="Carrier Code Filter"
    data-test="carrier-code-select"
    default-first-option
    filterable
    placeholder="Select"
    @change="onChange"
    @visible-change="onVisibleChange"
  >
    <el-option-group v-for="group in selectOptionGroups" :key="group.label" :label="group.label">
      <el-option
        v-for="(item, index) in group.options"
        :key="`${item.value}-carrier-code-${index}`"
        :label="item.label"
        :value="item.value"
        :data-test="'carrier-code-select-option-' + item.value"
        :data-dd-action-name="'Carrier Code Filter option: ' + item.label"
      />
    </el-option-group>
  </el-select>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

import { selectOptionGroups } from '@/modules/shared/services/select.service';
import { OptionGroup, SingleSelectOption } from '@/modules/shared/types/select.interface';

const selectAll = 'selectAll';
export default defineComponent({
  name: 'CarrierCodeSelect',
  props: {
    options: {
      type: Array as PropType<string[]>,
      default: (): string[] => [],
    },
    selectedValues: {
      type: Array as PropType<string[]>,
      default: (): string[] => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-change'],
  data() {
    return {
      query: '',
    };
  },
  computed: {
    transformedOptions(): SingleSelectOption[] {
      const options = this.options.map((option) => ({
        value: option,
        label: option,
      }));
      return this.multiple && this.selectedValues.length !== this.options.length
        ? [{ value: selectAll, label: this.$t('shared.select_all') }, ...options]
        : options;
    },
    selectOptionGroups(): OptionGroup[] {
      return selectOptionGroups(this.transformedOptions, this.multiple ? this.selectedValues : [this.selectedValues], this.query);
    },
  },
  methods: {
    onChange(newValue: any) {
      if (newValue.includes(selectAll)) {
        this.$emit('on-change', this.options);
      } else {
        this.$emit('on-change', newValue);
      }
    },
    filterOptions(e: string) {
      this.query = e.toLowerCase();
    },
    onVisibleChange(e: boolean) {
      if (!e) {
        this.query = '';
      }
    },
  },
});
</script>
