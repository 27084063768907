import { AxiosStatic } from 'axios';
import { ElMessageBox } from 'element-plus';
import { unref } from 'vue';

import { ApiError } from '@/modules/api/base-client';
import { HTTPStatusCode } from '@/modules/api/status-codes';
import { useAuthStore } from '@/modules/external/store/auth.store';
import { router } from '@/modules/router';
import { ErrorCode, ErrorCodeMessages } from '@/modules/shared/types/error-codes';
import { EddyError } from '@/modules/shared/types/error-response';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export enum ErrorModal {
  BATCH_IMPORT = 'batchImport',
  CONFLICT = 'conflict',
  SERVICE_UNAVAILABLE = 'serviceUnavailable',
}

export const errorModals: {
  [key in ErrorModal]: {
    isVisible: boolean;
    contentString: string;
  };
} = {
  [ErrorModal.BATCH_IMPORT]: {
    isVisible: false,
    contentString: 'messages.batch_running',
  },
  [ErrorModal.CONFLICT]: {
    isVisible: false,
    contentString: 'messages.conflict',
  },
  [ErrorModal.SERVICE_UNAVAILABLE]: {
    isVisible: false,
    contentString: 'messages.service_unavailable',
  },
};

function showErrorModal(modalType: ErrorModal): void {
  const modal = errorModals[modalType];

  if (!modal.isVisible) {
    modal.isVisible = true;

    ElMessageBox.alert(t(modal.contentString), {
      confirmButtonText: t('ok'),
      callback: () => (modal.isVisible = false),
    });
  }
}

export async function errorInterceptor(error: ApiError): Promise<void> {
  if (error.response?.status === HTTPStatusCode.Unauthorized) {
    if (unref(router.currentRoute).name !== RouteName.login) {
      const authStore = useAuthStore();
      await authStore.logout();
      await router.push({ name: RouteName.login });
    }
  } else if (error.response?.status === HTTPStatusCode.Conflict) {
    /** during batch import writes are locked */
    if (error.response?.data?.errors?.some(({ errorCode }) => errorCode === ErrorCode.BatchImportJobRunning)) {
      showErrorModal(ErrorModal.BATCH_IMPORT);
    } else {
      /** Could be multiple services are trying to write at the same time */
      showErrorModal(ErrorModal.CONFLICT);
    }
  } else if (error.response?.status === HTTPStatusCode.ServiceUnavailable) {
    /** A specific service is down */
    showErrorModal(ErrorModal.SERVICE_UNAVAILABLE);
  } else if (error.response?.status === HTTPStatusCode.BadRequest) {
    /** Assign UI message to error response */
    if (error.response.data) {
      error.response.data.errors =
        error.response.data.errors
          ?.map((errorResponse) => ({
            ...errorResponse,
            uiMessage: ErrorCodeMessages.get(errorResponse.errorCode as number),
          }))
          .filter((err): err is EddyError => !!err) ?? [];
    }
  }

  return Promise.reject(error);
}

export const registerErrorInterceptor = (axios: AxiosStatic): void => {
  axios.interceptors.response.use((response) => response, errorInterceptor);
};
