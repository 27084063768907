import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const DASHBOARD_ROUTES: RouteRecordRaw[] = [
  {
    path: 'general',
    redirect: { name: RouteName.dashboard },
    children: [
      {
        path: 'dashboard',
        name: RouteName.dashboard,
        meta: {
          authorities: [Authority.FlightRead],
          title: t('dashboard.titles.dashboard'),
        },
        component: () => import('@/modules/dashboard/views/DashboardView.vue'),
      },
    ],
  },
];
