import axios from 'axios';

import { ApiClient } from '@/modules/api/base-client';
import { ApiVersionStructure, VersionStructure } from '@/modules/docs/api/docs.contracts';

class DocsService {
  private basePath = 'release-notes';

  public async getImageAsBlobString(version: string, imgName: string, abortController: AbortController): Promise<string> {
    const headers = ApiClient.getHeaders();

    const response = await axios.get(`${this.basePath}/images`, {
      headers: Object.fromEntries(headers),
      withCredentials: true,
      responseType: 'blob',
      signal: abortController.signal,
      params: {
        version,
        image: imgName,
      },
    });

    return URL.createObjectURL(response.data);
  }

  public async getReleaseNotesForVersion(version: string): Promise<any> {
    return await ApiClient.get(`${this.basePath}`, { version });
  }

  public async getStructure(): Promise<VersionStructure> {
    const structure = await ApiClient.get<ApiVersionStructure>(`${this.basePath}/structure`);
    return structure.children;
  }
}

export const docsService = new DocsService();
