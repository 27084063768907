import { ApiClient } from '@/modules/api/base-client';

import { CreateRouteGroupPayload, IRouteGroupsService, RouteGroup } from './route-groups.contracts';

export class RouteGroupsService implements IRouteGroupsService {
  private basePath = 'route-groups';

  public async getAll(): Promise<RouteGroup[]> {
    const response = await ApiClient.get<{
      content: RouteGroup[];
    }>(this.basePath);
    return response.content.sort((a, b) => a.name.localeCompare(b.name));
  }

  public async post(routeGroup: CreateRouteGroupPayload): Promise<RouteGroup> {
    return await ApiClient.post<RouteGroup>(`${this.basePath}`, routeGroup);
  }
}

export const routeGroupsService = new RouteGroupsService();
