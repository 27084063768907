import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const CUSTOMER_CONFIG_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/customer-config',
    name: RouteName.customerConfig,
    meta: {
      authorities: [Authority.CustomerConfigRead],
      title: t('customer_config.titles.customer_configuration'),
    },
    component: () => import('@/modules/customer-config/views/CustomerConfig.vue'),
  },
];
