import { ApiClient } from '@/modules/api/base-client';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { FilterService } from '@/services/filter.service';

import { CustomerDefinedDataResponse, CustomerDefinedDataSchemaResponse } from './customer-defined-data.service.model';

export class CustomerDefinedDataService {
  private basePath = 'customer-defined-data';

  public async getByFlightKey(flightKey: string): Promise<CustomerDefinedDataResponse> {
    return ApiClient.get<CustomerDefinedDataResponse>(`${this.basePath}/${flightKey}`);
  }

  public async search(filters: FilterFieldDefinition[]): Promise<CustomerDefinedDataResponse[]> {
    const transformedRequest = FilterService.transformRequest(filters);
    return ApiClient.post<CustomerDefinedDataResponse[]>(`${this.basePath}/search`, transformedRequest);
  }

  public async getSchema(): Promise<CustomerDefinedDataSchemaResponse> {
    return ApiClient.get<CustomerDefinedDataSchemaResponse>(`${this.basePath}/schema`);
  }
}

export const customerDefinedDataService = new CustomerDefinedDataService();
