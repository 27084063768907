import {
  CustomerDefinedData,
  CustomerDefinedDataResponse,
  CustomerDefinedDataSchemaItem,
  CustomerDefinedDataSchemaResponse,
  CustomerDefinedDataType,
} from '@/modules/customer-defined-data/services/customer-defined-data.service.model';
import {
  CustomerDefinedDataUIItem,
  CustomerDefinedDataUIItemData,
} from '@/modules/customer-defined-data/store/customer-defined-data.module.model';

function transformToUiModel(
  customData: CustomerDefinedData,
  fields: { [key: string]: CustomerDefinedDataSchemaItem },
): CustomerDefinedDataUIItemData[] {
  return Object.keys(customData)?.map((key) => ({
    key,
    value: customData[key] as CustomerDefinedDataType,
    title: fields?.[key]?.title ?? key,
    description: fields?.[key]?.description ?? '',
    type: fields?.[key]?.type ?? 'string',
    ...(fields[key] ?? {}),
  }));
}

export function mapResponseToUIModel(
  response: CustomerDefinedDataResponse,
  schema: CustomerDefinedDataSchemaResponse,
): CustomerDefinedDataUIItem {
  const { flightKey, cabins, ...customData } = response;

  const mappedResponse: CustomerDefinedDataUIItem = {
    flightKey,
    data: transformToUiModel(customData, schema.flightFields),
  };

  cabins?.forEach((cabinData) => {
    const { cabinCode, ...customCabinData } = cabinData;
    mappedResponse[`cabin_${cabinCode}`] = {
      cabinCode,
      data: transformToUiModel(customCabinData, schema.cabinFields),
    };
  });

  return mappedResponse;
}
