import { Library } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faArrowAltToBottom,
  faArrowDown,
  faArrowLeft,
  faArrowsH,
  faArrowsMaximize,
  faBars,
  faBolt,
  faCalendar,
  faCalendarStar,
  faCalendarTimes,
  faChalkboard,
  faChartMixed,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCloudQuestion,
  faCloudUpload,
  faCog,
  faCopy,
  faDownload,
  faEdit,
  faEnvelope,
  faExchange,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFaceFrownSlight,
  faFaceMeh,
  faFaceSmileBeam,
  faFile,
  faFloppyDisks,
  faHockeySticks,
  faIndustry,
  faInfoCircle,
  faLink,
  faList,
  faLock,
  faLockOpen,
  faLongArrowRight,
  faMapLocationDot,
  faMobile,
  faMoneyBillWave,
  faPaperPlane,
  faPen,
  faPencil,
  faPercentage,
  faPlane,
  faPlaneDeparture,
  faPlayCircle,
  faPlus,
  faPlusHexagon,
  faRedo,
  faRobot,
  faRoute,
  faSave,
  faScaleUnbalanced,
  faSearch,
  faServer,
  faSms,
  faSpinnerThird,
  faSplit,
  faTasks,
  faText,
  faTimes,
  faTrash,
  faTrashAlt,
  faTrashUndo,
  faUndo,
  faUser,
  faUsers,
  faWaveSine,
} from '@fortawesome/pro-light-svg-icons';

export function addLightIcons(library: Library): void {
  library.add(
    faArchive,
    faArrowAltToBottom,
    faArrowLeft,
    faArrowDown,
    faArrowsH,
    faBars,
    faBolt,
    faCalendar,
    faCalendarStar,
    faCalendarTimes,
    faCheck,
    faClock,
    faCloudUpload,
    faCog,
    faCopy,
    faDownload,
    faEdit,
    faEnvelope,
    faExchange,
    faExclamationTriangle,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFaceFrownSlight,
    faFaceMeh,
    faFaceSmileBeam,
    faFile,
    faHockeySticks,
    faIndustry,
    faInfoCircle,
    faLink,
    faList,
    faLock,
    faLockOpen,
    faLongArrowRight,
    faMapLocationDot,
    faMobile,
    faMoneyBillWave,
    faPaperPlane,
    faPen,
    faPercentage,
    faPlayCircle,
    faPlus,
    faPlusHexagon,
    faRobot,
    faRoute,
    faSave,
    faSearch,
    faServer,
    faSms,
    faPencil,
    faSpinnerThird,
    faTasks,
    faText,
    faTimes,
    faTrash,
    faTrashAlt,
    faTrashUndo,
    faUndo,
    faUser,
    faWaveSine,
    faPlane,
    faCloudQuestion,
    faScaleUnbalanced,
    faChartMixed,
    faSplit,
    faPlaneDeparture,
    faUsers,
    faChalkboard,
    faArrowsMaximize,
    faFloppyDisks,
    faRedo,
    faChevronLeft,
    faChevronRight,
  );
}
