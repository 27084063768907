import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { logger } from '@/modules/monitoring';
import { routesService } from '@/modules/route-management';
import { RouteModel } from '@/modules/route-management/api/routes/routes.contracts';
import { MessageService } from '@/modules/shared';
import { i18n } from '@/plugins/i18n';

export interface Hub {
  code: string;
  fullName: string;
  flightPaths: Set<string>;
}

export class Hub {
  public code: string;
  public fullName: string;
  public flightPaths: Set<string>;

  constructor(code: string, fullName: string, flightPaths: Set<string>) {
    this.code = code;
    this.fullName = fullName;
    this.flightPaths = flightPaths;
  }
}

export type HubMap = Map<Hub['code'], Hub>;

export const useMarketInfoStore = defineStore('marketInfo', () => {
  const _collator = new Intl.Collator(i18n.global.locale.value);

  const isLoading: Ref<boolean> = ref(false);
  const routes: Ref<RouteModel[]> = ref([]);

  const _hubMap: ComputedRef<HubMap> = computed(uniqueHubsGetter);
  const uniqueHubs: ComputedRef<Hub[]> = computed(() =>
    Array.from(_hubMap.value, ([, value]) => value).sort(({ code: codeA }, { code: codeB }) => _collator.compare(codeA, codeB)),
  );

  async function get(): Promise<void> {
    try {
      isLoading.value = true;
      routes.value = await routesService.getAllRoutes();
    } catch (error) {
      MessageService.failedRequest('Failed to fetch market info');
      logger.error(error as Error);
    } finally {
      isLoading.value = false;
    }
  }

  function setHub(hubMap: HubMap, code: string, name: string, path: string): void {
    if (hubMap.has(code)) {
      hubMap.get(code)?.flightPaths.add(path);
    } else {
      hubMap.set(code, new Hub(code, name, new Set([path])));
    }
  }

  function uniqueHubsGetter(): HubMap {
    return routes.value.reduce((acc, route): HubMap => {
      setHub(acc, route.origin, route.airportOriginName ?? '', route.flightPath);
      setHub(acc, route.destination, route.airportDestinationName ?? '', route.flightPath);

      return acc;
    }, new Map<Hub['code'], Hub>());
  }

  function $reset(): void {
    isLoading.value = false;
    routes.value = [];
  }

  return {
    routes,
    uniqueHubs,
    isLoading,
    $reset,
    get,
  };
});
