<template>
  <div class="mb-2">
    <k-draggable-list-header :disabled="false">
      <linked-class-rule-detail-headers class="w-full" />
    </k-draggable-list-header>

    <k-draggable
      :model-value="rules"
      item-key="classCode"
      data-test="linked-class-rule-detail-list"
      @update:model-value="emitUpdatedRules"
      @item-remove="onItemRemoved"
    >
      <template #default="{ element, index }">
        <div class="flex w-full gap-x-2">
          <div class="w-1/4">
            <class-code-select
              data-test="class-code-options"
              :model-value="element.classCode"
              :options="getClassCodeOptions(rules, element.linkingClassCode)"
              @update:model-value="onClassCodeUpdate($event, element, index)"
            />
          </div>
          <div class="w-1/4">
            <el-input
              :model-value="element.value"
              maxlength="3"
              :max="999"
              data-test="linked-class-value-input"
              @update:model-value="onValueUpdate($event, element, index)"
            />
          </div>
          <div class="w-1/4">
            <class-rule-unit-select v-model="element.unit" />
          </div>
          <div class="w-1/4">
            <class-code-select
              data-test="linked-class-options"
              :model-value="element.linkingClassCode"
              :options="getLinkingClassCodeOptions(element.classCode)"
              @update:model-value="onLinkingClassCodeUpdate($event, element, index)"
            />
          </div>
        </div>
      </template>
    </k-draggable>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

import ClassCodeSelect from '@/modules/rules/linked-class-rules/components/ClassCodeSelect.vue';
import ClassRuleUnitSelect from '@/modules/rules/linked-class-rules/components/ClassRuleUnitSelect.vue';
import LinkedClassRuleDetailHeaders from '@/modules/rules/linked-class-rules/components/LinkedClassRuleDetailHeaders.vue';
import { LinkedClassRuleDetailModel } from '@/modules/rules/linked-class-rules/models/linked-class-rule.model';
import {
  getClassCodeOptionsExcludingRule,
  getFilteredClassCodeOptions,
} from '@/modules/rules/linked-class-rules/utils/filter-class-code-options.util';
import { KDraggableRemoveItemEvent } from '@/modules/shared/components/draggable/KDraggable.types';
import KDraggable from '@/modules/shared/components/draggable/KDraggable.vue';
import KDraggableListHeader from '@/modules/shared/components/draggable-list/KDraggableListHeader.vue';
import { OptionGroup } from '@/modules/shared/types/select.interface';
import { ConversionService } from '@/services/conversion.service';

export default defineComponent({
  components: {
    KDraggable,
    KDraggableListHeader,
    ClassCodeSelect,
    ClassRuleUnitSelect,
    LinkedClassRuleDetailHeaders,
  },
  props: {
    rules: {
      type: Array as PropType<LinkedClassRuleDetailModel[]>,
      required: true,
    },
  },
  emits: ['update:rules'],
  setup(props, { emit }) {
    function onItemRemoved({ index }: KDraggableRemoveItemEvent) {
      const rules = [...props.rules];
      rules.splice(index, 1);
      emitUpdatedRules(rules);
    }

    function emitUpdatedRules(updatedRules: LinkedClassRuleDetailModel[]) {
      emit('update:rules', updatedRules);
    }

    function getLinkingClassCodeOptions(classToExclude: string): OptionGroup[] {
      return getFilteredClassCodeOptions(classToExclude);
    }

    function onValueUpdate($event: any, item: LinkedClassRuleDetailModel, index: number) {
      const updatedRules = [...props.rules];
      updatedRules[index] = new LinkedClassRuleDetailModel({ ...item, value: ConversionService.removeNonNumericCharsFromInput($event) });
      emitUpdatedRules(updatedRules);
    }

    /**
     * Filters the class code options, by exluding the linking class selected (you cannot select the same class as class _and_ linking class),
     * and other classes that are already linked in this model.
     */
    function getClassCodeOptions(rules: LinkedClassRuleDetailModel[], classToExclude: string): OptionGroup[] {
      return getClassCodeOptionsExcludingRule(rules, classToExclude);
    }

    function onLinkingClassCodeUpdate($event: string, element: LinkedClassRuleDetailModel, index: number) {
      const updatedRules = [...props.rules];
      updatedRules[index] = new LinkedClassRuleDetailModel({ ...element, linkingClassCode: $event });
      emitUpdatedRules(updatedRules);
    }

    function onClassCodeUpdate($event: string, element: LinkedClassRuleDetailModel, index: number) {
      const updatedRules = [...props.rules];
      updatedRules[index] = new LinkedClassRuleDetailModel({ ...element, classCode: $event });
      emitUpdatedRules(updatedRules);
    }

    return {
      emitUpdatedRules,
      getClassCodeOptions,
      getLinkingClassCodeOptions,
      onValueUpdate,
      onItemRemoved,
      onClassCodeUpdate,
      onLinkingClassCodeUpdate,
    };
  },
});
</script>
