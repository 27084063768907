import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';

import { Insight, insightsService } from '@/modules/insights/api/insights.service';

export const useInsightsStore = defineStore('insights', () => {
  const insights: Ref<Insight[]> = ref([]);
  const insightsSorted: Ref<Insight[]> = computed(() => insights.value.sort((a, b) => a.order - b.order));
  const availableInsights: Ref<Insight[]> = ref([]);
  const availableInsightNames: Ref<string[]> = computed(() =>
    availableInsights.value.sort((a, b) => a.order - b.order).map((insight) => insight.name),
  );

  const isLoading = ref(false);

  async function getEnabledInsights(): Promise<void> {
    try {
      isLoading.value = true;
      insights.value = await insightsService.getEnabledInsights();
    } finally {
      isLoading.value = false;
    }
  }

  async function getAvailableInsights(): Promise<void> {
    try {
      isLoading.value = true;
      availableInsights.value = await insightsService.getAvailableInsights();
    } finally {
      isLoading.value = false;
    }
  }

  function $reset(): void {
    isLoading.value = false;
    insights.value = [];
  }

  return {
    isLoading,
    insights: insightsSorted,
    availableInsightNames,
    getEnabledInsights,
    getAvailableInsights,
    $reset,
  };
});
