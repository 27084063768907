import { Locale, Path, createI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import messages from '@/locales/en';
import { logger } from '@/modules/monitoring';

export const i18n = createI18n({
  legacy: false,
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
  warnHtmlMessage: false,
  missing: (locale: Locale, key: Path) => {
    const route = useRoute();

    logger.error(new Error(`Locale missing: ${key}`), {
      locale,
      key,
      route,
    });
  },
});
