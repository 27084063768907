import { DayOfWeek } from '@/modules/shared/types/day-of-week';

export const en = {
  shared: {
    real_time_bookings_pickup: 'RT Pickup',
    real_time_performance_band_pickup: 'RT Performance band Pickup',
    competitors: 'Competitors',
    ndo_bookings: 'Ndo Bookings',
    ndo_revenues: 'Ndo Revenues',
    ndo: 'NDO',
    selected: 'Selected',
    pickups: 'Pickups',
    bookings_pickups: 'Bookings Pickups',
    revenue_pickups: 'Revenue Pickups',
    performance_band_pickups: 'Performance Band Pickups',
    performance_band_pickups_tooltip: 'Performance Band Pickups will only be visible if the flight has an assigned cluster.',
    feedback_form: {
      provide_feedback: 'Provide Feedback',
      cancel: 'Cancel',
      send_feedback: 'Send Feedback',
      feedback_sent: 'Feedback sent! Thank you for your input.',
      required_emotion: 'Please select an emotion',
      required_comment: 'Please provide a comment',
    },
    all: 'All',
    any: 'Any',
    select_all: 'Select all',
    flight_number_range_placeholder: 'e.g. 1-5, 8, 110-200',
    select_season_placeholder: 'Select a Season...',
    day: 'day | days',
    yes: 'Yes',
    no: 'No',
    // Field validation messages
    invalid_field: {
      required: 'Field is required',
      min_one_value: 'Provide at least one value',
      integer: 'Value must be an integer',
      positive_integer: 'Value must be a positive integer',
      max_length: 'Length must be max {n} characters',
    },
    day_of_week: {
      [DayOfWeek.MONDAY]: {
        short: 'Mon',
        long: 'Monday',
      },
      [DayOfWeek.TUESDAY]: {
        short: 'Tue',
        long: 'Tuesday',
      },
      [DayOfWeek.WEDNESDAY]: {
        short: 'Wed',
        long: 'Wednesday',
      },
      [DayOfWeek.THURSDAY]: {
        short: 'Thu',
        long: 'Thursday',
      },
      [DayOfWeek.FRIDAY]: {
        short: 'Fri',
        long: 'Friday',
      },
      [DayOfWeek.SATURDAY]: {
        short: 'Sat',
        long: 'Saturday',
      },
      [DayOfWeek.SUNDAY]: {
        short: 'Sun',
        long: 'Sunday',
      },
    },
  },
};
