import { LocationQuery, LocationQueryValue, NavigationGuard, NavigationGuardNext, RouteMeta } from 'vue-router';

import { useSettingsStore } from '@/modules/application/store/settings.store';
import { useAuthStore } from '@/modules/external/store/auth.store';
import { MessageService } from '@/modules/shared';
import { RouteName } from '@/modules/shared/types/routes';
import { useUserStore } from '@/modules/user-settings/store/user.store';
import { JwtService } from '@/services/jwt.service';

async function logOut(next: NavigationGuardNext, query: LocationQuery): Promise<void> {
  const authStore = useAuthStore();
  await authStore.logout();
  next({ name: RouteName.login, query });
}

/**
 * We first check if the route is 'login' which is open regardless if the user is authenticated
 * Then we check if the token is valid if not redirect to login
 * If we have a valid token we verify if the user is authorized to access the route
 * If not we stay on the current page and show a 'not allowed' message
 */
export const globalGuard: NavigationGuard = async (to, from, next) => {
  const redirect = window.location.pathname + window.location.search;

  const query: LocationQuery = {};
  if (redirect !== '/' && !redirect.includes('external/login')) {
    query.redirect = redirect;
  }

  const authStore = useAuthStore();
  const { token } = authStore;

  /** When we have a token and user tries to go to 'login' we redirect to the app default page, since user is already logged in */
  try {
    if (token) {
      const isTokenExpired = JwtService.isTokenExpired(token);
      if (isTokenExpired) {
        await logOut(next, query);
        return;
      } else if (to.name === RouteName.login) {
        next({ name: RouteName.appDefault });
        return;
      }
    } else if (to.name === RouteName.login) {
      next();
      return;
    } else {
      await logOut(next, query);
      return;
    }
  } catch (err) {
    await logOut(next, query);
    return;
  }

  try {
    const settingsStore = useSettingsStore();
    const routeMeta: RouteMeta = to.meta;

    /** Grab the customer settings if not fetched already */
    if (token && !settingsStore.hasFetchedSettings) {
      await settingsStore.get();
    }

    /** Check the isAllowed function and see if it doesn't pass (returns false) */
    if (routeMeta && routeMeta.isAllowed && !routeMeta.isAllowed()) {
      next({
        name: RouteName.appDefault,
      });

      MessageService.noPermission();

      return;
    }

    const userStore = useUserStore();
    const { user } = userStore;

    /** Check authorities */
    if (
      routeMeta?.authorities &&
      user?.authorities &&
      !routeMeta.authorities.every((authority) => typeof authority === 'string' && user?.authorities.includes(authority))
    ) {
      const fallbackNavigation = to.query?.fallbackNavigation as LocationQueryValue;
      fallbackNavigation ? next({ name: fallbackNavigation }) : next(false);
      MessageService.noPermission(!!fallbackNavigation);

      return;
    }

    /** If forceChangePassword is true redirect user to userChangePassword view unless user tries to log out */
    if (user?.forceChangePassword && to.name !== RouteName.userChangePassword && to.name !== RouteName.login) {
      next({
        name: RouteName.userChangePassword,
      });

      return;
    }

    /** If above checks pass, continue */
    next();
    return;
  } catch (ex) {
    await logOut(next, query);
    return;
  }
};
