import { LinkedClassRuleAssignmentPayload } from '@/modules/data-import/api/data-import/data-import-contracts';
import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';

import { LinkedClassRuleModel } from '../models/linked-class-rule.model';

export interface ILinkedClassRulesService {
  create(payload: LinkedClassRuleDto): Promise<LinkedClassRuleModel>;
  getAll(): Promise<LinkedClassRuleModel[]>;
  getById(id: number): Promise<LinkedClassRuleModel>;
  update(payload: LinkedClassRuleUpdateDto): Promise<LinkedClassRuleModel>;
  bulkAssign(request: LinkedClassRuleAssignmentPayload): Promise<void>;
}

export interface LinkedClassRuleDto {
  id: number;
  name: string;
  description: string;
  createdBy: UserModel;
  modifiedBy: UserModel;
  createdDateTime: string;
  modifiedDateTime?: string;
  isArchived: boolean;
  details: Array<LinkedClassRuleDetailDto>;
}

export interface LinkedClassRuleUpdateDto {
  id: number;
  name: string;
  description: string;
  isArchived: boolean;
  details: Array<LinkedClassRuleDetailDto>;
}

export interface LinkedClassRuleDetailDto {
  ruleId: number;
  rank: number;
  dependentClassCabinCode: string;
  dependentClassCode: string;
  value: number;
  sourceClassCabinCode: string;
  sourceClassCode: string;
  unit: LinkedClassRuleUnit;
}

/**
 * The Unit represents the type of action that will use the Link Class Rules' value and linking class' value as input,
 * and applies it to the class selected.
 */
export enum LinkedClassRuleUnit {
  PERCENT_OF_AU = 'PERCENT_OF_AU',
}
