import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const EVENT_MANAGEMENT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/event-management',
    name: RouteName.eventManagementOverview,
    redirect: { name: RouteName.eventManagementInclusions },
    meta: {
      authorities: [Authority.FlightRead],
      title: t('event_management.titles.event_management'),
    },
    component: () => import('../views/EventManagementOverview.vue'),
    children: [
      {
        path: 'inclusions',
        name: RouteName.eventManagementInclusions,
        component: () => import('../views/EventManagementTable.vue'),
      },
      {
        path: 'exclusions',
        name: RouteName.eventManagementExclusions,
        component: () => import('../views/EventManagementTable.vue'),
      },
    ],
  },
];
