import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const ROUTE_MANAGEMENT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/route-management',
    name: RouteName.routeManagement,
    meta: {
      authorities: [Authority.RouteRead],
      title: t('route_management.titles.route_management'),
    },
    component: () => import('../views/RouteManagementView.vue'),
  },
];
