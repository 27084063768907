import { isEmpty } from 'lodash-es';
import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { customerDefinedDataService } from '@/modules/customer-defined-data/services/customer-defined-data.service';
import { CustomerDefinedDataSchemaResponse } from '@/modules/customer-defined-data/services/customer-defined-data.service.model';
import { mapResponseToUIModel } from '@/modules/customer-defined-data/store/customer-defined-data-mapper';
import { CustomerDefinedDataUIItem } from '@/modules/customer-defined-data/store/customer-defined-data.module.model';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { logger } from '@/modules/monitoring';

export const useCustomerDefinedDataStore = defineStore('customerDefinedData', () => {
  const defaultSchema: CustomerDefinedDataSchemaResponse = { flightFields: {}, cabinFields: {} };

  const schema: Ref<CustomerDefinedDataSchemaResponse> = ref(defaultSchema);
  const data: Ref<Map<string, CustomerDefinedDataUIItem>> = ref(new Map());

  const schemaIsEmpty: ComputedRef<boolean> = computed(() => isEmpty(schema.value.flightFields) && isEmpty(schema.value.cabinFields));

  const byFlightKey: ComputedRef<(flightKey: string) => CustomerDefinedDataUIItem | undefined> = computed(
    () => (flightKey: string) => data.value.get(flightKey),
  );

  async function getSchema(): Promise<void> {
    try {
      schema.value = await customerDefinedDataService.getSchema();
    } catch {
      schema.value = defaultSchema;
    }
  }

  async function getByFlightKey(flightKey: string): Promise<void> {
    const response = await customerDefinedDataService.getByFlightKey(flightKey);

    if (schemaIsEmpty.value) {
      await getSchema();
    }

    const item = mapResponseToUIModel(response, schema.value);
    upsertData([item]);
  }

  async function search(filters: FilterFieldDefinition[]): Promise<void> {
    try {
      const responses = await customerDefinedDataService.search(filters);

      if (schemaIsEmpty.value) {
        await getSchema();
      }

      const items = responses.map((response) => mapResponseToUIModel(response, schema.value));
      upsertData(items);
    } catch (error) {
      logger.error(error as Error);
    }
  }

  function upsertData(payload: CustomerDefinedDataUIItem[]): void {
    const newMap = new Map([...data.value]);
    payload.forEach((item) => newMap.set(item.flightKey, item));
    data.value = newMap;
  }

  function $reset(): void {
    schema.value = { flightFields: {}, cabinFields: {} };
    data.value = new Map();
  }

  return {
    schema,
    getSchema,
    search,
    byFlightKey,
    getByFlightKey,
    $reset,
  };
});
