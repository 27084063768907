export const en = {
  customer_config: {
    titles: {
      customer_configuration: 'Customer Configuration',
    },
    customer_configuration: 'Customer Configuration',
    localization: 'Localization',
    customer_config_saved: 'Customer Configuration Saved',
    fare_conversion: {
      label: 'Fare Conversion',
      description:
        'If enabled, (own) fares are converted to the route currency. This must be set to false in the case of published fares in multiple currencies.',
    },
    has_distance_in_miles: {
      label: 'Distance in Miles',
      description: 'If enabled, the reporting distance unit is miles. When disabled, this is kilometers.',
    },
    batch: 'Batch Import',
    load_external_currency_data: {
      label: 'Load External Currency Data',
      description: 'If enabled, the batch import will not load the currency data from the daily PSS files, but use an external source.',
    },
    load_external_class_fares_data: {
      label: 'Load External Class Fares Data',
      description: 'If enabled, the batch import will not load the class fares data from the daily PSS files, but use an external source.',
    },
    nest_constraint_availability: {
      label: 'Constraint Seat Availability',
      description:
        "Navitaire has the ability to have nest level lids and constrain availability at a nest level, but SRM Flex doesn't support this because it does not store the nest level lids. If enabled, SRM Flex will use the script of tmp_class_aggregation_to_flight_class_details for Y4 as a workaround to integrate this data from Y4 that arrives with specified nest availability. If disabled, the script for the other carriers will be used.",
    },
    sum_of_sector_enabled: {
      label: 'Sum of Sector',
      description:
        'This is determined by how the fare information is received in the bookings file (S) for flights with stops . If enabled, only leg data is received (A-B, BC). If disabled, both leg and segment data are received (A-B, B-C, A-B-C).',
    },
    authorized_capacity_equals_sum_of_nest_lids: {
      label: 'Auth Capacity Equals Sum of Nest Lids',
      description:
        'If enabled, the authorized capacity equals the sum of nested lids. This setting determines how seat availability is calculated in the SRM Flex API for discrete classes within nests. Discrete classes are not part of the nest unless this setting is enabled.',
    },
    display_booking_details: {
      label: 'Display Booking Details',
      description: 'If enabled, Booking Details information is available (only in 1N and 1X environments).',
    },
    security: 'Security',
    multi_factor_authentication: {
      label: 'Multifactor Authentication',
      description: 'If enabled, the multifactor authentication by SMS is turned on.',
    },
    change_password_interval_days: {
      label: 'Change Password Interval',
      description: 'Force a password reset after the chosen number of days.',
    },
    change: 'Change Details',
    user: {
      label: 'Updated By',
      description: 'Please enter your name here for the change log.',
    },
    reason: {
      label: 'Change Reason',
      description: 'Please state why the change is required.',
    },
    features: 'Features',
    airAsiaDataScience: {
      label: 'Data Science Recommendations For Air Asia',
      description: 'Enable data science recommendation functionality for Air Asia.',
    },
    forecasting: {
      label: 'Forecasting',
      description: 'Enable forecasting of bookings.',
    },
    forecasting_and_dynamic_program: {
      label: 'Forecast+Dynamic Program',
      description: 'Enable Forecast+Dynamic Program. This enables forecasting tactics and the Bid Price Vector',
    },
    number_of_forecasting_and_dynamic_program_op_levels: {
      label: 'Total number of Forecast+Dynamic Program OP Levels',
      description:
        'If you want to reduce the number of levels, please make sure to verify the flights that have an OP level assigned will not be impacted. If so, please re-assign them first.',
    },
    overbooking: {
      label: 'Overbooking',
      description: 'If enabled, the aCap as defined in the database will be used and will not be calculated using the overbooking factor.',
    },
    gold_model: {
      label: 'Gold Model',
      description: 'Turning the Gold Model on will enable the Gold Model functionality on control and FlowFX.',
    },
    has_linked_class_rules_enabled: {
      label: 'Linked Class Rules',
      description: 'If enabled, the Rules menu item is available.',
    },
    has_rival_rules_enabled: {
      label: 'Rival Rules',
      description: 'If enabled, Rival Rules is available.',
    },
    realtime_on_demand: {
      label: 'Realtime',
      description: '',
    },
    insights: {
      label: 'Insights',
      description: 'Enable the insights section within SRM Flex.',
    },
    insights_options: {
      label: 'Insights Explores',
      description: 'Configure which Explores are shown to the customer.',
    },
    nego_allocation: {
      label: 'Nego Allocation',
      description: 'Enable the NEGO Allocation columns.',
    },
    tour_operators: {
      label: 'TO/groups v2',
      description: 'New extended implementation of tour operators and groups (Jeju only)',
    },
    booking_overwrite: {
      label: 'Booking Overwrite',
      description: 'When this is enabled the imported sold values from the TO/Groups file will overwrite the bookings',
    },
    reporting: {
      label: 'Reporting',
      description: 'Enable reporting functionality.',
    },
    realtime_on_demand_source: {
      label: 'Realtime Bookings Refresh Type',
      description: `Determines how the bookings are received/pulled.
      <ul class="list-disc list-inside">
          <li>None: There is no source data; therefore, no updates are made to the realtime data.</li>
          <li>Streamed: Realtime data is continuously pushed into SRM Flex as it becomes available.</li>
          <li>Scheduled: Data is fetched from the realtime source at set intervals throughout the day.</li>
      </ul>
          `,
    },
    has_automations_enabled: {
      label: 'Automations',
      description: 'Allow FlowFX.',
    },
    has_customer_defined_data_enabled: {
      label: 'Customer Defined Data',
      description: 'Enable BYOD / customer-defined data for this customer.',
    },
    has_bring_your_own_recommendations_enabled: {
      label: 'Bring Your Own Recommendations',
      description: 'Enable BYOR / customer-defined OP tactics.',
    },
    has_construct_bring_your_own_recommendations_op_levels_enabled: {
      label: 'Construct BYOR OP Levels on/off',
      description: 'Construct BYOR OP Levels on/off',
    },
    number_of_bring_your_own_recommendations_op_levels: {
      label: 'Total number of BYOR OP Levels',
      description:
        'If you want to reduce the number of levels, please make sure to verify the flights that have an OP level assigned will not be impacted. If so, please re-assign them first.',
    },
    has_shadow_tactics_enabled: {
      label: 'Shadow Tactics',
      description: 'Enable shadow OP tactics.',
    },
    booking_source: {
      label: 'Booking Source',
      description: '',
    },
    none: 'None',
    streamed: 'Streamed',
    scheduled: 'Scheduled',
    realtime_trigger: {
      label: 'Realtime Trigger',
      description: 'Triggers the realtime process after the daily import finishes.',
    },
    flights: 'Flights',
    schedule_mode: {
      label: 'Schedule Mode',
      description:
        'Determines how SRM Flex receives data in the daily import files.\n' +
        'Segment: Use this if inventory files are available. This means we receive inventory for legs AB, BC and ABC. SRM Flex will display all the legs on the Flight Details page.\n' +
        'Network: The carrier has segments with more than one leg, but these are not managed as multileg flights. For example,  AB and BC but there is no inventory for ABC, so Flight Details will not display the inventory for ABC.',
    },
    has_auto_correct_enabled: {
      label: 'AU Structure Autocorrect',
      description: 'Automatically cleans up the AU structure after AU changes.',
    },
    own_fare_source: {
      label: 'Own Fare Source',
      description: 'Determines the data should be used in our own fares when we make comparisons to competitor fares.',
    },
    segment: 'Segment',
    network: 'Network',
    managed_network: 'Managed Network',
    competitive_fares: 'Competitor Fares',
    competitive_fares_inbound_logic: {
      label: 'Competitor Fares Inbound Logic',
      description: 'If enabled, roundtrips are converted into two single flights. Works on roundtrip fares only. ',
    },
    competitive_fare_max_data_age_days: {
      label: 'Competitor Fare Max Data Age Days',
      description:
        "Choose how many days you want to get competitor fare data for. One day means only today's capture date will be received. If you want to include data from seven days ago, the value should be eight. Competitive fare data is only stored in the working table from the last seven days.",
    },
    published_fares: 'Published Fares',
    dynamic_pricing: {
      label: 'Dynamic Pricing',
      description: 'If enabled, continuous pricing functionality is allowed.',
    },
    hemisphere: {
      label: 'Hemisphere',
      description: 'Determine the seasons.',
    },
    northern: 'Northern',
    southern: 'Southern',
    pricing_multiplier: {
      label: 'Pricing Multiplier',
      description: 'Multiply the price increment.',
    },
    max_price: {
      label: 'Maximum Price',
      description: 'Set the max. price of the Y class (only for airlines that use continuous pricing).',
    },
    price_adjustment_range: {
      label: 'Price Adjustment Range',
      description: 'Determine the price adjustment range.',
    },
    price_increment_range: {
      label: 'Price Increment Range',
      description: 'Determine the pricing increment.',
    },
    has_promotions_enabled: {
      label: 'Promotions',
      description: 'If enabled, promotions are allowed.',
    },
    flowfx: 'FlowFx',
    flowfx_scheduled_type: {
      label: 'Scheduled Type',
      description: 'If enabled, scheduled automations are allowed',
    },
    flowfx_routine_type: {
      label: 'Routine Type',
      description: 'If enabled, routine based automations are allowed',
    },
    max_routine_based_automations: {
      label: 'Max Routine Based Automations',
      description: 'Set the maximum number of routine based automations.',
    },
    has_overbooking_recommendations_enabled: {
      label: 'DS Recommended OVB Factors',
      description: 'Allow Data Science data to show Overbooking Recommendations',
    },
    default_overbooking_risk_percentage: {
      label: 'Set a Default Risk Rate on Airline Level',
      description: 'The default percentage of Overbooking Risk that is applied to all leg cabins',
    },
    pss_bookings_column: {
      label: 'Show PSS Bookings',
      description: 'If enabled, flight control and flight details will show the PSS Bookings.',
    },
    unbalanced_adjustments: {
      label: 'Unbalanced Adjustments',
      description: 'If enabled, unbalanced adjustments are allowed.',
    },
    allotments: {
      label: 'Allotments',
      description: 'If enabled, allotments are allowed.',
    },
    has_total_revenue_enabled: {
      label: 'Total Revenue',
      description: `Total Revenue: If enabled, the total revenue functionality is available.
      <ul class="list-disc list-inside">
          <li>The Total Revenue column is available in Flight Control and Flight Details.</li>
          <li>The Total Revenue value is calculated as a sum of toggled on Revenue Components: Ancillaries Revenue, Tax Revenue, Surcharge Revenue.</li>
      </ul>
`,
    },
    use_currency_exchange_rates: {
      label: 'Use currency exchange rates',
      description: `If enabled, monetary values will be converted to the base currency.
       Otherwise, monetary values will be passed through unchanged.
       <br>
       <br>
       This setting is currently only applicable for the revenue importer.`,
    },
    include_ancillary_in_total_revenue: {
      label: 'Include Ancillary in Total Revenue',
      description: `Ancillaries Revenue: If enabled, the ancillaries revenue functionality is available.
      <ul class="list-disc list-inside">
        <li>The Ancillaries Revenue tab is available in Revenue Components Management.
        <li>The Ancillaries Revenue value is added to the calculation of the Total Revenue.</li>
        <li>The Ancillaries Revenue column is available in Flight Control and Flight Details.</li>
        <li>The Share of Ancillaries Revenue control is available in Flight Details.</li>
        <li>The Ancillaries Revenue breakdown is available in and Flight Details.</li>
        <li>The Average Ancillaries revenue per passenger column is available in Flight Control and Flight Details.</li>
      </ul>
      `,
    },
    include_surcharge_in_total_revenue: {
      label: 'Include Surcharge in Total Revenue',
      description: `Surcharge Revenue: If enabled, the surcharge revenue functionality is available.
      <ul class="list-disc list-inside">
        <li>The Surcharges column is available in Revenue Components Management.</li>
        <li>The Surcharge Revenue value is added to the calculation of the Total Revenue.</li>
        <li>The Surcharge Revenue column is available in Flight Control and Flight Details.</li>
      </ul>
      `,
    },
    include_tax_in_total_revenue: {
      label: 'Include Tax in Total Revenue',
      description: `Tax Revenue: If enabled, the tax revenue functionality is available.
      <ul class="list-disc list-inside">
        <li>The Surcharges column is available in Revenue Components Management.</li>
        <li>The Surcharge Revenue value is added to the calculation of the Total Revenue.</li>
        <li>The Surcharge Revenue column is available in Flight Control and Flight Details. </li>
      </ul>
      `,
    },
    overbooking_risk_values: {
      0: '0%',
      1: '1%',
      3: '3%',
      5: '5%',
      10: '10%',
      15: '15%',
      20: '20%',
      25: '25%',
      30: '30%',
      35: '35%',
      40: '40%',
      45: '45%',
      50: '50%',
      55: '55%',
      60: '60%',
      65: '65%',
      70: '70%',
      75: '75%',
      80: '80%',
      85: '85%',
      90: '90%',
      95: '95%',
      97: '97%',
      99: '99%',
    },
  },
};
