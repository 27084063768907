import { cloneDeep, isEmpty, isFinite, isNil } from 'lodash-es';
import moment from 'moment';
import { defineStore, storeToRefs } from 'pinia';
import { computed, readonly, ref } from 'vue';

import { CabinCode, CabinStructure } from '@/modules/api/application/application-contracts';
import {
  FlightFilterModel,
  FlightLineCabin,
  FlightLineCabinClass,
  FlightLineModel,
  PostDepartureCabin,
  SourceFlight,
} from '@/modules/api/flight/flight-contracts';
import { ondsService as OndsService } from '@/modules/api/flight/onds-service';
import { HTTPStatusCode } from '@/modules/api/status-codes';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { useOverbookingStore } from '@/modules/details/stores/overbooking.store';
import { AddOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/add-optimization-profile-level-action';
import { AddShadowOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/add-shadow-optimization-profile-level-action';
import { ApplyRecommendedAUAction } from '@/modules/flight-actions/actions/cabin-actions/apply-recommended-au-action';
import { CorrectAuStructureAction } from '@/modules/flight-actions/actions/cabin-actions/correct-au-structure-action';
import {
  SetRecommendedOverbookingRiskPercentageAction,
  SetRecommendedOverbookingRiskPercentagePayload,
} from '@/modules/flight-actions/actions/cabin-actions/set-recommended-overbooking-risk-percentage-action';
import { SwapOptimizationTacticAction } from '@/modules/flight-actions/actions/cabin-actions/swap-optimization-tactic-action';
import { UpdateAutopilotAction } from '@/modules/flight-actions/actions/cabin-actions/update-autopilot-action';
import { UpdateOverbookingAutopilotAction } from '@/modules/flight-actions/actions/cabin-actions/update-overbooking-autopilot-action';
import { AddTagsAction } from '@/modules/flight-actions/actions/definitions/add-tags-action';
import { RemoveTagsAction } from '@/modules/flight-actions/actions/definitions/remove-tags-action';
import { ClassValue, DistributedClasses, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { useFlightActionsStore } from '@/modules/flight-actions/store/flight-actions.store';
import { RouteClusterAssignments } from '@/modules/route-management/api/route-cluster.contracts';
import { routeClusterService } from '@/modules/route-management/api/route-cluster.service';
import { useMarketInfoStore } from '@/modules/route-management/store/market-info.store';
import { MessageService } from '@/modules/shared';
import { useSystemStore } from '@/modules/system-settings/store/system.store';
import { ITag } from '@/modules/tags';
import { i18n } from '@/plugins/i18n';
import { DateTimeService } from '@/services/date-time.service';
import { FlightService } from '@/services/flight.service';
import { useAppSettingsStore } from '@/store/modules/app-settings.store';
import { ControlModule } from '@/store/modules/control.module';

const { t } = i18n.global;

//
export const useFlightStore = defineStore('flight', () => {
  const isLoading = ref(false);
  const isSaving = ref(false);
  const isRecalculating = ref(false);
  const isNewClusterLevelAssigned = ref(false);
  const flight = ref<FlightLineModel[] | null>(null);
  const filter = ref<FlightFilterModel | null>(null);
  const isAuditSidebarOpen = ref(false);
  const selectedFlightLineCode = ref('');
  const sourceFlight = ref<SourceFlight | null | undefined>(null);
  const carrierCode = ref<string | null>(null);
  const hasValidAuStructure = ref(true);
  const selectedFlightLine = ref<FlightLineModel | null>(null);
  const flightActions = ref<Map<number, FlightAction<any>[]>>(new Map());
  const isFlightDeparted = ref(false);
  /**
   * @private
   */
  const updatedFlight = ref<FlightLineModel[] | null>(null);

  const customerSettingsStore = useCustomerSettingsStore();
  const { settings } = storeToRefs(customerSettingsStore);

  const systemStore = useSystemStore();

  const appSettingsStore = useAppSettingsStore();

  const shouldDisplayContinuousPricingWarning = computed(() => {
    const hasOnlyDiscreteClasses = !!selectedFlightLine.value?.cabins?.some(
      (cabin) => cabin.classes.filter((cls) => !cls.discrete).length < 2,
    );

    return !!settings.value?.hasContinuousPricingEnabled && hasOnlyDiscreteClasses;
  });

  function clearFlight(): void {
    setFlight(null);
    clearFlightActions();
    carrierCode.value = null;
  }

  async function getFlight(filter: FlightFilterModel): Promise<void> {
    clearFlight();
    carrierCode.value = filter.carrierCode;

    try {
      isLoading.value = true;
      const customerSettingsStore = useCustomerSettingsStore();
      const gold = !!customerSettingsStore.settings?.useGoldModel;
      const flight: FlightLineModel[] = await OndsService.get(filter, gold);
      if (!isEmpty(flight)) {
        checkAuStructure({
          flightLine: flight[0],
          cabins: appSettingsStore.inventoryConfigurationProperties.cabins,
        });

        const marketInfoStore = useMarketInfoStore();

        const flightLineRoutes = cloneDeep(
          marketInfoStore.routes.filter((route) => flight.map((fl) => fl.flightPath).includes(route.flightPath)),
        );

        const routeClusterAssignmentPromises = flightLineRoutes.map((route) => routeClusterService.get(route.id));

        let routeClusterAssignments: RouteClusterAssignments[] = await Promise.all(routeClusterAssignmentPromises);

        // remove empty responses
        routeClusterAssignments = routeClusterAssignments.filter((routeClusterAssignment) => isFinite(routeClusterAssignment.routeId));

        // Apply cluster assignments to the flight lines
        for (const clusterAssigment of routeClusterAssignments) {
          // Find the route for the cluster assignment
          const matchedRoute = flightLineRoutes.find((r) => r.id === clusterAssigment.routeId);

          // Add the flightpath to the cluster assignment
          if (!isNil(matchedRoute)) {
            clusterAssigment.flightPath = matchedRoute.flightPath;
          }

          // Get the flightline that matches the route cluster assignment
          const matchingFlightLine = flight.find((fl) => fl.flightPath === clusterAssigment.flightPath);

          // For each cabin in the flightline, set the cluster options
          if (!isNil(matchingFlightLine)) {
            for (const cabin of clusterAssigment.cabins) {
              const matchingCabin = FlightService.getMatchedCabin(matchingFlightLine, cabin.cabinCode);

              if (!isNil(matchingCabin)) {
                matchingCabin.clusterOptions = cabin.clusters;
              }
            }
          }
        }

        setFlight(flight);
      }
    } finally {
      isLoading.value = false;
    }
  }

  async function getDepartedFlight(filter: FlightFilterModel): Promise<void> {
    clearFlight();
    carrierCode.value = filter.carrierCode;

    try {
      isLoading.value = true;
      const response = await OndsService.getDeparted(filter);

      if (!isEmpty(response)) {
        const sortedResponse = await Promise.all(response).then((resolvedOnds: FlightLineModel[]) =>
          resolvedOnds.map((fl: FlightLineModel) => {
            if (fl.postDeparture && fl.postDeparture.cabins?.length > 0) {
              fl.postDeparture.cabins.sort(
                (a: PostDepartureCabin, b: PostDepartureCabin) =>
                  (appSettingsStore.inventoryConfigurationProperties?.cabins?.find(({ code }) => a.code === code)?.order ?? 0) -
                  (appSettingsStore.inventoryConfigurationProperties?.cabins?.find(({ code }) => b.code === code)?.order ?? 0),
              );
              fl.postDeparture.cabins.forEach((cabin: PostDepartureCabin) => {
                cabin.classes.sort(
                  (a: FlightLineCabinClass, b: FlightLineCabinClass) =>
                    (appSettingsStore.inventoryConfigurationProperties?.cabins
                      ?.find(({ code }) => cabin.code === code)
                      ?.classes.find(({ code }) => a.code === code)?.order ?? 0) -
                    (appSettingsStore?.inventoryConfigurationProperties?.cabins
                      ?.find(({ code }) => cabin?.code === code)
                      ?.classes?.find(({ code }) => b?.code === code)?.order ?? 0),
                );
              });
            }
            return fl;
          }),
        );
        setFlight(sortedResponse);
      }
    } finally {
      isLoading.value = false;
    }
  }

  function checkAuStructure(payload: { flightLine: FlightLineModel | null; cabins: CabinStructure[] }): void {
    const flightLine = payload.flightLine;
    const cabins = payload.cabins;

    if (!flightLine?.cabins || !cabins) {
      return;
    }

    const validAuStructure = flightLine.cabins.every((cabin) => {
      const inventoryCabin = cabins.find((inventoryCabin) => inventoryCabin.code === cabin.code);
      if (isNil(inventoryCabin)) {
        return true;
      }
      // Filter out if class has excludeInLAF set to true
      const ondClasses = cabin.classes.filter((ondClass) =>
        inventoryCabin.classes.find((inventoryClass) => inventoryClass.code === ondClass.code && !inventoryClass.excludeInLAF),
      );

      return ondClasses.every((ondClass, index) => {
        const au: number = (ondClass.isAuthorizationUnitsUpdated ? ondClass.updatedAuthorizationUnits : ondClass.authorizationUnits) ?? 0;
        const parentClass = cabin.classes[index - 1];
        const parentAU: number =
          (parentClass
            ? parentClass.isAuthorizationUnitsUpdated
              ? parentClass.updatedAuthorizationUnits
              : parentClass.authorizationUnits
            : au) ?? 0;

        return parentAU >= au;
      });
    });

    hasValidAuStructure.value = validAuStructure;
  }

  async function checkFlightAuStructure(cabinCode: CabinCode): Promise<void> {
    const action = new CorrectAuStructureAction(cabinCode);
    addAction({
      action,
      flightLineId: selectedFlightLine.value.id,
    });

    await saveFlight({
      preview: true,
      updateWorkingFlight: true,
    });
  }

  function selectFlightLine(flightLineCode: string): void {
    setSelectedFlightLine(flightLineCode);
    /**
     * Always set the sourceFlight, even if it is undefined.
     * Setting the sourceFlight to undefined will clear the state
     */
    sourceFlight.value = selectedFlightLine.value?.sourceFlight;
  }

  async function applyFlightActions(payload: { preview: boolean }): Promise<FlightLineModel[]> {
    const flightActionsStore = useFlightActionsStore();
    const actions: Promise<FlightLineModel>[] = [];

    flightActions.value.forEach((value, key) => {
      actions.push(
        flightActionsStore.applyOne({
          actions: value,
          flightLine: flight.value?.find((flightLine) => flightLine.id === key),
          preview: payload.preview,
          // False because we update below when all requests are done, considering multi-legged flights
          updateWorkingFlight: false,
        }),
      );
    });

    const results = await Promise.all(actions);
    results.forEach((result: FlightLineModel) => {
      updateWorkingFlight(result);
    });

    return results;
  }

  async function saveFlight(payload: { preview: boolean; updateWorkingFlight: boolean }): Promise<FlightLineModel[]> {
    const flightActionsStore = useFlightActionsStore();
    isSaving.value = true;
    isRecalculating.value = true;

    const actions: Promise<FlightLineModel>[] = [];
    flightActions.value.forEach((value, key) => {
      actions.push(
        flightActionsStore.applyOne({
          actions: value,
          flightLine: flight.value?.find((flightLine) => flightLine.id === key),
          preview: payload.preview,
          // False because we update below when all requests are done, considering multi-legged flights
          updateWorkingFlight: payload.updateWorkingFlight,
        }),
      );
    });

    return Promise.all(actions)
      .then((results) => {
        let updatedFlight: FlightLineModel;

        results.forEach((result: any) => {
          if (result) {
            if (result && (result as FlightLineModel).cabins) {
              updatedFlight = result;
              const payload = {
                date: result.userDateModified,
                flightId: result.id,
              };

              dateModifiedUpdated(payload);
              updateWorkingFlight(updatedFlight);
            }
          }
        });

        ControlModule.updateFlightReviewUpdated(true);
        if (!payload.preview) {
          MessageService.success();
        }

        return results;
      })
      .catch((error: any) => {
        ControlModule.updateFlightReviewUpdated(false);

        if (error.response && error.response.status === HTTPStatusCode.Forbidden) {
          MessageService.error(t('flight_update_not_allowed'));
        } else {
          MessageService.generalError();
        }

        return [];
      })
      .finally(() => {
        resetModified();
        isSaving.value = false;
        isRecalculating.value = false;
      });
  }

  function setFilter(payload: FlightFilterModel): void {
    filter.value = payload;
  }

  async function addTag(payload: { flightLineId: number; selectedTag: ITag }): Promise<void> {
    if (flight.value) {
      const flightLineToAdjust = flight.value?.find((fl) => fl.id === payload.flightLineId);

      if (flightLineToAdjust) {
        flightLineToAdjust.isModified = true;

        const flightActionGroups = flightActions.value
          ?.get(selectedFlightLine.value?.id)
          ?.filter((action: FlightAction<any>) => action.actionType === FlightActionType.tagsAdd) as unknown as AddTagsAction[];

        const action = new AddTagsAction(
          flightActionGroups.length > 0 ? [...flightActionGroups[0].value, payload.selectedTag] : [payload.selectedTag],
        );
        addAction({
          action,
          flightLineId: selectedFlightLine.value.id,
          joinAction: true,
        });
        selectedFlightLine.value?.tags?.push(payload.selectedTag);
      }
    }
  }

  async function removeTag(payload: { flightLineId: number; index: number }): Promise<void> {
    if (flight.value) {
      const flightLineToAdjust = flight.value?.find((fl) => fl.id === payload.flightLineId);
      if (flightLineToAdjust) {
        flightLineToAdjust.isModified = true;

        const action = new RemoveTagsAction({ tags: flightLineToAdjust.tags?.splice(payload.index, 1) ?? [], removeAll: false });
        addAction({
          action,
          flightLineId: selectedFlightLine.value.id,
          joinAction: true,
        });
      }
    }
  }

  function addAutopilotAction(payload: { action: UpdateAutopilotAction; flightLineId: number }): void {
    const index =
      flightActions.value
        ?.get(payload.flightLineId)
        ?.findIndex(
          (action) =>
            action.actionType === FlightActionType.addOptimisationProfileLevel &&
            (action as UpdateAutopilotAction).cabinCode === payload.action.cabinCode,
        ) ?? -1;

    if (index > -1) {
      const action = flightActions.value?.get(payload.flightLineId)?.splice(index, 1)[0];
      (action as AddOptimizationProfileLevelAction).applyRecommendedAUs = payload.action.getPayload().value;

      const actions = [...flightActions.value.get(payload.flightLineId)];
      actions[index] = action;

      flightActions.value.set(payload.flightLineId, actions);
    } else if (index === -1 && payload.action.value) {
      const optimisationProfileLevel = selectedFlightLine.value?.cabins?.find(
        (cabin) => cabin.code === payload.action.cabinCode,
      )?.optimisationProfileLevel;
      if (optimisationProfileLevel) {
        const optimisationProfileAction = new AddOptimizationProfileLevelAction(
          payload.action.cabinCode,
          payload.action.getPayload().value,
          selectedFlightLine.value?.cabins?.find((cabin) => cabin.code === payload.action.cabinCode)?.optimisationProfileLevel?.id,
        );

        addAction({
          action: optimisationProfileAction,
          flightLineId: payload.flightLineId,
        });
      }
    }

    addAction(payload);
  }

  function addOverbookingAutopilotAction(payload: { action: UpdateOverbookingAutopilotAction; flightLineId: number }): void {
    // If the overbooking autopilot action is set to 'on', we should apply the rOVB to the OVB
    if (payload.action.value) {
      const recommendedOverbookingRiskPercentageAction = new SetRecommendedOverbookingRiskPercentageAction(
        payload.action.cabinCode,
        payload.action.legNumber,
      );

      const overbookingStore = useOverbookingStore();
      const overbookingRisk = overbookingStore.overbookingRisk;

      // Only apply recommendedOverbookingFactor if it has been set by the user
      if (overbookingStore.selectedLegCabin?.recommendedOverbookingFactor && typeof overbookingRisk === 'number') {
        const cabin = selectedFlightLine.value?.cabins?.find((cabin) => cabin.code === overbookingStore.selectedLegCabin?.cabinCode);
        const recommendedOverbookingRiskPayload: SetRecommendedOverbookingRiskPercentagePayload = {
          actionType: recommendedOverbookingRiskPercentageAction.actionType,
          value: overbookingRisk,
          cabinCode: overbookingStore.selectedLegCabin?.cabinCode,
          applyRecommendedAU: cabin?.autopilot as boolean,
          applyRecommendedOverbookingFactor: true,
          recommendedOverbookingFactor: overbookingStore.selectedLegCabin?.recommendedOverbookingFactor,
          legNumber: overbookingStore.selectedLegCabin?.legId,
        };

        recommendedOverbookingRiskPercentageAction.setPayload(recommendedOverbookingRiskPayload);

        addMultiLegAction({
          action: recommendedOverbookingRiskPercentageAction,
        });
      }

      const selectedCabin: FlightLineCabin | undefined = selectedFlightLine.value?.cabins?.find(
        (selectedCabin: FlightLineCabin) => selectedCabin.code === payload.action.cabinCode,
      );
      // And if the selectedFlightLine has autopilot for OP levels, we should also apply the rAUs
      if (selectedCabin?.autopilot) {
        const applyRecommendedAUAction = new ApplyRecommendedAUAction(payload.action.cabinCode);
        addAction({
          action: applyRecommendedAUAction,
          flightLineId: payload.flightLineId,
        });
      }
    }
    // And finally apply the overbooking autopilot action
    addMultiLegAction(payload);
  }

  function addSwapOptimisationProfileAction(payload: { cabinCode: CabinCode; flightLineId: number; autopilot: boolean }): void {
    const localActions: FlightAction<any>[] | undefined = flightActions.value.get(payload.flightLineId);
    if (localActions) {
      // When swapping and the last action was also swapping, we can just remove the last swap action
      if (localActions[localActions.length - 1]?.actionType === FlightActionType.swapOptimisationTactic) {
        removeAction({
          actionType: FlightActionType.swapOptimisationTactic,
          flightLineId: payload.flightLineId,
          definedIndex: localActions.length - 1,
        });
      } else {
        const action = new SwapOptimizationTacticAction(payload.cabinCode, payload.autopilot);
        addAction({ action, flightLineId: payload.flightLineId, joinAction: true });
      }
    }
  }

  function addRemoveOptimizationTacticAction(payload: {
    action: AddOptimizationProfileLevelAction | AddShadowOptimizationProfileLevelAction;
    flightLineId: number;
    shadow: boolean;
  }): void {
    // When we add a tactic action, usually we can remove the old add action or the remove tactic action.
    // One caveat is when a swap action is within the actions, then we want to keep the add or remove actions that happened before the last swap as the swaps need previous data to
    // keep the correct values
    // Example:
    // The original value for the active tactic is 4 and the shadow tactic is 8.
    // We set the active tactic to 2
    // We swap the tactics, so we'll get active: 8 and shadow: 2
    // Then we set the active to 1 > We need to keep the first set active action, otherwise the swap action will swap the wrong (original) value and then the shadow tactic will
    // have a wrong value

    const localActions: FlightAction<any>[] | undefined = flightActions.value.get(payload.flightLineId);
    if (localActions) {
      const lastSwapActionIndex: number = localActions
        .map((action) => action.actionType)
        .lastIndexOf(FlightActionType.swapOptimisationTactic);

      if (lastSwapActionIndex !== -1) {
        const isOfActiveOptimisationActionType: FlightActionType =
          FlightActionType.addOptimisationProfileLevel || FlightActionType.removeOptimisationProfileLevel;
        const isOfShadowOptimisationActionType: FlightActionType =
          FlightActionType.addShadowOptimisationProfileLevel || FlightActionType.removeShadowOptimisationProfileLevel;

        const isOfCorrectOptimisationActionType: FlightActionType = payload.shadow
          ? isOfShadowOptimisationActionType
          : isOfActiveOptimisationActionType;
        localActions
          .slice(lastSwapActionIndex, localActions.length)
          .flatMap((action: FlightAction<any>, index) => {
            if (action.actionType === isOfCorrectOptimisationActionType) {
              return [{ actionType: action.actionType, index }];
            } else {
              return [];
            }
          })
          .forEach(({ actionType, index }) => {
            removeAction({
              actionType,
              flightLineId: payload.flightLineId,
              definedIndex: lastSwapActionIndex + index,
            });
          });
      }
      addAction({ ...payload, joinAction: true });
    }
  }

  function addInventoryClassAction(payload: { action: FlightAction<any>; classCode: string }): void {
    const selectedFlightLineId = selectedFlightLine.value?.id ?? -9999;

    const index =
      flightActions.value.get(selectedFlightLineId)?.findIndex(
        (action) =>
          action.actionType === payload.action.actionType &&
          action.cabinCode === payload.action.cabinCode &&
          // We make 1 request per class, so we can grab the first value
          (action.value as DistributedClasses)?.classes?.[0].code === payload.classCode,
      ) ?? -1;

    if (index > -1) {
      flightActions.value.get(selectedFlightLineId)?.splice(index, 1);
    }

    flightActions.value.get(selectedFlightLineId)?.push(payload.action);
  }

  function addClassAction(payload: { action: FlightAction<any>; classCode: string }): void {
    const selectedFlightLineId = selectedFlightLine.value?.id ?? -9999;

    const index =
      flightActions.value.get(selectedFlightLineId)?.findIndex(
        (action) =>
          action.actionType === payload.action.actionType &&
          action.cabinCode === payload.action.cabinCode &&
          // We make 1 request per class, so we can grab the first value
          (action.value as ClassValue[])?.[0]?.code === payload.classCode,
      ) ?? -1;

    if (index > -1) {
      flightActions.value.get(selectedFlightLineId)?.splice(index, 1);
    }

    flightActions.value.get(selectedFlightLineId)?.push(payload.action);
  }

  function removeAction(payload: { actionType: FlightActionType; flightLineId: number; definedIndex?: number }): void {
    const localActions = flightActions.value?.get(payload.flightLineId);
    if (localActions) {
      const index = payload.definedIndex || localActions.findIndex((action) => action.actionType === payload.actionType);

      if (index === -1) {
        return;
      }

      localActions.splice(index, 1);
    }
  }

  function clearFlightActions(): void {
    flightActions.value = new Map();
  }

  function setCarrierCode(payload?: string): void {
    carrierCode.value = payload ? payload : null;
  }

  function updateWorkingFlight(payload: FlightLineModel): void {
    const flightLine = flight.value?.find((fl) => fl.id === payload.id);
    if (flightLine) {
      const updatedLegs = flightLine.legs?.map((leg) => {
        const updatedLeg = payload.legs?.find((payloadLeg) => payloadLeg.id === leg.id) ?? leg;

        return {
          ...updatedLeg,
          departureDate: typeof updatedLeg.departureDate === 'string' ? moment(updatedLeg.departureDate) : updatedLeg.departureDate,
        };
      });

      flightLine.hasRealTimeBookings = payload.hasRealTimeBookings;
      flightLine.tags = payload.tags;
      flightLine.linkedClassRuleId = payload.linkedClassRuleId;
      flightLine.rivalRuleId = payload.rivalRuleId;

      // Make sure we update the flight line at the right index
      const index = (flight.value ?? [])?.findIndex((fl) => fl.id === flightLine.id);

      if (!isEmpty(flightLine.cabins)) {
        const cabins = flightLine.cabins?.map((cabin: FlightLineCabin) => {
          const payloadCabin = payload.cabins?.find((previewCabin) => previewCabin.code === cabin.code);
          if (payloadCabin) {
            // Some values are not always present when not defined, so update these explicitly
            cabin = {
              ...cabin,
              ...payloadCabin,
              optimisationTactics: payloadCabin.optimisationTactics,
              optimisationProfileLevel: payloadCabin.optimisationProfileLevel,
              optimisationProfileLevelId: payloadCabin.optimisationProfileLevelId,
              recommendedLowestAvailableFareClass: payloadCabin.recommendedLowestAvailableFareClass,
              shadowOptimisationTactics: payloadCabin.shadowOptimisationTactics,
              shadowOptimisationProfileLevel: payloadCabin.shadowOptimisationProfileLevel,
              shadowOptimisationProfileLevelId: payloadCabin.shadowOptimisationProfileLevelId,
              shadowRecommendedLowestAvailableFareClass: payloadCabin.shadowRecommendedLowestAvailableFareClass,
            };

            if (!payloadCabin.cluster) {
              delete cabin.cluster;
              delete cabin.performanceLabel;
              delete cabin.performanceScore;
            }
          }
          return cabin;
        });

        flight.value?.splice(index, 1, {
          ...flightLine,
          sourceFlight: payload.sourceFlight,
          cabins,
          legs: updatedLegs,
        });
      } else {
        flight.value?.splice(index, 1, {
          ...flightLine,
          legs: updatedLegs,
        });
      }
    }
  }

  function setFlight(flightPayload: FlightLineModel[] | null): void {
    if (flightPayload === null) {
      flight.value = null;
      updatedFlight.value = null;
      return;
    }

    flight.value = flightPayload;
    if (flight.value?.length) {
      const captureDate = DateTimeService.getDate({
        date: systemStore.config?.captureDate || '',
      });

      const flightDepartureDate = DateTimeService.getDate({
        date: flight.value[0].departureDate,
      });
      isFlightDeparted.value = flightDepartureDate.isBefore(captureDate);
    }
    updatedFlight.value = cloneDeep(flightPayload);

    selectedFlightLine.value =
      flightPayload.find((flightLine: FlightLineModel) => flightLine.flightLine === filter.value?.flightLine) || flightPayload[0];

    flight.value.forEach((flightLine) => {
      flightActions.value.set(flightLine.id, []);
    });
  }

  function addAction(payload: { action: FlightAction<any>; flightLineId: number; joinAction?: boolean }): void {
    const localActions = flightActions.value.get(payload.flightLineId);
    if (localActions) {
      const index = localActions.findIndex(
        (action) => action.actionType === payload.action.actionType && action.cabinCode === payload.action.cabinCode,
      );
      if (index === -1 || payload.joinAction) {
        flightActions.value.set(payload.flightLineId, [...(flightActions.value.get(payload.flightLineId) ?? []), payload.action]);
        return;
      }
      flightActions.value.get(payload.flightLineId)?.splice(index, 1, payload.action);
    }
  }

  function setSelectedFlightLine(flightLineCode: string): void {
    selectedFlightLineCode.value = flightLineCode;
    selectedFlightLine.value = flight.value?.find((fl) => fl.flightLine === selectedFlightLineCode.value) || null;
  }

  function addMultiLegAction(payload: { action: FlightAction<any> }): void {
    flightActions.value.forEach((value, key) => {
      const index = value.findIndex(
        (action) =>
          action.actionType === payload.action.actionType &&
          //@ts-ignore
          action.cabinCode === payload.action.cabinCode &&
          //@ts-ignore
          action.legNumber === payload.action.legNumber,
      );

      if (index === -1) {
        flightActions.value.set(key, [...flightActions.value.get(key), payload.action]);
        return;
      }

      flightActions.value.get(key)?.splice(index, 1, payload.action);
    });
  }

  function $reset(): void {
    isLoading.value = false;
    isSaving.value = false;
    isRecalculating.value = false;
    isNewClusterLevelAssigned.value = false;
    flight.value = null;
    filter.value = null;
    isAuditSidebarOpen.value = false;
    selectedFlightLineCode.value = '';
    sourceFlight.value = null;
    carrierCode.value = null;
    hasValidAuStructure.value = true;
    selectedFlightLine.value = null;
    flightActions.value = new Map();
    isFlightDeparted.value = false;
    updatedFlight.value = null;
  }

  /**
   * @private
   */
  function dateModifiedUpdated(payload: { date: string; flightId: number }): void {
    let modifiedFlightLine = flight.value?.find((fl) => fl.id === payload.flightId);

    if (modifiedFlightLine) {
      modifiedFlightLine = {
        ...modifiedFlightLine,
        userDateModified: payload.date,
      };
    }

    flight.value =
      flight.value?.map((fl) => {
        if (fl.id === modifiedFlightLine?.id) {
          fl = { ...modifiedFlightLine };
        }
        return fl;
      }) ?? [];
  }

  /**
   * @private
   */
  function setFlightLineRowModifiedToFalse(): void {
    flight.value =
      flight.value?.map((flightLineInfo) => {
        flightLineInfo.cabins?.map((cabin) =>
          cabin.classes.map((row) => {
            delete row.isModified;
          }),
        );

        return flightLineInfo;
      }) ?? [];
  }

  /**
   * @private
   */
  function setFlightLineTagModifiedToFalse(): void {
    flight.value =
      flight.value?.map((flightLineInfo) => {
        flightLineInfo.isModified = false;

        return flightLineInfo;
      }) ?? [];
  }

  /**
   * @private
   */
  function resetUpdatedFlight(): void {
    updatedFlight.value = cloneDeep(flight.value);
  }

  /**
   * @private
   */
  function resetModified(): void {
    setFlightLineRowModifiedToFalse();
    setFlightLineTagModifiedToFalse();
    resetUpdatedFlight();
  }

  return {
    isLoading,
    isSaving,
    isRecalculating: readonly(isRecalculating),
    isNewClusterLevelAssigned,
    flight,
    filter,
    isAuditSidebarOpen,
    selectedFlightLineCode,
    sourceFlight,
    carrierCode,
    hasValidAuStructure,
    selectedFlightLine,
    flightActions,
    isFlightDeparted,
    shouldDisplayContinuousPricingWarning,
    clearFlight,
    getFlight,
    getDepartedFlight,
    checkAuStructure,
    checkFlightAuStructure,
    selectFlightLine,
    applyFlightActions,
    setCarrierCode,
    saveFlight,
    setFilter,
    addTag,
    removeTag,
    addAutopilotAction,
    addOverbookingAutopilotAction,
    addSwapOptimisationProfileAction,
    addRemoveOptimizationTacticAction,
    updateWorkingFlight,
    addInventoryClassAction,
    $reset,
    addClassAction,
    removeAction,
    clearFlightActions,
    setSelectedFlightLine,
    setFlight,
    addAction,
    addMultiLegAction,
  };
});
