import { cloneDeep, isEmpty, isEqual, isFinite, isNil } from 'lodash-es';
import { Ref, computed, ref } from 'vue';

import { EddyBaseError } from '@/modules/api/base-client';
import { LinkedClassRuleUnit } from '@/modules/rules/linked-class-rules/api/linked-class-rules.contracts';
import { linkedClassRulesService } from '@/modules/rules/linked-class-rules/api/linked-class-rules.service';
import { LinkedClassRuleDetailModel, LinkedClassRuleModel } from '@/modules/rules/linked-class-rules/models/linked-class-rule.model';
import { MessageService } from '@/modules/shared';
import { ErrorCode } from '@/modules/shared/types/error-codes';
import { i18n } from '@/plugins/i18n';
import { useAppSettingsStore } from '@/store/modules/app-settings.store';

const { t } = i18n.global;

const linkedClassRules = new Map<number, LinkedClassRuleModel>();

interface LinkedClassRulesState {
  isLoadingLinkedClassRule: boolean;
  isLoadingLinkedClassRules: boolean;
  linkedClassRule: LinkedClassRuleModel;
  linkedClassRules: LinkedClassRuleModel[];
  linkedClassRuleUnchanged: LinkedClassRuleModel;
}

const globalLinkedClassRuleState: Ref<LinkedClassRulesState> = ref({
  isLoadingLinkedClassRule: false,
  isLoadingLinkedClassRules: false,
  linkedClassRule: undefined,
  linkedClassRules: [],
  linkedClassRuleUnchanged: undefined,
});

export function useGlobalLinkedClassRule() {
  return linkedClassRuleLoader(globalLinkedClassRuleState);
}

export function useLinkedClassRule() {
  const linkedClassRuleState: Ref<LinkedClassRulesState> = ref({
    isLoadingLinkedClassRule: false,
    isLoadingLinkedClassRules: false,
    linkedClassRule: undefined,
    linkedClassRules: [],
    linkedClassRuleUnchanged: undefined,
  });

  return linkedClassRuleLoader(linkedClassRuleState);
}

/**
 * A loader that helps to manage a Linked Class Rule. It has a local state, so calling this function will create a new state, managed by that instance.
 * @returns composable functions and computed properties related to managing the state of a Linked Class Rule
 */
function linkedClassRuleLoader(linkedClassRuleState: Ref<LinkedClassRulesState>) {
  /**
   * Gets all Linked Class Rules from the API.
   * @returns LinkedClassRuleModel[]
   */
  async function getAll(): Promise<LinkedClassRuleModel[]> {
    try {
      linkedClassRuleState.value.isLoadingLinkedClassRules = true;
      const response = await linkedClassRulesService.getAll();
      linkedClassRuleState.value.linkedClassRules = response;

      return response;
    } catch (e) {
      MessageService.failedRequest();
    } finally {
      linkedClassRuleState.value.isLoadingLinkedClassRules = false;
    }
  }

  function updateLinkedClassRuleState(rule: LinkedClassRuleModel) {
    linkedClassRuleState.value.linkedClassRule = rule;
    linkedClassRuleState.value.linkedClassRuleUnchanged = cloneDeep(rule);
  }

  /**
   * Gets a specific Linked Class Rule from the API.
   * @param id The ID of the Linked Class Rule in the database
   * @returns The Linked Class Rule Model
   */
  async function loadLinkedClassRule(id: number, forceNetwork = false): Promise<LinkedClassRuleModel> {
    if (!forceNetwork && linkedClassRules.has(id)) {
      const rule = linkedClassRules.get(id);

      updateLinkedClassRuleState(rule);
      linkedClassRules.set(id, rule);

      return rule;
    } else {
      try {
        const rule = await linkedClassRulesService.getById(id);

        updateLinkedClassRuleState(rule);

        return rule;
      } catch (e) {
        MessageService.failedRequest();
      }
    }
  }

  /**
   * Revert the changes made by loading a fresh version of the Rule from the API.
   */
  async function revertChanges() {
    if (!isNil(linkedClassRuleState.value.linkedClassRule)) {
      await loadLinkedClassRule(linkedClassRuleState.value.linkedClassRule.id, true);
    }
  }

  /** Checks if the model has been changed, and returns true if that's the case  */
  function hasChanges(): boolean {
    return !isEqual(linkedClassRuleState.value.linkedClassRule, linkedClassRuleState.value.linkedClassRuleUnchanged);
  }

  function removeRuleByIndex(index: number) {
    linkedClassRuleState.value.linkedClassRule.details.splice(index, 1);
  }

  /**
   * Creates a new, empty Linked Class Rule Model.
   */
  function loadNewLinkedClassRule() {
    linkedClassRuleState.value.linkedClassRule = LinkedClassRuleModel.new();
    linkedClassRuleState.value.linkedClassRuleUnchanged = undefined;
  }

  /**
   * Adds a new rule that links two classes to eachother, to the model.
   */
  function addNewLinkedClassRule() {
    const appSettingsStore = useAppSettingsStore();

    // Get the first class that is not linked yet
    const availableClasses = appSettingsStore.classesWithOutTopClasses;
    let firstUnlinkedClass = availableClasses[0].code;

    // If there are already classes linked, skip those
    if (!isEmpty(linkedClassRuleState.value.linkedClassRule.details)) {
      for (const availableClass of availableClasses) {
        if (!linkedClassRuleState.value.linkedClassRule.details.some((linkedClass) => linkedClass.classCode === availableClass.code)) {
          firstUnlinkedClass = availableClass.code;
          break;
        }
      }
    }

    linkedClassRuleState.value.linkedClassRule.details.push(
      new LinkedClassRuleDetailModel({
        ruleId: 0,
        rank: linkedClassRuleState.value.linkedClassRule.details.length + 1,
        classCode: firstUnlinkedClass,
        linkingClassCode: appSettingsStore.classes[0].code,
        unit: LinkedClassRuleUnit.PERCENT_OF_AU,
        value: 0,
      }),
    );
  }

  async function copyLinkedClassRule(linkedClassRuleToBeCopied: LinkedClassRuleModel, name: string): Promise<LinkedClassRuleModel> {
    linkedClassRuleState.value.linkedClassRule = cloneDeep(linkedClassRuleToBeCopied);

    // Delete id, so we create a new linked class rule (POST)
    delete linkedClassRuleState.value.linkedClassRule.id;
    linkedClassRuleState.value.linkedClassRule.name = name;

    return await saveLinkedClassRule();
  }

  async function saveLinkedClassRule(): Promise<LinkedClassRuleModel> {
    try {
      linkedClassRuleState.value.isLoadingLinkedClassRule = true;

      if (!linkedClassRuleState.value.linkedClassRule.name) {
        MessageService.error(t('form.validate_name'));
        return;
      }

      let response;

      // Before we save, recalculate the ranks to make sure they are sequential and start from 1.
      linkedClassRuleState.value.linkedClassRule.recalculateRanks();

      if (isFinite(linkedClassRuleState.value.linkedClassRule.id)) {
        response = await linkedClassRulesService.update(linkedClassRuleState.value.linkedClassRule.toDto());
      } else {
        response = await linkedClassRulesService.create(linkedClassRuleState.value.linkedClassRule.toDto());
      }

      if (!response) {
        throw new Error();
      }

      linkedClassRuleState.value.linkedClassRule = response;
      linkedClassRuleState.value.linkedClassRuleUnchanged = cloneDeep(response);
      MessageService.savedSuccessfully(linkedClassRuleState.value.linkedClassRule.name);

      return response;
    } catch (error) {
      const err = error as EddyBaseError;

      if (err.response.data.errors.find((error) => error.errorCode === ErrorCode.UniqueName)) {
        MessageService.error(t('linked_class_rules.unique_linked_class_rule_name_needed'));
      } else if (err.response.data.errors.find((error) => error.errorCode === ErrorCode.EmptyList)) {
        MessageService.error(t('linked_class_rules.linked_class_rule_empty_list'));
      } else {
        MessageService.failedRequest();
      }
    } finally {
      linkedClassRuleState.value.isLoadingLinkedClassRule = false;
    }
  }

  return {
    isLoadingLinkedClassRule: computed(() => linkedClassRuleState.value.isLoadingLinkedClassRule),
    isLoadingLinkedClassRules: computed(() => linkedClassRuleState.value.isLoadingLinkedClassRules),
    linkedClassRule: computed(() => linkedClassRuleState.value.linkedClassRule),
    linkedClassRules: computed(() => linkedClassRuleState.value.linkedClassRules),
    isNew: computed(
      () =>
        !(
          linkedClassRuleState.value.linkedClassRule &&
          isFinite(linkedClassRuleState.value.linkedClassRule.id) &&
          linkedClassRuleState.value.linkedClassRule.id > 0
        ),
    ),
    getAll,
    loadLinkedClassRule,
    revertChanges,
    saveLinkedClassRule,
    copyLinkedClassRule,
    hasChanges,
    removeRuleByIndex,
    addNewLinkedClassRule,
    loadNewLinkedClassRule,
  };
}
