import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import {
  AirlineProperties,
  ApplicationSettingsModel,
  CabinName,
  CabinStructure,
  ClassStructure,
  DiscreteType,
  FlowFxProperties,
  InventoryConfigurationProperties,
  PickupLimits,
  PssName,
} from '@/modules/api/application/application-contracts';
import { applicationService } from '@/modules/api/application/application-service';

export const useAppSettingsStore = defineStore('appSettings', () => {
  const inventoryConfigurationProperties: Ref<InventoryConfigurationProperties> = ref({
    cabins: [],
  } as unknown as InventoryConfigurationProperties);
  const multipleClassConfiguration: Ref<boolean> = ref(false);
  const baseCurrency: Ref<string> = ref('');
  const airlineProperties: Ref<AirlineProperties> = ref({});
  const flowFxProperties: Ref<FlowFxProperties> = ref({ performanceBandPickupConditionDayList: [] });
  const pickupLimits: Ref<PickupLimits> = ref({});

  const pss: ComputedRef<PssName | undefined> = computed(() => inventoryConfigurationProperties.value?.pss);

  const cabinClassesWithOutTopClasses = computed(() =>
    inventoryConfigurationProperties.value?.cabins?.map((cabin) => ({
      ...cabin,
      classes: getClassesWithoutTopClass({
        cabinName: cabin.name,
        classes: cabin.classes,
      }),
    })),
  );
  const classesWithOutTopClasses = computed(() =>
    inventoryConfigurationProperties.value?.cabins?.flatMap((cabin) =>
      getClassesWithoutTopClass({
        cabinName: cabin.name,
        classes: cabin.classes,
      }),
    ),
  );
  const classes: ComputedRef<ClassStructure[]> = computed(
    () => inventoryConfigurationProperties.value?.cabins?.flatMap((cabin) => cabin.classes) ?? [],
  );
  const economyCabin: ComputedRef<CabinStructure | undefined> = computed(() =>
    inventoryConfigurationProperties.value?.cabins?.find((cabin) => cabin.name === CabinName.Economy),
  );
  const businessCabin: ComputedRef<CabinStructure | undefined> = computed(() =>
    inventoryConfigurationProperties.value?.cabins?.find((cabin) => cabin.name === CabinName.Business),
  );
  const premiumEconomyCabin = computed(() =>
    inventoryConfigurationProperties.value?.cabins?.find((cabin) => cabin.name === CabinName.PremiumEconomy),
  );
  const topClassEconomyCabin: ComputedRef<ClassStructure | undefined> = computed(() => economyCabin.value?.classes[0]);
  const topClassBusinessCabin: ComputedRef<ClassStructure | undefined> = computed(() => businessCabin.value?.classes[0]);
  const topClassPremiumEconomyCabin: ComputedRef<ClassStructure | undefined> = computed(() => premiumEconomyCabin.value?.classes[0]);
  const timezoneId: ComputedRef<string | undefined> = computed(() => airlineProperties.value?.timezoneId);

  const flowFxPerformanceBandPickUpDays: ComputedRef<number[]> = computed(
    () => flowFxProperties.value.performanceBandPickupConditionDayList ?? [],
  );

  function getClassesWithoutTopClass(payload: { classes: ClassStructure[]; cabinName: CabinName }): ClassStructure[] {
    return payload.classes.filter(
      (clazz) =>
        (payload.cabinName === CabinName.Economy && clazz.code !== topClassEconomyCabin.value?.code) ||
        (payload.cabinName === CabinName.Business && clazz.code !== topClassBusinessCabin.value?.code) ||
        (payload.cabinName === CabinName.PremiumEconomy && clazz.code !== topClassPremiumEconomyCabin.value?.code),
    );
  }

  function getClassesByDiscreteType(payload: { discreteType: DiscreteType; selectedCabinCode: string }): ClassStructure[] {
    const configuredCabin = inventoryConfigurationProperties.value?.cabins?.find((cabin) => cabin.code === payload.selectedCabinCode);

    if (!configuredCabin) {
      return [];
    }

    return configuredCabin.classes.filter(
      (configuredClass) => configuredClass.discrete && configuredClass.discreteType === DiscreteType.promotion,
    );
  }

  function getCabinCodeForClass(classCode: string): string | undefined {
    return inventoryConfigurationProperties.value?.cabins?.find((cabin) =>
      cabin?.classes?.some((cabinClass) => cabinClass.code === classCode),
    )?.code;
  }

  async function getApplicationSettings(): Promise<void> {
    const response = await applicationService.getSettings();
    setApplicationSettings(response);
  }

  function setApplicationSettings(payload: ApplicationSettingsModel): void {
    inventoryConfigurationProperties.value = payload.inventoryConfigurationProperties;
    baseCurrency.value = payload.baseCurrency.code;
    airlineProperties.value = payload.airlineProperties;
    flowFxProperties.value = payload.flowFxProperties;
    pickupLimits.value = payload.pickupLimits;
    multipleClassConfiguration.value = payload.multipleClassConfiguration;
  }

  function $reset(): void {
    inventoryConfigurationProperties.value = {
      cabins: [],
    } as unknown as InventoryConfigurationProperties;
    baseCurrency.value = '';
    airlineProperties.value = {};
    flowFxProperties.value = { performanceBandPickupConditionDayList: [] };
    pickupLimits.value = {};
  }

  return {
    getClassesWithoutTopClass,
    getClassesByDiscreteType,
    getCabinCodeForClass,
    getApplicationSettings,
    setApplicationSettings,
    $reset,
    pss,
    inventoryConfigurationProperties,
    multipleClassConfiguration,
    airlineProperties,
    baseCurrency,
    pickupLimits,
    cabinClassesWithOutTopClasses,
    classesWithOutTopClasses,
    classes,
    economyCabin,
    businessCabin,
    premiumEconomyCabin,
    topClassEconomyCabin,
    topClassBusinessCabin,
    topClassPremiumEconomyCabin,
    timezoneId,
    flowFxPerformanceBandPickUpDays,
  };
});
