import { AnalyticsUser, IAnalytics, IErrorLogger } from '@/modules/monitoring/types/logger.types';

export class ConsoleLogService implements IErrorLogger, IAnalytics {
  public async error(exception: Error, context: object): Promise<void> {
    // eslint-disable-next-line no-console
    console.error(exception, context);
  }

  public async trackEvent(event: string, properties?: object | undefined): Promise<void> {
    // eslint-disable-next-line no-console
    console.info(event, properties);
  }

  public async setUser(user: AnalyticsUser): Promise<void> {
    // eslint-disable-next-line no-console
    console.info(user);
  }

  public async clearUser(): Promise<void> {
    // eslint-disable-next-line no-console
    console.info('User cleared');
  }
}
