import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { RouteName } from '@/modules/shared';
import { i18n } from '@/plugins/i18n';

const ancillaryActivated = (): boolean => {
  const customerSettingsStore = useCustomerSettingsStore();
  return customerSettingsStore.settings?.includeAncillaryInTotalRevenue ?? false;
};
const surchargeActivated = (): boolean => {
  const customerSettingsStore = useCustomerSettingsStore();
  return customerSettingsStore.settings?.includeSurchargeInTotalRevenue ?? false;
};
const taxesActivated = (): boolean => {
  const customerSettingsStore = useCustomerSettingsStore();
  return customerSettingsStore.settings?.includeTaxInTotalRevenue ?? false;
};

function codeManagementActivated(): boolean {
  const customerSettingsStore = useCustomerSettingsStore();
  return [
    customerSettingsStore.settings?.includeAncillaryInTotalRevenue,
    customerSettingsStore.settings?.includeSurchargeInTotalRevenue,
    customerSettingsStore.settings?.includeTaxInTotalRevenue,
  ].some((setting) => !!setting);
}

const { t } = i18n.global;

export const REVENUE_COMPONENTS_MANAGEMENT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'revenue-components-management',
    name: RouteName.revenueComponentManagement,
    component: () => import('@/modules/revenues/views/RevenueComponentsManagementView.vue'),
    redirect: { name: RouteName.ancillaryCodesManagement },
    meta: {
      authorities: [Authority.RevenueComponentRead],
      isAllowed: codeManagementActivated,
      title: t('revenues.management.title'),
    },
    children: [
      {
        path: 'ancillaries',
        name: RouteName.ancillaryCodesManagement,
        component: () => import('@/modules/revenues/views/AncillaryCodesManagementView.vue'),
        meta: {
          authorities: [Authority.RevenueComponentRead],
          isAllowed: ancillaryActivated,
        },
      },
      {
        path: 'tax',
        name: RouteName.taxCodesManagement,
        component: () => import('@/modules/revenues/views/TaxCodesManagementView.vue'),
        meta: {
          authorities: [Authority.RevenueComponentRead],
          isAllowed: taxesActivated,
        },
      },
      {
        path: 'surcharges',
        name: RouteName.surchargeCodesManagement,
        component: () => import('@/modules/revenues/views/SurchargeCodesManagementView.vue'),
        meta: {
          authorities: [Authority.RevenueComponentRead],
          isAllowed: surchargeActivated,
        },
      },
    ],
  },
];
