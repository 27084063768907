import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { App, Plugin } from 'vue';

import { addLightIcons } from './pro-light-icons';
import { addProRegularIcons } from './pro-regular-icons';
import { addProSolidIcons } from './pro-solid-icons';

export const FontAwesome: Plugin = {
  install(app: App) {
    addProRegularIcons(library);
    addProSolidIcons(library);
    addLightIcons(library);

    app.component('FontAwesomeIcon', FontAwesomeIcon);
    app.component('FontAwesomeLayers', FontAwesomeLayers);
  },
};
