import { NavigationGuardNext, RouteLocationPathRaw, RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { authService } from '@/modules/api/auth/auth-service';
import { RouteName } from '@/modules/shared';
import { useUserStore } from '@/modules/user-settings/store/user.store';

export const APP_ROUTE: RouteRecordRaw = {
  path: '/app',
  component: () => import('@/modules/application/views/AppBase.vue'),
  redirect: { name: RouteName.appDefault },
} as unknown as RouteRecordRaw;

export const APP_ROUTE_DEFAULT: RouteRecordRaw = {
  // beforeEnter matches on path section matching, and we don't want to run this on child routes of the app.
  // So, to prevent unneeded processing we have a separate default landing page with its own logic
  path: '/app-default',
  name: RouteName.appDefault,
  beforeEnter: (to, from, next: NavigationGuardNext) => {
    // Defined redirects take precedence over the default route
    // We add fallbackNavigation so when authorisation fails for the redirect, the user is still guided to a default page
    const redirectUrl = to.query.redirect;

    if (redirectUrl && typeof redirectUrl === 'string') {
      // if the redirectUrl has params get those and put them in the query as well
      const redirectUrlParams =
        redirectUrl.indexOf('?') > 0 ? Object.fromEntries(new URLSearchParams(redirectUrl.slice(redirectUrl.indexOf('?')))) : {};
      const redirectRoute: RouteLocationPathRaw = {
        path: to.query.redirect as string,
        query: {
          fallbackNavigation: RouteName.appDefault,
          ...redirectUrlParams,
        },
      };

      return next(redirectRoute);
    }

    const userStore = useUserStore();
    // Redirect to Control route to avoid long-loading dashboards and resulting load on the database
    // If a user does not have flight:read permissions redirect to User settings
    authService.hasAuthority([Authority.FlightRead], userStore.user?.authorities || [])
      ? next({ name: RouteName.control })
      : next({ name: RouteName.user });
  },
} as RouteRecordRaw;
