import { Library } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faArrowLeft,
  faArrowRight,
  faArrowsMaximize,
  faBars,
  faBell,
  faBolt,
  faBookmark,
  faBroom,
  faCalendarAlt,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faCog,
  faCogs,
  faCompressAlt,
  faDown,
  faEllipsisH,
  faEllipsisV,
  faExclamation,
  faExclamationTriangle,
  faExpandAlt,
  faEye,
  faEyeSlash,
  faFaceFrownSlight,
  faFaceMeh,
  faFaceSmileBeam,
  faFilter,
  faHandshakeSimple,
  faHashtag,
  faHexagon,
  faHexagonExclamation,
  faHistory,
  faIndustry,
  faInfoCircle,
  faLightbulb,
  faLink,
  faMinus,
  faPaperPlane,
  faPaste,
  faPen,
  faPlane,
  faPlaneDeparture,
  faPlus,
  faPuzzlePiece,
  faQuestionCircle,
  faRedoAlt,
  faSave,
  faSearch,
  faSort,
  faStopwatch,
  faTags,
  faThumbsUp,
  faThumbtack,
  faTicketsAirline,
  faTimes,
  faToggleOn,
  faTrash,
  faTriangle,
  faUp,
  faUser,
  faWarning,
} from '@fortawesome/pro-solid-svg-icons';

export function addProSolidIcons(library: Library): void {
  library.add(
    faArchive,
    faArrowLeft,
    faArrowRight,
    faBars,
    faBell,
    faBolt,
    faBookmark,
    faBroom,
    faCalendarAlt,
    faChartLine,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faClock,
    faClock,
    faCog,
    faCogs,
    faCompressAlt,
    faDown,
    faEllipsisH,
    faEllipsisV,
    faExclamation,
    faExclamationTriangle,
    faExpandAlt,
    faEye,
    faEyeSlash,
    faFaceFrownSlight,
    faFaceMeh,
    faFaceSmileBeam,
    faFilter,
    faHandshakeSimple,
    faHashtag,
    faHistory,
    faIndustry,
    faInfoCircle,
    faLightbulb,
    faLink,
    faMinus,
    faPaperPlane,
    faPaste,
    faPen,
    faPlane,
    faPlaneDeparture,
    faPlus,
    faPuzzlePiece,
    faQuestionCircle,
    faRedoAlt,
    faSave,
    faSearch,
    faSort,
    faStopwatch,
    faTags,
    faThumbsUp,
    faThumbtack,
    faTicketsAirline,
    faTimes,
    faToggleOn,
    faTrash,
    faUp,
    faUser,
    faArrowsMaximize,
    faHexagonExclamation,
    faWarning,
    faHexagon,
    faTriangle,
    faCircle,
  );
}
