import { ApiClient } from '@/modules/api/base-client';
import { CalendarEvent, CalendarEventType, ICalendarService } from '@/modules/route-management/api/calendar.contracts';

export class CalendarService implements ICalendarService {
  private basePath = 'calendars';

  public async getBy(eventType: CalendarEventType, endDate: string): Promise<CalendarEvent[]> {
    const data =
      eventType || endDate
        ? {
            type: eventType,
            endDate,
          }
        : {};

    return ApiClient.get<CalendarEvent[]>(this.basePath, data);
  }
}

export const calendarService = new CalendarService();
