import { ApiClient } from '@/modules/api/base-client';
import { DataImportAssignment, TagAssignmentPayload } from '@/modules/data-import/api/data-import/data-import-contracts';
import { ICreateTag, ITag, ITagService } from '@/modules/tags';

export class TagService implements ITagService {
  private basePath = 'tags';

  public async get(filters?: { ond: number }): Promise<ITag[]> {
    return await ApiClient.get<ITag[]>(`${this.basePath}`, filters);
  }

  public async post(tags: ICreateTag[]): Promise<ITag[]> {
    return await ApiClient.post<ITag[]>(`${this.basePath}`, tags);
  }

  public async bulkAssign(request: DataImportAssignment<TagAssignmentPayload>): Promise<void> {
    return await ApiClient.patch<void>(`/${this.basePath}/bulk-assign`, request);
  }
}

export const tagsService = new TagService();
