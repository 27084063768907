import { OpLevelAdjustmentIncrementActionType, RouteManagementActions } from '@/models/enums';
import { CabinCode } from '@/modules/api/application/application-contracts';

import { RouteAction, RouteActionPayload, RouteActionType } from './route-actions-contracts';

export interface SetOpLevelAdjustmentIncrementPayload extends RouteActionPayload {
  opLevelAdjustmentIncrement: number;
  cabinCode: CabinCode;
}

const routeManagementActionTypeToRouteActionType: Record<OpLevelAdjustmentIncrementActionType, RouteActionType> = {
  [RouteManagementActions.BYOROpLevelAdjustIncrement]: RouteActionType.setBYOROpLevelAdjustmentIncrement,
  [RouteManagementActions.DynamicProgramOpLevelAdjustIncrement]: RouteActionType.setDynamicProgramOpLevelAdjustmentIncrement,
};

export const opLevelAdjustmentIncrementDefault: number = 50;

export class SetOpLevelAdjustmentIncrementAction implements RouteAction<SetOpLevelAdjustmentIncrementPayload> {
  public actionType: RouteActionType;

  public opLevelAdjustmentIncrement: number = opLevelAdjustmentIncrementDefault;

  public cabinCode: CabinCode = 'Y';

  constructor(cabinCode: CabinCode, opLevelAdjustmentIncrement: number, actionType: OpLevelAdjustmentIncrementActionType) {
    this.cabinCode = cabinCode;
    this.opLevelAdjustmentIncrement = opLevelAdjustmentIncrement;
    this.actionType = routeManagementActionTypeToRouteActionType[actionType];
  }

  public setPayload(payload: SetOpLevelAdjustmentIncrementPayload): void {
    this.opLevelAdjustmentIncrement = payload.opLevelAdjustmentIncrement;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): SetOpLevelAdjustmentIncrementPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      opLevelAdjustmentIncrement: this.opLevelAdjustmentIncrement,
    };
  }
}
