<template>
  <div>
    <span class="inline-block w-16" :data-test="isDiscrete ? 'cabin-class-discrete-value' : 'cabin-class-value'">
      {{ classCode }}
    </span>

    <span v-if="isDiscrete" class="text-primary text-2xs font-semibold uppercase">
      {{ t('discrete') }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { CabinStructure } from '@/modules/api/application/application-contracts';
import { i18n } from '@/plugins/i18n';
import { useAppSettingsStore } from '@/store/modules/app-settings.store';

const { t } = i18n.global;

export type ClassCodeItemProps = {
  classCode: string;
  cabinCode: string;
};

const props = defineProps<ClassCodeItemProps>();

const appSettingStore = useAppSettingsStore();
const { inventoryConfigurationProperties, multipleClassConfiguration } = storeToRefs(appSettingStore);

const cabin = computed<CabinStructure | undefined>(() =>
  inventoryConfigurationProperties.value.cabins.find((cabin) => cabin.code === props.cabinCode),
);
const isDiscrete = computed<boolean>(
  () => !!cabin.value?.classes.some((cls) => cls.code === props.classCode && cls.discrete) && !multipleClassConfiguration.value,
);

defineExpose({
  cabin,
  isDiscrete,
});
</script>
