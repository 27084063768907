import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../../api/flight-actions.contracts';

const { t } = i18n.global;

export interface MoveClusterLevelPayload extends BaseFlightActionPayload {
  cabinCode: CabinCode;
  offset: number;
}

export enum ClusterLevelOffsetDirection {
  Up,
  Down,
}

export class MoveClusterLevelAction implements FlightAction<MoveClusterLevelPayload> {
  public actionType: FlightActionType = FlightActionType.moveClusterLevel;
  public value = 1;
  public cabinCode: CabinCode;
  private direction = ClusterLevelOffsetDirection.Up;

  constructor(cabinCode?: CabinCode, direction: ClusterLevelOffsetDirection = ClusterLevelOffsetDirection.Up) {
    this.cabinCode = cabinCode;
    this.direction = direction;
  }

  public get label() {
    const directionIsDown = this.direction === ClusterLevelOffsetDirection.Down;
    return directionIsDown ? t('dynamic_filters.cluster_level_down') : t('dynamic_filters.cluster_level_up');
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    const directionIsDown = this.direction === ClusterLevelOffsetDirection.Down;
    const field = directionIsDown ? FilterFieldField.moveClusterLevelDown : FilterFieldField.moveClusterLevelUp;

    return {
      label: this.label,
      value: this.cabinCode + field,
      meta: {
        cabinCode: this.cabinCode,
        label: this.label,
        flightActionType: FlightActionType.moveClusterLevel,
        field: field,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + field,
        uniqueField: this.cabinCode,
        componentName: ComponentName.MoveClusterLevelField,
        value: this.value,
        isValueValid: (value: number) => isFinite(value),
        dataTest: `move-cluster-level-${directionIsDown ? 'down' : 'up'}-input`,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.direction =
      action.field === FilterFieldField.moveClusterLevelUp ? ClusterLevelOffsetDirection.Up : ClusterLevelOffsetDirection.Down;

    this.value = action.value;
    this.cabinCode = action.uniqueField as CabinCode;
  }

  public setPayload(payload: MoveClusterLevelPayload): void {
    /** If the offset is negative, the cluster level is being moved down */
    if (payload.offset < 0) {
      this.direction = ClusterLevelOffsetDirection.Down;
    }
    this.value = Math.abs(payload.offset);
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): MoveClusterLevelPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      offset:
        /** Based on the direction, the offset should be either positive (up), or negative (down) */
        this.direction === ClusterLevelOffsetDirection.Up ? this.value : this.value * -1,
    };
  }
}
