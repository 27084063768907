<template>
  <div class="flex gap-x-2 text-sm">
    <div class="w-1/4">
      {{ $t('linked_class_rules.class') }}
    </div>
    <div class="w-1/4">
      {{ $t('linked_class_rules.value') }}
    </div>
    <div class="w-1/4">
      {{ $t('linked_class_rules.unit') }}
    </div>
    <div class="w-1/4">
      {{ $t('linked_class_rules.linked_class') }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
