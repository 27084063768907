export const en = {
  revenues: {
    columns: {
      ancillary_revenue: {
        header_name: 'Ancillaries Revenue',
        header_tooltip: 'Ancillaries Revenue',
      },
      total_revenue: {
        header_name: 'Total Revenue',
        header_tooltip: 'Total Revenue',
      },
      average_ancillary_revenue_per_pax: {
        header_name: 'AvgAnc',
        header_tooltip: 'Average Ancillaries Revenue per Passenger',
      },
      total_ancillaries_ratio: {
        header_name: 'Ancillaries Share of Revenue',
        header_tooltip: 'Ancillaries Share of Revenue',
      },
      surcharge_revenue: {
        header_name: 'Surcharge Revenue',
        header_tooltip: 'Surcharge Revenue',
      },
      tax_revenue: {
        header_name: 'Tax Revenue',
        header_tooltip: 'Tax Revenue',
      },
    },
    ancillaryCategory: {
      category: 'Category',
      countOfSales: 'Units',
      revenues: 'Revenue',
      ratio: '%',
    },
    management: {
      title: 'Revenue Components Management',
      tabs: {
        ancillaries: 'Ancillaries',
        taxes: 'Taxes',
        surcharges: 'Surcharges',
      },
      ancillaries: {
        create_category_failed: 'Failed to create ancillary category',
        category_failed_unique_name: 'Category name must be unique',
        delete_category_failed: 'Failed to delete ancillary category',
        assign_category_failed: 'Failed to assign ancillaries to category',
        update_failed: 'Failed to update ancillary information',
        titles: {
          ancillary_management: 'Ancillary Management',
          group_management: 'Category Management',
          add_category: 'Add Category',
          category: 'Ancillary Categories',
          assign_to_category: 'Assign Ancillaries to Category',
        },
        placeholders: {
          search_categories: 'Search Categories',
        },
        labels: {
          category_name: 'Category Name',
        },
        columns: {
          name: {
            header_name: 'Name',
            header_tooltip: 'Ancillary Name',
          },
          code: {
            header_name: 'Code',
            header_tooltip: 'Ancillary Code',
          },
          category: {
            header_name: 'Category',
            header_tooltip: 'Ancillary Category',
            no_category: 'Uncategorized',
          },
          details: {
            header_name: 'Details',
            header_tooltip: 'Ancillary Details',
          },
          assigned_count: {
            header_name: 'Assigned Ancillaries',
            header_tooltip: 'Number of ancillaries assigned to this category',
          },
        },
        actions: {
          title: 'Actions',
          edit_categories: 'Edit Categories',
          add_category: 'Add Category',
          close: 'Close',
          create: 'Create',
          assign: 'Assign',
          delete: 'Delete',
          assign_to_category: 'Assign to Category',
          unassign_category: 'Unassign Category',
        },
      },
      taxes: {
        titles: {
          tax_management: 'Tax Management',
        },
        placeholders: {
          search_taxes: 'Search Taxes',
        },
        labels: {
          code: 'Code',
          name: 'Name',
          description: 'Description',
        },
        columns: {
          code: {
            header_name: 'Code',
            header_tooltip: 'Tax System Code',
          },
          name: {
            header_name: 'Name',
            header_tooltip: 'Tax Name',
          },
          details: {
            header_name: 'Details',
            header_tooltip: 'Tax Details',
          },
        },
      },
      surcharges: {
        titles: {
          surcharge_management: 'Surcharge Management',
        },
        placeholders: {},
        labels: {
          code: 'Code',
          name: 'Name',
          description: 'Description',
        },
        columns: {
          code: {
            header_name: 'Code',
            header_tooltip: 'Surcharge System Code',
          },
          name: {
            header_name: 'Name',
            header_tooltip: 'Surcharge Name',
          },
          details: {
            header_name: 'Details',
            header_tooltip: 'Surcharge Details',
          },
        },
      },
    },
  },
};
