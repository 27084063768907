import { RouteRecordRaw } from 'vue-router';

import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const USER_SETTINGS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/user',
    name: RouteName.user,
    meta: {
      title: t('user_settings.titles.user_settings'),
    },
    component: () => import('@/modules/user-settings/views/UserSettingsView.vue'),
  },
  {
    path: `settings/${RouteName.userChangePassword}`,
    name: RouteName.userChangePassword,
    meta: {
      title: t('user_settings.titles.change_password'),
    },
    component: () => import('@/modules/user-settings/views/UserChangePasswordView.vue'),
  },
];
