import { AircraftTypeModel, IAircraftTypeService } from './aircraft-type-contracts';
import { ApiClient } from '../base-client';

export class AircraftTypeService implements IAircraftTypeService {
  private basePath = 'legs/aircrafttype';

  public async getAll(): Promise<AircraftTypeModel[]> {
    return await ApiClient.get<AircraftTypeModel[]>(`${this.basePath}`);
  }
}

export const aircraftTypeService = new AircraftTypeService();
