import { LinkedClassRuleUnit } from '../api/linked-class-rules.contracts';

export const en = {
  linked_class_rules: {
    linked_class_rules: 'Linked Class Rules',
    new_linked_class_rule: 'New Linked Class Rule',
    set_linked_class_rules: 'Set Linked Class Rule',
    remove_linked_class_rules: 'Remove Linked Class Rule',
    loading_rules: 'Loading Linked Class Rules',
    create_new_linked_class_rule: 'Create new Linked Class Rule',
    no_rules: 'No Linked Class Rules',
    no_rules_description:
      'There are no Linked Class Rules present in the system. Rules allow you to recalculate classes when a manual change is made to a flight.',
    amount_of_classes_linked: 'Classes Linked',
    class: 'Class',
    value: 'Value',
    unit: 'Unit',
    loading: 'Loading',
    linked_class: 'Linked Class',
    linked_classes: 'Linked Classes',
    add_linked_class_rule: 'Add New Rule',
    general: 'General',
    display_name: 'Display Name',
    display_name_placeholder: 'Max 20 Characters',
    unique_linked_class_rule_name_needed: 'Rule should have a unique name',
    linked_class_rule_empty_list: 'Rule should have at least one class rule attached to it',
    enum_linked_class_rule_unit: {
      [LinkedClassRuleUnit.PERCENT_OF_AU]: 'Percent Of AU',
    },
  },
};
