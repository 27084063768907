import { RouteAction, RouteActionPayload, RouteActionType } from './route-actions-contracts';

export interface SetCompetitiveFareRangePayload extends RouteActionPayload {
  competitiveFareStartAt: number;
  competitiveFareEndAt: number;
}

export class SetCompetitiveFareRangeAction implements RouteAction<SetCompetitiveFareRangePayload> {
  public actionType: RouteActionType = RouteActionType.setCompetitiveFareRange;

  public competitiveFareStartAt: number;
  public competitiveFareEndAt: number;

  constructor(competitiveFareStartAt: number, competitiveFareEndAt: number) {
    this.competitiveFareStartAt = competitiveFareStartAt;
    this.competitiveFareEndAt = competitiveFareEndAt;
  }

  public setPayload(payload: SetCompetitiveFareRangePayload): void {
    this.competitiveFareStartAt = payload.competitiveFareStartAt;
    this.competitiveFareEndAt = payload.competitiveFareEndAt;
  }

  public getPayload(): SetCompetitiveFareRangePayload {
    return {
      actionType: this.actionType,
      competitiveFareStartAt: this.competitiveFareStartAt,
      competitiveFareEndAt: this.competitiveFareEndAt,
    };
  }
}
