export interface CalendarEvent {
  id: number;
  type: string;
  name: string;
  startDate: string;
  endDate: string;
}

export enum CalendarEventType {
  Season = 'season',
}

export interface ICalendarService {
  getBy(type: CalendarEventType, endDate: string): Promise<CalendarEvent[]>;
}
