import {
  aircraftTypeFilter,
  autopilotFilter,
  carrierCodeFilter,
  clusterFilter,
  eventClusterFilter,
  eventNameFilter,
  flightPathFilter,
  hubFilter,
  invertSearchFilter,
  optimizationProfileFilter,
  optimizationTacticFilter,
  routeGroupFilter,
  stopsFilter,
  tagFilter,
  userFilter,
} from '@/models/filters/definitions/control';
import {
  dayOfWeekFilter,
  departureDateRangeFilter,
  destinationFilter,
  flightNumberFilter,
  originFilter,
} from '@/models/filters/definitions/shared';
import { RouteFilterType } from '@/modules/api/shared-contracts';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';

export function generateControlFilters(filterType?: RouteFilterType): FilterFieldDefinition[] {
  const filters: FilterFieldDefinition[] = [
    dayOfWeekFilter,
    departureDateRangeFilter,
    tagFilter,
    userFilter,
    flightNumberFilter,
    aircraftTypeFilter,
    routeGroupFilter,
    carrierCodeFilter,
    autopilotFilter,
    optimizationProfileFilter,
    optimizationTacticFilter,
    clusterFilter,
    eventNameFilter,
    eventClusterFilter,
    stopsFilter,
  ];
  switch (filterType) {
    case RouteFilterType.origin_destination:
      filters.push(originFilter, destinationFilter, invertSearchFilter);
      break;
    case RouteFilterType.hub:
      filters.push(hubFilter, flightPathFilter, invertSearchFilter);
      break;
    default:
      // TODO: (KB) when would you want to have all the filters available with a combination of both market types?
      filters.push(originFilter, destinationFilter, hubFilter, flightPathFilter, invertSearchFilter);
      break;
  }

  return filters;
}
