import { useTitle } from '@vueuse/core';
import { NavigationHookAfter } from 'vue-router';

import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const titleHook: NavigationHookAfter = (to) => {
  const defaultTitle = t('application.titles.eddy');
  const navigationTitle = to.meta?.title;
  const title =
    typeof navigationTitle === 'function'
      ? `${defaultTitle}: ${navigationTitle()}`
      : typeof navigationTitle === 'string'
        ? `${defaultTitle}: ${navigationTitle}`
        : defaultTitle;

  useTitle(title);
};
