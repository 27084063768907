import { IFlow, Routine } from '@/modules/flowfx/api/flows.contracts';

export enum BranchType {
  CORNER = 'corner',
  LINE = 'line',
  T = 't',
}

export enum BranchDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum Statement {
  IF = 'if',
  ELSEIF = 'else if',
  ELSE = 'else',
}

export type EnhancedRoutine = Routine & { guid: string };

export enum FlowTrigger {
  NEW_FLIGHT = 'new-flight',
  FLIGHT_CABIN_CAPACITY_CHANGE = 'flight-cabin-capacity-change',
  BOOKINGS_CHANGE = 'bookings-change',
  ALL_SYSTEMS_GO = 'all-systems-go',
  FLIGHT_DEPARTURE_TIME_CHANGE = 'flight-departure-time-change',
  SCHEDULED = 'scheduled',
  BYOD_CHANGE = 'byod-change',
  COMPETITIVE_FARE_CHANGE = 'competitive-fare-change',
  ROUTINE = 'routine',
}

/**
 * The backend expects the enum keys in the endpoint instead of the values.
 * @example getFlowTriggerKeyName(FlowTrigger.NEW_FLIGHT) // 'NEW_FLIGHT' (instead of 'new-flight')
 */
export function getFlowTriggerKeyName(value: string): string | undefined {
  return Object.entries(FlowTrigger).find(([, val]) => val === value)?.[0];
}

/**
 * The root level condition options that are selectable by the user.
 * Any new options need to be added.
 */

export enum ConditionName {
  AUTOPILOT = 'autopilot',
  AVERAGE_FARE = 'averageFare',
  BOOKINGS = 'bookings',
  CAPACITY = 'capacity',
  CAPACITY_LOAD_FACTOR = 'capacityLoadFactor',
  CARRIER_CODE = 'carrierCode',
  CLUSTER = 'cluster',
  DAY_OF_WEEK = 'dayOfWeek',
  DAYS_SINCE_LAST_BOOKING = 'daysSinceLastBooking',
  DAYS_SINCE_LAST_MODIFIED = 'daysSinceLastModified',
  DAYS_SINCE_LAST_VIEWED = 'daysSinceLastViewed',
  DEPARTURE_DATE = 'departureDate',
  DEPARTURE_TIME = 'departureTime',
  EQUIPMENT_TYPE = 'equipmentType',
  LAF = 'laf',
  FLIGHT_NUMBER = 'flightNumber',
  LID = 'lid',
  LID_LOAD_FACTOR = 'lidLoadFactor',
  MIN_COMPETITIVE_FARE_DELTA = 'minCompetitiveFareDelta',
  MIN_RIVAL_FARE_DELTA = 'minRivalFareDelta',
  NUMBER_OF_DAYS_OUT = 'numberOfDaysOut',
  OPTIMISATION_PROFILE_GROUP_LEVEL = 'optimisationProfileLevelId',
  OPTIMISATION_PROFILE_LEVEL = 'optimisationProfileLevel',
  OPTIMISATION_TACTIC = 'optimisationTactic',
  OVERNIGHTS = 'overnights',
  PRICING_ADJUSTMENT = 'pricingAdjustment',
  PRICING_AGGREGATION = 'pricingAggregation',
  PRICING_TACTIC = 'pricingTactic',
  REVENUE = 'revenue',
  TAGS = 'tags',
  PERFORMANCE_BAND = 'performanceBand',
  PERFORMANCE_BAND_PICKUP = 'performanceBandPickup',
  MIN_COMPETITIVE_FARE = 'minCompetitiveFare',
  MIN_RIVAL_FARE = 'minRivalFare',
  OWN_FARE = 'ownFare',
  LOOKS = 'looks',
  BOOKS = 'books',
  LOOKS_BOOKS_RATIO = 'ratio',
}

export enum FlowsTableAction {
  VIEW = 'view',
  ASSIGN_ROUTES = 'assign_routes',
  ARCHIVE = 'archive',
  RESTORE = 'restore',
  COPY = 'copy',
}

export interface ISelectedFlowsOverviewAction {
  action: FlowsTableAction;
  flow: IFlow;
}

export enum ScheduleBasis {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}

/**
 * The option that can be used to present to the user.
 */
export interface IFlowOption {
  label?: string;
  /**
   * The name is actually the unique identifier of the option.
   */
  name: string;
  /**
   * Some options can have nested options.
   * @example Average Fare -> Cabin -> Y
   */
  options?: IFlowOption[];
}

export interface IFlowSelectOption<T = any> {
  id: string | number;
  label: string | number;
  /**
   * To support nested options in UI controls like cascaders.
   */
  options?: IFlowSelectOption<T>[];
  meta?: {
    cabinCode?: string;
  } & T;
}

export enum DayOfWeekCaps {
  'MONDAY' = 'MONDAY',
  'TUESDAY' = 'TUESDAY',
  'WEDNESDAY' = 'WEDNESDAY',
  'THURSDAY' = 'THURSDAY',
  'FRIDAY' = 'FRIDAY',
  'SATURDAY' = 'SATURDAY',
  'SUNDAY' = 'SUNDAY',
}

export enum BlockActionType {
  ADD_ACTIONS = 'add-actions',
  ADD_CONDITIONS = 'add-conditions',
  ADD_ROUTINE = 'add-routine',
}

export interface BlockAction {
  id: string;
  label: string;
  testLabel: string;
  visible: boolean;
}
