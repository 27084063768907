import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import RivalRulesDetails from '@/modules/rules/rival-rules/views/RivalRulesDetails.vue';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const hasRivalRulesEnabled = (): boolean => {
  const customerSettingsStore = useCustomerSettingsStore();
  return !!customerSettingsStore.settings?.hasRivalRulesEnabled;
};

const { t } = i18n.global;

export const RIVAL_ROUTES: RouteRecordRaw[] = [
  {
    path: 'rival-rules/create',
    name: RouteName.rivalRulesCreate,
    meta: {
      authorities: [Authority.RivalRulesUpdate],
      isAllowed: hasRivalRulesEnabled,
      title: t('rival_rules.rival_rules'),
    },
    component: RivalRulesDetails,
  },
  {
    path: 'rival-rules/:id',
    name: RouteName.rivalRulesDetails,
    meta: {
      authorities: [Authority.RivalRulesRead],
      isAllowed: hasRivalRulesEnabled,
      title: t('rival_rules.rival_rules'),
    },
    component: RivalRulesDetails,
  },
];
