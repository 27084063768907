import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { ClearableClasses, FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { isValueNotEmpty } from './validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../api/flight-actions.contracts';

const { t } = i18n.global;

export interface UpdateSourceFlightPayload extends BaseFlightActionPayload {
  sourceFlightId: number;
}

export class UpdateSourceFlightAction implements FlightAction<UpdateSourceFlightPayload> {
  public actionType: FlightActionType = FlightActionType.updateSourceFlight;
  public label = t('update_source_flight');
  public value: number;

  constructor(sourceFlightId?: number) {
    this.value = sourceFlightId;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: FlightActionType.updateSourceFlight,
      meta: {
        label: this.label,
        flightActionType: FlightActionType.updateSourceFlight,
        uniqueField: FlightActionType.updateSourceFlight,
        field: FilterFieldField.updateSourceFlight,
        type: FilterFieldType.equal,
        value: undefined,
        componentDataOptions: [],
        valueKey: FlightActionType.updateSourceFlight,
        componentName: ComponentName.RivalRuleSelectFilterField,
        clearableClasses: ClearableClasses.none,
        isValueValid: (value: any[]) => isValueNotEmpty(value),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
  }

  public setPayload(payload: UpdateSourceFlightPayload): void {
    this.value = payload.sourceFlightId;
  }

  public getPayload(): UpdateSourceFlightPayload {
    return {
      actionType: this.actionType,
      sourceFlightId: this.value,
    };
  }
}
