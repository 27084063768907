import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';
import { ICreateTag, ITag, tagsService } from '@/modules/tags';
import { i18n } from '@/plugins/i18n';

export const useTagsStore = defineStore('tags', () => {
  const collator = new Intl.Collator(i18n.global.locale.value);
  const isLoading: Ref<boolean> = ref(false);
  const tagsDefaultValue: ITag[] = [];
  const _tags: Ref<ITag[]> = ref([...tagsDefaultValue]);

  const tags: ComputedRef<ITag[]> = computed(() => _tags.value.sort(({ name: nameA }, { name: nameB }) => collator.compare(nameA, nameB)));

  async function get(): Promise<void> {
    try {
      isLoading.value = true;
      _tags.value = await tagsService.get();
    } catch (e) {
      logger.error(e as Error);
      MessageService.error('Failed to fetch tags');
    } finally {
      isLoading.value = false;
    }
  }

  async function create(tags: ICreateTag[]): Promise<ITag[]> {
    const response = await tagsService.post(tags);
    _tags.value = [..._tags.value, ...response];
    return response;
  }

  function $reset(): void {
    _tags.value = [...tagsDefaultValue];
    isLoading.value = false;
  }

  return {
    tags,
    isLoading,
    create,
    get,
    $reset,
  };
});
