import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';

import { RivalRuleDetailDto, RivalRuleDetailFareType, RivalRuleDto } from '../api/rival-rules.contracts';

/**
 * The RivalRuleModel represents a Rival Rule, which contains a group of rival rules details that specify your main rivals.
 */
export class RivalRuleModel {
  public id: number;
  public name: string;
  public description: string;
  public createdBy: UserModel;
  public modifiedBy: UserModel;
  public createdDateTime: string;
  public modifiedDateTime?: string;
  /** Determines whether the rule is soft-deleted. */
  public isArchived: boolean;
  public details: Array<RivalRuleDetailModel>;

  constructor({
    id,
    name,
    description,
    createdBy,
    modifiedBy,
    createdDateTime,
    modifiedDateTime,
    isArchived,
    details,
  }: {
    id: number;
    name: string;
    description: string;
    createdBy: UserModel;
    modifiedBy: UserModel;
    createdDateTime: string;
    modifiedDateTime: string;
    isArchived: boolean;
    details: Array<RivalRuleDetailModel>;
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
    this.createdDateTime = createdDateTime;
    this.modifiedDateTime = modifiedDateTime;
    this.isArchived = isArchived;
    this.details = details;
  }

  public static fromDto(dto: RivalRuleDto) {
    return new RivalRuleModel({
      id: dto.id,
      name: dto.name,
      description: dto.description,
      createdBy: dto.createdBy,
      modifiedBy: dto.modifiedBy,
      createdDateTime: dto.createdDateTime,
      modifiedDateTime: dto.modifiedDateTime ?? '',
      isArchived: dto.isArchived,
      details: dto.details ? RivalRuleDetailModel.fromDtoList(dto.details) : [],
    });
  }

  public static fromDtoList(dtos: RivalRuleDto[]): RivalRuleModel[] {
    return dtos.map((dto) => RivalRuleModel.fromDto(dto));
  }

  /** Instantiates a new, empty model */
  public static new(): RivalRuleModel {
    return new RivalRuleModel({
      createdBy: undefined,
      createdDateTime: undefined,
      description: '',
      id: undefined,
      isArchived: false,
      modifiedBy: undefined,
      modifiedDateTime: undefined,
      name: 'Rival Rule',
      details: [],
    });
  }

  /**
   * Converts the Model to the DTO, for API communication.
   * @returns A DTO for the API containg the Rival Rule
   */
  public toDto(): RivalRuleDto {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      createdBy: this.createdBy,
      modifiedBy: this.modifiedBy,
      createdDateTime: this.createdDateTime,
      modifiedDateTime: this.modifiedDateTime,
      isArchived: this.isArchived,
      details: this.details.map((model) => model.toDto()),
    };
  }
}

/**
 * The RivalRuleDetailModel represents an individual rival rule.
 */
export class RivalRuleDetailModel {
  public ruleId: number;
  public rivalCarrierCode: string;
  public rivalTimeWindow: number[];
  public stopoverCounts: number[] = [];
  public fareType: RivalRuleDetailFareType = null;

  constructor({
    ruleId,
    rivalCarrierCode,
    rivalTimeWindowLower,
    rivalTimeWindowUpper,
    fareType,
    stopoverCounts,
  }: {
    ruleId?: number;
    rivalCarrierCode: string;
    rivalTimeWindowLower: number;
    rivalTimeWindowUpper: number;
    fareType?: RivalRuleDetailFareType;
    stopoverCounts?: number[];
  }) {
    this.ruleId = ruleId;
    this.rivalCarrierCode = rivalCarrierCode;
    this.rivalTimeWindow = [rivalTimeWindowLower, rivalTimeWindowUpper];
    this.fareType = fareType ?? null;
    this.stopoverCounts = stopoverCounts ?? [];
  }

  public static fromDto(dto: RivalRuleDetailDto): RivalRuleDetailModel {
    return new RivalRuleDetailModel({
      ruleId: dto.ruleId,
      rivalCarrierCode: dto.rivalCarrierCode,
      rivalTimeWindowLower: dto.rivalTimeWindowLower,
      rivalTimeWindowUpper: dto.rivalTimeWindowUpper,
      fareType: dto.fareType ?? null,
      stopoverCounts: dto.stopoverCounts ?? [],
    });
  }

  public static fromDtoList(dtos: RivalRuleDetailDto[]): RivalRuleDetailModel[] {
    return dtos.map((dto) => RivalRuleDetailModel.fromDto(dto));
  }

  public toDto(): RivalRuleDetailDto {
    return {
      ruleId: this.ruleId,
      rivalCarrierCode: this.rivalCarrierCode,
      rivalTimeWindowLower: this.rivalTimeWindow[0],
      rivalTimeWindowUpper: this.rivalTimeWindow[1],
      fareType: this.fareType,
      stopoverCounts: this.stopoverCounts,
    };
  }
}
