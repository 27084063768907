import { ApiClient } from '@/modules/api/base-client';

import { FlightActionGroup, FlightActionGroupSetDto, IFlightActionGroupsService } from './flight-action-groups.contracts';

export class FlightActionGroupsService implements IFlightActionGroupsService {
  private basePath = 'flight-action-groups';

  public async create(flightAction: FlightActionGroupSetDto): Promise<FlightActionGroup> {
    return await ApiClient.post<FlightActionGroup>(`/${this.basePath}`, flightAction);
  }

  public async edit(id: number, flightAction: FlightActionGroupSetDto): Promise<FlightActionGroup> {
    return await ApiClient.put<FlightActionGroup>(`/${this.basePath}/${id}`, flightAction);
  }

  public async delete(flightActionId: number): Promise<any> {
    return await ApiClient.delete(`/${this.basePath}/${flightActionId}`);
  }

  public async getAll(): Promise<FlightActionGroup[]> {
    return await ApiClient.get<FlightActionGroup[]>(`/${this.basePath}`);
  }

  // TODO: Make API endpoint that supports a put/edit for the whole collection
  public async saveAll(actionGroups: FlightActionGroup[]): Promise<FlightActionGroup[]> {
    return await Promise.all(
      actionGroups.map(
        async (group, index) =>
          await flightActionGroupsService.edit(group.id, {
            ...group,
            groupOrder: index,
          }),
      ),
    );
  }
}

export const flightActionGroupsService = new FlightActionGroupsService();
