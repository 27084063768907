import { PssName } from '@/modules/api/application/application-contracts';
import { ApiPssCapabilities } from '@/modules/customer-settings/api/customer-settings.contracts';

import { PssCapabilities1a } from './definitions/1a';
import { PssCapabilities1d } from './definitions/1d';
import { PssCapabilities1n } from './definitions/1n';
import { PssCapabilities1s } from './definitions/1s';
import { PssCapabilities1v } from './definitions/1v';
import { PssCapabilities1x } from './definitions/1x';
import { PssCapabilities1y } from './definitions/1y';
import { UiAndApiPssCapabilities } from './pss-capabilities';

export function getUiCapabilitiesByPss(pss: PssName, apiPssCapabilities: ApiPssCapabilities): UiAndApiPssCapabilities {
  let uiPssCapabilities = undefined;
  switch (pss) {
    case PssName.Amadeus:
      uiPssCapabilities = PssCapabilities1a;
      break;
    case PssName.Radixx:
      uiPssCapabilities = PssCapabilities1d;
      break;
    case PssName.Sabre:
      uiPssCapabilities = PssCapabilities1s;
      break;
    case PssName.Navitaire:
      uiPssCapabilities = PssCapabilities1n;
      break;
    case PssName.Genius_TurSys:
      uiPssCapabilities = PssCapabilities1x;
      break;
    case PssName.Pribas:
      uiPssCapabilities = PssCapabilities1y;
      break;
    case PssName.Videcom:
      uiPssCapabilities = PssCapabilities1v;
      break;

    default:
      break;
  }

  return Object.assign({}, apiPssCapabilities, uiPssCapabilities);
}
