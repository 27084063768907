import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { FlightActionGroup, FlightActionGroupSetDto } from '@/modules/flight-actions/api/flight-action-groups.contracts';
import { flightActionGroupsService } from '@/modules/flight-actions/api/flight-action-groups.service';
import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';

export const useFlightActionGroupsStore = defineStore('flightActionGroups', () => {
  const isLoading: Ref<boolean> = ref(false);
  const _flightActionGroups: Ref<Map<number, FlightActionGroup>> = ref(new Map());
  const flightActionGroups: ComputedRef<FlightActionGroup[]> = computed(() =>
    Array.from(_flightActionGroups.value, ([, group]) => group).sort((a, b) => a.groupOrder - b.groupOrder),
  );

  function $reset(): void {
    isLoading.value = false;
    _flightActionGroups.value = new Map();
  }

  async function get(): Promise<void> {
    try {
      isLoading.value = true;
      const groups = await flightActionGroupsService.getAll();
      _flightActionGroups.value = new Map(groups.map((group) => [group.id, group]));
    } catch (e) {
      MessageService.failedRequest('Failed to fetch Flight Action Groups');
    } finally {
      isLoading.value = false;
    }
  }

  async function update(groups: FlightActionGroup[]): Promise<void> {
    try {
      isLoading.value = true;
      const updatedGroups = await flightActionGroupsService.saveAll(groups);
      updatedGroups.forEach((group) => _flightActionGroups.value.set(group.id, group));
    } catch (e) {
      MessageService.failedRequest('Failed to update Flight Action Groups');
      // TODO: Check if we can get rid of this since now the component is also responsible for handling the error
      throw e;
    } finally {
      isLoading.value = false;
    }
  }

  async function remove(id: FlightActionGroup['id']): Promise<void> {
    const actionToBeDeleted = _flightActionGroups.value.get(id);
    logger.trackEvent('Delete Quick Action', {
      name: actionToBeDeleted?.name,
      actions: actionToBeDeleted?.actions,
    });

    try {
      isLoading.value = true;
      await flightActionGroupsService.delete(id);
      _flightActionGroups.value.delete(id);
    } catch (e) {
      MessageService.failedRequest('Failed to remove Flight Action Group');
    } finally {
      isLoading.value = false;
    }
  }

  async function create(payload: FlightActionGroupSetDto): Promise<void> {
    try {
      isLoading.value = true;
      const response: FlightActionGroup = await flightActionGroupsService.create(payload);
      _flightActionGroups.value.set(response.id, response);
    } catch (e) {
      MessageService.failedRequest(`Failed to create Flight Action Group ${payload.name}`);
      // TODO: Check if we can get rid of this since now the component is also responsible for handling the error
      throw e;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    flightActionGroups,
    get,
    create,
    remove,
    update,
    $reset,
  };
});
