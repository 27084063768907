import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import RulesOverview from '@/modules/rules/views/RulesOverview.vue';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const hasLinkedClassRulesEnabled = (): boolean => {
  const customerSettingsStore = useCustomerSettingsStore();
  return !!customerSettingsStore.settings?.hasLinkedClassRulesEnabled;
};
const hasRivalRulesEnabled = (): boolean => {
  const customerSettingsStore = useCustomerSettingsStore();
  return !!customerSettingsStore.settings?.hasRivalRulesEnabled;
};

export function getRedirect(): { name: RouteName } {
  // If both Linked Class and Rival rule are not enabled go to Control
  // This practically shouldn't happen since the Rule link in the main menu won't appear
  const rivalRuleRedirect = hasRivalRulesEnabled() ? { name: RouteName.rivalRulesOverview } : { name: RouteName.control };
  return hasLinkedClassRulesEnabled() ? { name: RouteName.linkedClassRulesOverview } : rivalRuleRedirect;
}

export function rulesRedirect(to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext): void {
  if (to.name === RouteName.rulesOverview) {
    const redirect = getRedirect();
    next(redirect);
  } else {
    next();
  }
}

const { t } = i18n.global;

export const RULES_ROUTES: RouteRecordRaw[] = [
  {
    path: 'rules',
    name: RouteName.rulesOverview,
    component: RulesOverview,
    children: [
      {
        path: 'linked-class-rules',
        name: RouteName.linkedClassRulesOverview,
        meta: {
          authorities: [Authority.LinkedClassRulesRead],
          isAllowed: hasLinkedClassRulesEnabled,
          title: t('linked_class_rules.linked_class_rules'),
        },
        component: () => import('../linked-class-rules/views/LinkedClassRulesOverview.vue'),
      },
      {
        path: 'rival-rules',
        name: RouteName.rivalRulesOverview,
        meta: {
          authorities: [Authority.RivalRulesRead],
          isAllowed: hasRivalRulesEnabled,
          title: t('rival_rules.rival_rules'),
        },
        component: () => import('../rival-rules/views/RivalRulesOverview.vue'),
      },
    ],
  },
];
