import { defineStore, storeToRefs } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { FlightFilterModel, FlightLineModel, SlimFlightLineModel } from '@/modules/api/flight/flight-contracts';
import { useFlightStore } from '@/store/modules/flight.store';

export const useFlightReviewStore = defineStore(
  'flightReview',
  () => {
    const currentPosition: Ref<number> = ref(0);
    const reviewQueue: Ref<FlightFilterModel[]> = ref([]);
    const supportReviewQueue: Ref<boolean> = ref(true);

    const canGoToPrevious: ComputedRef<boolean> = computed(() => currentPosition.value > 0);
    const canGoToNext: ComputedRef<boolean> = computed(() => currentPosition.value < reviewQueue.value.length - 1);
    const currentFlightLine: ComputedRef<FlightFilterModel> = computed(() => reviewQueue.value[currentPosition.value]);

    const flightStore = useFlightStore();
    const { selectedFlightLine } = storeToRefs(flightStore);

    async function reviewFlights(flightLines: SlimFlightLineModel[] | FlightFilterModel[]): Promise<void> {
      currentPosition.value = 0;
      // check if flightLines is an array of FlightFilterModel
      if (flightLines[0] instanceof FlightFilterModel) {
        reviewQueue.value = flightLines as FlightFilterModel[];
      } else {
        reviewQueue.value = flightLines.map((flightLine) => FlightFilterModel.fromFlight(flightLine as FlightLineModel));
      }
      await selectFlightLine();
    }

    async function moveToNext(): Promise<void> {
      if (canGoToNext.value) {
        currentPosition.value = currentPosition.value + 1;
        await selectFlightLine();
      }
    }

    async function moveToPrevious(): Promise<void> {
      if (canGoToPrevious.value) {
        currentPosition.value = currentPosition.value - 1;
        await selectFlightLine();
      }
    }

    async function moveToPosition(queuePosition: number): Promise<void> {
      if (reviewQueue.value[queuePosition]) {
        currentPosition.value = queuePosition;
        await selectFlightLine();
      }
    }

    async function selectFlightLine(): Promise<void> {
      await flightStore.getFlight(reviewQueue.value[currentPosition.value]);
      const selected = reviewQueue.value[currentPosition.value].flightLine || selectedFlightLine.value?.flightLine;
      if (selected) {
        flightStore.selectFlightLine(selected);
      }
    }

    function $reset(): void {
      currentPosition.value = 0;
      reviewQueue.value = [];
    }

    return {
      currentFlightLine,
      currentPosition,
      reviewQueue,
      supportReviewQueue,
      canGoToPrevious,
      canGoToNext,
      reviewFlights,
      moveToNext,
      moveToPrevious,
      moveToPosition,
      selectFlightLine,
      $reset,
    };
  },
  {
    persist: [
      {
        storage: sessionStorage,
      },
    ],
  },
);
