import { trimEnd } from 'lodash-es';

import { FilterField, FilterFieldType, FilterGroup } from '@/modules/api/shared-contracts';

export const getFlightNumberRangeQueryString = (filterGroupFields: (FilterGroup | FilterField)[]): string => {
  let computedValue = '';

  filterGroupFields.forEach((field) => {
    const groupedFilterField = field as unknown as FilterField;

    if (groupedFilterField.type === FilterFieldType.between) {
      computedValue += `${(groupedFilterField.value as number[])[0]}-${(groupedFilterField.value as number[])[1]},`;
    } else if (groupedFilterField.type === FilterFieldType.equal) {
      computedValue += `${groupedFilterField.value},`;
    }
  });

  // remove the last comma if there's any
  return trimEnd(computedValue, ',');
};
