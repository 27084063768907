import { AuthTokenModel } from '@/modules/api/auth/auth-contracts';

export class JwtService {
  public static decodeToken(token: string): AuthTokenModel {
    const parts = token.split('.');
    let decoded = null;

    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }
    try {
      decoded = this.urlBase64Decode(parts[1]);
    } catch (e) {
      throw new Error('Error decoding token');
    }
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }

    return JSON.parse(decoded) as AuthTokenModel;
  }

  public static getTokenExpirationDate(token: string): Date {
    const decoded = this.decodeToken(token);

    if (typeof decoded.exp === 'undefined') {
      return null;
    }

    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  public static isTokenExpired(token: string, offsetSeconds?: number): boolean {
    const date = this.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;
    if (date === null) {
      return false;
    }

    // Token expired?
    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  private static urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw new Error('Illegal base64url string!');
      }
    }

    return decodeURIComponent(escape(window.atob(output)));
  }
}
