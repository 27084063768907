import { ApiClient } from '@/modules/api/base-client';
import { LinkedClassRuleAssignmentPayload } from '@/modules/data-import/api/data-import/data-import-contracts';

import { ILinkedClassRulesService, LinkedClassRuleDto, LinkedClassRuleUpdateDto } from './linked-class-rules.contracts';
import { LinkedClassRuleModel } from '../models/linked-class-rule.model';

/**
 * Communicates with the Production API for Linked Class Rules related calls
 */
export class LinkedClassRulesService implements ILinkedClassRulesService {
  private _baseUrl = 'linked-class-rules';

  async create(payload: LinkedClassRuleUpdateDto): Promise<LinkedClassRuleModel> {
    const result = await ApiClient.post<LinkedClassRuleDto>(this._baseUrl, payload);

    return LinkedClassRuleModel.fromDto(result);
  }

  async getAll(): Promise<LinkedClassRuleModel[]> {
    const dtos = await ApiClient.get<LinkedClassRuleDto[]>(this._baseUrl);

    return LinkedClassRuleModel.fromDtoList(dtos);
  }

  async getById(id: number): Promise<LinkedClassRuleModel> {
    const dtos = await ApiClient.get<LinkedClassRuleDto>(`${this._baseUrl}/${id}`);

    return LinkedClassRuleModel.fromDto(dtos);
  }

  async update(payload: LinkedClassRuleUpdateDto): Promise<LinkedClassRuleModel> {
    const dto = await ApiClient.put<LinkedClassRuleDto>(`${this._baseUrl}/${payload.id}`, payload);
    return LinkedClassRuleModel.fromDto(dto);
  }

  public async bulkAssign(request: LinkedClassRuleAssignmentPayload): Promise<void> {
    return await ApiClient.patch<void>(`${this._baseUrl}/bulk-assign`, request);
  }
}

export const linkedClassRulesService = new LinkedClassRulesService();
