import { ApiClient } from '@/modules/api/base-client';

import { ApplicationSettingsModel, IApplicationService } from './application-contracts';

export class ApplicationService implements IApplicationService {
  private basePath = 'application';

  public getSettings(): Promise<ApplicationSettingsModel> {
    return ApiClient.get<ApplicationSettingsModel>(`${this.basePath}/settings`);
  }
}

export const applicationService = new ApplicationService();
