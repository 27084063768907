import { isEmpty } from 'lodash-es';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../../api/flight-actions.contracts';

const { t } = i18n.global;

export interface SetPromotionPayLoad extends BaseFlightActionPayload {
  cabinCode: CabinCode;
  promotionId: number;
  sequence: number;
}

/**
 * Array consisting of [PromotionId, Sequence]. They can be strings (set by the editor, a cascader), or numbers (set via payload).
 * Empty array support for initial empty selection.
 */
export type SetPromotionValueArray =
  | [
      /** Promotion ID */
      number | string,
      /** Sequence */
      number | string,
    ]
  | [];

export class SetPromotionAction implements FlightAction<SetPromotionPayLoad> {
  public actionType: FlightActionType = FlightActionType.setPromotion;
  public label = t('set_promotion');
  public value: SetPromotionValueArray = [];
  public cabinCode: CabinCode;

  constructor(cabinCode?: CabinCode, value?: SetPromotionValueArray) {
    this.cabinCode = cabinCode;
    if (value) this.value = value;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.setPromotion,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.setPromotion,
        field: FilterFieldField.setPromotion,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.setPromotion,
        uniqueField: this.cabinCode,
        isValueValid: (value: number[]) => !isEmpty(value),
        componentName: ComponentName.SetCabinPromotionActionEditor,
        value: this.value,
        hasNoEditor: false,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value as SetPromotionValueArray;
    this.cabinCode = action.uniqueField as CabinCode;
  }

  public setPayload(payload: SetPromotionPayLoad): void {
    this.value = [payload.promotionId, payload.sequence];
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): SetPromotionPayLoad {
    const flightAction: SetPromotionPayLoad = {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      promotionId: Number.parseInt(this.value[0].toString()),
      sequence: Number.parseInt(this.value[1].toString()),
    };

    return flightAction;
  }
}
