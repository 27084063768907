import { RouteAction, RouteActionPayload, RouteActionType } from './route-actions-contracts';

export interface SetFareCurrencyPayload extends RouteActionPayload {
  fareCurrency: string;
}

export class SetFareCurrencyAction implements RouteAction<SetFareCurrencyPayload> {
  public actionType: RouteActionType = RouteActionType.setFareCurrency;

  public fareCurrency: string;

  constructor(fareCurrency: string) {
    this.fareCurrency = fareCurrency;
  }

  public setPayload(payload: SetFareCurrencyPayload): void {
    this.fareCurrency = payload.fareCurrency;
  }

  public getPayload(): SetFareCurrencyPayload {
    return {
      actionType: this.actionType,
      fareCurrency: this.fareCurrency,
    };
  }
}
