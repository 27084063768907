export const en = {
  audit_log: {
    flight_audit: {
      sources: {
        user: 'User Action',
        automation: 'FlowFx',
        async_update: 'Async Update',
        unkown: 'Unknown',
      },
      download_changes_as_csv: 'Download Changes as CSV',
      lowest_available_fare: 'Lowest Available Fare',
      class_change_history: 'Class Change History For',
      no_class_changes: 'There were no changes to the Classes on this Flight.',
      error_loading_class_changes:
        'There was an error loading the changes. Please try again. If the error persists, please contact support.',
      showing_protections: 'Showing Protection changes',
      showing_authorization_units: 'Showing Authorization Unit changes',
      user: 'User',
      all: 'All',
      eddy: 'SRM Flex',
      details: 'Details',
      show_changes_made_by: 'Show Changes Made By',
    },
  },
};
