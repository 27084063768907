import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const PACING_CURVE_MANAGEMENT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/pacing-curve-management',
    name: RouteName.pacingCurveManagement,
    meta: {
      authorities: [Authority.PacingCurvesRead],
      title: t('pacing_curve_management.pacing_curve_management'),
    },
    component: () => import('../views/PacingCurveManagementView.vue'),
  },
  {
    path: 'settings/pacing-curve-management/:id/details',
    name: RouteName.pacingCurveEdit,
    meta: {
      authorities: [Authority.PacingCurvesUpdate],
      title: t('pacing_curve_management.pacing_curve_management'),
    },
    component: () => import('../views/PacingCurveManagementDetailsView.vue'),
  },
  {
    path: 'settings/pacing-curve-management/create',
    name: RouteName.pacingCurveCreate,
    meta: {
      authorities: [Authority.PacingCurvesUpdate],
      title: t('pacing_curve_management.pacing_curve_management'),
    },
    component: () => import('../views/PacingCurveManagementDetailsView.vue'),
  },
];
