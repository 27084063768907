import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const USER_MANAGEMENT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/user-management',
    name: RouteName.userManagement,
    meta: {
      authorities: [Authority.UserRead],
      title: t('user_management.titles.user_management'),
    },
    component: () => import('@/modules/user-management/views/UserManagement.vue'),
  },
];
