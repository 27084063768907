import { AnalyticsUser, IAnalytics, IErrorLogger } from '@/modules/monitoring/types/logger.types';

export class NoopLogService implements IErrorLogger, IAnalytics {
  public async error(): Promise<void> {
    // eslint
  }

  public async trackEvent(): Promise<void> {
    // eslint
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async setUser(user: AnalyticsUser): Promise<void> {
    // eslint
  }

  public async clearUser(): Promise<void> {
    // eslint
  }
}
