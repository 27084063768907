import axios from 'axios';

import { Environment } from '@/environment';
import { ApiClient } from '@/modules/api/base-client';
import {
  ApiPssCapabilities,
  CustomerSettingsModel,
  ICustomerSettingsService,
  MultiFactorAuthentication,
} from '@/modules/customer-settings/api/customer-settings.contracts';

export class CustomerSettingsService implements ICustomerSettingsService {
  private basePath = 'customer-settings';

  public async get(): Promise<CustomerSettingsModel<ApiPssCapabilities>> {
    return await ApiClient.get<CustomerSettingsModel<ApiPssCapabilities>>(`${this.basePath}`);
  }

  public async getMultiFactorAuthentication(): Promise<MultiFactorAuthentication> {
    return axios({
      method: 'get',
      url: '/multi-factor-authentication',
      baseURL: Environment.oauthUrl,
      headers: {
        Accept: 'application/json',
      },
    }).then((res) => res.data);
  }
}

export const customerSettingsService = new CustomerSettingsService();
