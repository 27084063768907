import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { CabinCode, CabinStructure, ClassStructure } from '@/modules/api/application/application-contracts';
import { EddyBaseError } from '@/modules/api/base-client';
import { FlightLineModel, SlimFlightLineModel } from '@/modules/api/flight/flight-contracts';
import { CustomerSettingsModel } from '@/modules/customer-settings/api/customer-settings.contracts';
import { UpdateAuthorizationUnitsAction } from '@/modules/flight-actions/actions/cabin-actions/update-authorization-units-action';
import { UpdateProtectionAction } from '@/modules/flight-actions/actions/cabin-actions/update-protections-action';
import { BaseFlightActionPayload, FlightAction, FlightActionType, PinState } from '@/modules/flight-actions/api/flight-actions.contracts';
import { flightActionService } from '@/modules/flight-actions/api/flight-actions.service';
import { FilterFieldDefinition, FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { ErrorCode, InvalidOPAssignmentErrorResponse, MessageService } from '@/modules/shared';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';
import { ITag } from '@/modules/tags';
import { useTagsStore } from '@/modules/tags/store/tags.store';
import { i18n } from '@/plugins/i18n';
import { useAppSettingsStore } from '@/store/modules/app-settings.store';
import { useFlightStore } from '@/store/modules/flight.store';

const { t } = i18n.global;

export const useFlightActionsStore = defineStore('flightActions', () => {
  const isLoading: Ref<boolean> = ref(false);
  const flightStore = useFlightStore();

  function findAction<T extends BaseFlightActionPayload>(
    flightActionType: FlightActionType,
    flightActions: BaseFlightActionPayload[],
  ): T | undefined {
    return flightActions.find((action) => action.actionType === flightActionType) as T | undefined;
  }

  function $reset(): void {
    isLoading.value = false;
  }

  async function apply(payload: {
    actions: FilterFieldDefinition[];
    flightLines: SlimFlightLineModel[];
    customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>;
  }): Promise<void> {
    try {
      isLoading.value = true;

      await flightActionService.applyActions(payload.actions as FlightActionDefinition[], payload.flightLines, {
        inventoryConfigurationProperties: useAppSettingsStore().inventoryConfigurationProperties,
        pssCapabilities: payload.customerSettings.pssCapabilities,
        priceIncrementRange: payload.customerSettings.priceIncrementRange as number[],
      });

      /**
       * Fetch new tags if tags are created as a result of the actions being applied
       */
      const createdNewTags = (payload.actions as FlightActionDefinition[])
        .filter(({ flightActionType }) => flightActionType === FlightActionType.tagsAdd)
        .flatMap(({ value }) => value.filter((tag: ITag | string) => typeof tag === 'string'));

      if (createdNewTags.length) {
        // Don't await this, it's not that important
        useTagsStore().get();
      }
    } catch (e) {
      MessageService.failedRequest('Failed to apply flight actions');
    } finally {
      isLoading.value = false;
    }
  }

  async function applyOne(payload: {
    actions: FlightAction<any>[];
    flightLine: FlightLineModel;
    preview: boolean;
    updateWorkingFlight: boolean;
  }): Promise<FlightLineModel> {
    try {
      isLoading.value = true;
      const actions = cloneDeep(payload.actions);

      // AU / PR action should be added at the end since we might have pinned actions -> Pin is King
      const auAction = findAction<UpdateAuthorizationUnitsAction>(FlightActionType.updateAuthorizationUnits, actions);
      if (auAction && auAction.value.classes.some((clazz) => clazz.pinned === PinState.PIN)) {
        const index = payload.actions.findIndex((action) => action.actionType === FlightActionType.updateAuthorizationUnits);
        actions.splice(index, 1);
        actions.push(auAction);
      }

      const prAction = findAction<UpdateProtectionAction>(FlightActionType.updateProtection, actions);
      if (prAction && prAction.value.classes.some((clazz) => clazz.pinned === PinState.PIN)) {
        const index = payload.actions.findIndex((action) => action.actionType === FlightActionType.updateProtection);
        actions.splice(index, 1);
        actions.push(prAction);
      }

      // Sort all classes per cabin, so we can sort on this per action later
      const classesPerCabin: { [key: string]: ClassStructure[] } = {};
      useAppSettingsStore().inventoryConfigurationProperties.cabins.forEach((cabin: CabinStructure) => {
        classesPerCabin[cabin.code] = cabin.classes;
      });

      const classOrder: (flightAction: FlightAction<any>) => number = function (flightAction: FlightAction<any>) {
        return classesPerCabin[flightAction.cabinCode as CabinCode].find(
          (classStructure: ClassStructure) => classStructure.code === flightAction.value?.classes?.[0].code,
        )?.order as number;
      };

      const orderedExecutedActions = actions
        .sort((a, b) => {
          // Check if the action has a cabinCode, if not, keep the order as is.
          if (!a.cabinCode || !b.cabinCode) {
            return 0;
          }
          // Make sure the actions are sorted on each cabin's class order, so top classes actions are handled first in each cabin, and then work down.
          // The order overlaps cabins, so the order already takes in account if there are several cabins.
          // e.g. If cabin Y has class B with order 0, then another cabin can't have any class with order 0 anymore.
          return classOrder(a) < classOrder(b) ? -1 : 1;
        })
        .map(async (action) => action.getPayload?.());

      const response: FlightLineModel = await flightActionService.applyActionsById(
        await Promise.all(orderedExecutedActions),
        payload.flightLine,
        payload.preview,
      );

      if (payload.updateWorkingFlight) {
        flightStore.updateWorkingFlight(response);
        flightStore.setSelectedFlightLine(flightStore.selectedFlightLineCode);
      }

      return response;
    } catch (e) {
      const invalidOpAssignmentErr = (e as EddyBaseError)?.response?.data?.errors?.find(
        (error) => error.errorCode === ErrorCode.InvalidOptimizationProfileAssignment,
      );
      if (invalidOpAssignmentErr) {
        const missingClasses = (invalidOpAssignmentErr as InvalidOPAssignmentErrorResponse).missingClassList.join();
        MessageService.error(`${t('messages.invalid_op_assignment', { classes: missingClasses })}`);
      } else {
        MessageService.failedRequest('Failed to apply flight action');
      }
      throw e;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    apply,
    applyOne,
    $reset,
  };
});
