/** Routes */
import type { RouteRecordRaw } from 'vue-router';

import { APP_ROUTE, APP_ROUTE_DEFAULT } from '@/modules/application/routes';
import { CLUSTER_MANAGEMENT_ROUTES } from '@/modules/cluster-management/routes';
import { CONTROL_ROUTES } from '@/modules/control/routes';
import { CUSTOMER_CONFIG_ROUTES } from '@/modules/customer-config/routes';
import { DASHBOARD_ROUTES } from '@/modules/dashboard/routes';
import { DATA_IMPORT_ROUTES } from '@/modules/data-import/routes';
import { DETAILS_ROUTES } from '@/modules/details/routes';
import { DOCS_ROUTES } from '@/modules/docs/routes';
import { EVENT_MANAGEMENT_ROUTES } from '@/modules/event-management/routes';
import { EXTERNAL_ROUTES } from '@/modules/external/routes';
import { FLOW_FX_ROUTES } from '@/modules/flowfx/routes';
import { INSIGHTS_ROUTES } from '@/modules/insights/routes';
import { PACING_CURVE_MANAGEMENT_ROUTES } from '@/modules/pacing-curve-management/routes';
import { PROMOTIONS_ROUTES } from '@/modules/promotions/routes';
import { REPORTING_ROUTES } from '@/modules/reporting/routes';
import { REVENUE_COMPONENTS_MANAGEMENT_ROUTES } from '@/modules/revenues/routes';
import { ROUTE_MANAGEMENT_ROUTES } from '@/modules/route-management/routes';
import { LINKED_CLASSS_RULES_ROUTES } from '@/modules/rules/linked-class-rules/routes';
import { RULES_ROUTES } from '@/modules/rules/routes';
import { SYSTEM_SETTINGS_ROUTES } from '@/modules/system-settings/routes';
import { USER_MANAGEMENT_ROUTES } from '@/modules/user-management/routes';
import { USER_SETTINGS_ROUTES } from '@/modules/user-settings/routes';

import { RIVAL_ROUTES } from '../rules/rival-rules/routes';

export const ROUTES: RouteRecordRaw[] = [
  {
    path: '',
    redirect: '/app',
  },
  {
    ...APP_ROUTE,
    children: [
      ...DASHBOARD_ROUTES,
      ...CONTROL_ROUTES,
      ...DETAILS_ROUTES,
      ...REPORTING_ROUTES,
      ...PROMOTIONS_ROUTES,
      ...SYSTEM_SETTINGS_ROUTES,
      ...CUSTOMER_CONFIG_ROUTES,
      ...USER_SETTINGS_ROUTES,
      ...USER_MANAGEMENT_ROUTES,
      ...ROUTE_MANAGEMENT_ROUTES,
      ...EVENT_MANAGEMENT_ROUTES,
      ...CLUSTER_MANAGEMENT_ROUTES,
      ...PACING_CURVE_MANAGEMENT_ROUTES,
      ...DATA_IMPORT_ROUTES,
      ...FLOW_FX_ROUTES,
      ...RULES_ROUTES,
      ...LINKED_CLASSS_RULES_ROUTES,
      ...RIVAL_ROUTES,
      ...REVENUE_COMPONENTS_MANAGEMENT_ROUTES,
      ...INSIGHTS_ROUTES,
      ...DOCS_ROUTES,
    ],
  },
  {
    ...APP_ROUTE_DEFAULT,
  },
  ...EXTERNAL_ROUTES,
  { path: '/:pathMatch(.*)*', redirect: '/app' },
];
