import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

function hasReportingEnabled(): boolean {
  const customerSettingsStore = useCustomerSettingsStore();
  return customerSettingsStore.settings!.hasReportingEnabled;
}

const { t } = i18n.global;

export const REPORTING_ROUTES: RouteRecordRaw[] = [
  {
    path: 'insight',
    redirect: { name: RouteName.reportingListing },
    meta: {
      isAllowed: hasReportingEnabled,
      title: t('reporting.titles.reporting'),
    },
    children: [
      {
        path: 'reporting/create',
        name: RouteName.reportingCreate,
        meta: {
          authorities: [Authority.FlightRead],
        },
        component: () => import('@/modules/reporting/views/ReportingEditorView.vue'),
      },
      {
        path: 'reporting/:id/edit',
        name: RouteName.reportingEdit,
        meta: {
          authorities: [Authority.FlightRead],
        },
        component: () => import('@/modules/reporting/views/ReportingEditorView.vue'),
      },
      {
        path: 'reporting/listing',
        name: RouteName.reportingListing,
        meta: {
          authorities: [Authority.FlightRead],
        },
        component: () => import('@/modules/reporting/views/ReportingListingView.vue'),
      },
    ],
  },
];
