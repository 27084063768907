import { RouteAction, RouteActionPayload, RouteActionType } from './route-actions-contracts';

export interface SetDirectionPayload extends RouteActionPayload {
  direction: string;
}

export class SetDirectionAction implements RouteAction<SetDirectionPayload> {
  public actionType: RouteActionType = RouteActionType.setDirection;

  public direction: string;

  constructor(direction: string) {
    this.direction = direction;
  }

  public setPayload(payload: SetDirectionPayload): void {
    this.direction = payload.direction;
  }

  public getPayload(): SetDirectionPayload {
    return {
      actionType: this.actionType,
      direction: this.direction,
    };
  }
}
