<template>
  <el-select :model-value="modelValue" placeholder="Select" :disabled="disabled" data-test="unit-select" @change="onChange">
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
  </el-select>
</template>

<script lang="ts">
import { CascaderOption } from 'element-plus';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { LinkedClassRuleUnit } from '../api/linked-class-rules.contracts';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const { t } = useI18n();
    const units = Object.values(LinkedClassRuleUnit);
    const options: CascaderOption[] = units.map((unit) => ({
      label: t(`linked_class_rules.enum_linked_class_rule_unit.${unit}`),
      value: unit,
    }));

    return {
      options,
    };
  },
  methods: {
    onChange(newValue: string) {
      this.$emit('update:modelValue', newValue);
    },
  },
});
</script>

<style lang="scss">
.el-input .el-input__inner {
  text-overflow: ellipsis;
}
</style>
