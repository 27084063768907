import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../../api/flight-actions.contracts';
import { isValueNotEmpty } from '../validation-options';

const { t } = i18n.global;

export interface SetRecommendedOverbookingRiskPercentagePayload extends BaseFlightActionPayload {
  cabinCode: CabinCode;
  value: number;
  applyRecommendedOverbookingFactor: boolean;
  applyRecommendedAU: boolean;
  recommendedOverbookingFactor?: number;
  legNumber?: number;
}

export class SetRecommendedOverbookingRiskPercentageAction implements FlightAction<SetRecommendedOverbookingRiskPercentagePayload> {
  public actionType: FlightActionType;
  public label = t('set_recommended_overbooking_risk_percentage');

  public value;
  public cabinCode: CabinCode;
  public applyRecommendedOverbookingFactor = true;
  public applyRecommendedAU = true;
  public recommendedOverbookingFactor: number | undefined;
  public legNumber: number | undefined;
  public fieldType: FilterFieldField = FilterFieldField.setRecommendedOverbookingRiskPercentage;

  constructor(cabinCode?: CabinCode, legNumber?: number) {
    this.cabinCode = cabinCode ?? 'Y';
    this.legNumber = legNumber;
    this.setFlightActionType(legNumber);

    const customerSettingsStore = useCustomerSettingsStore();
    this.value = customerSettingsStore.settings!.defaultOverbookingRiskPercentage;
  }

  public get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + this.fieldType,
      meta: {
        cabinCode: this.cabinCode,
        label: this.label,
        flightActionType: this.actionType,
        field: this.fieldType,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + this.fieldType,
        uniqueField: this.cabinCode,
        componentName: ComponentName.SetRecommendedOverbookingRiskPercentageFilterField,
        value: {
          value: this.value,
          applyRecommendedOverbookingFactor: this.applyRecommendedOverbookingFactor,
          applyRecommendedAU: this.applyRecommendedAU,
          recommendedOverbookingFactor: this.recommendedOverbookingFactor,
          legNumber: this.legNumber,
        } as SetRecommendedOverbookingRiskPercentagePayload,
        warning: t('bulk_overbooking_change'),
        isValueValid: (value: any) => isValueNotEmpty(value),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition<SetRecommendedOverbookingRiskPercentagePayload>): void {
    const payload = action.value;
    if (payload) {
      this.value = payload.value;
      this.legNumber = payload.legNumber;
      this.setFlightActionType(payload.legNumber);
      this.applyRecommendedOverbookingFactor = payload.applyRecommendedOverbookingFactor;
      this.applyRecommendedAU = payload.applyRecommendedAU;
      this.recommendedOverbookingFactor = payload.recommendedOverbookingFactor;
    }
    this.cabinCode = action.uniqueField as CabinCode;
  }

  public setPayload(payload: SetRecommendedOverbookingRiskPercentagePayload): void {
    this.value = payload.value;
    this.cabinCode = payload.cabinCode;
    this.applyRecommendedOverbookingFactor = payload.applyRecommendedOverbookingFactor;
    this.applyRecommendedAU = payload.applyRecommendedAU;
    this.recommendedOverbookingFactor = payload.recommendedOverbookingFactor;
    this.legNumber = payload.legNumber;
    this.setFlightActionType(payload.legNumber);
  }

  public getPayload(): SetRecommendedOverbookingRiskPercentagePayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      applyRecommendedOverbookingFactor: this.applyRecommendedOverbookingFactor,
      applyRecommendedAU: this.applyRecommendedAU,
      recommendedOverbookingFactor: this.recommendedOverbookingFactor,
      legNumber: this.legNumber,
      value: this.value,
    };
  }

  private setFlightActionType(legNumber: number | undefined): void {
    this.actionType = legNumber
      ? FlightActionType.setMultiLeggedRecommendedOverbookingRiskPercentage
      : FlightActionType.setRecommendedOverbookingRiskPercentage;
  }
}
