<template>
  <router-link
    class="font-medium text-sm block leading-10 px-5 k-tabs-router-link"
    :to="routeOptions"
    :data-dd-action-name="`Tab: ${routeName}`"
  >
    <slot></slot>
  </router-link>
</template>

<script lang="ts" setup>
import { ComputedRef } from 'vue';
import { computed } from 'vue';
import { RouteLocationRaw } from 'vue-router';

const props = defineProps<{
  routeOptions: RouteLocationRaw;
}>();

const routeName: ComputedRef<string> = computed(() =>
  typeof props.routeOptions !== 'string' && 'name' in props.routeOptions ? props.routeOptions.name?.toString() ?? '' : '',
);
</script>

<style lang="scss" scoped>
.k-tabs-router-link {
  color: unset;
  box-shadow: 0 0.125rem 0 theme('colors.primary/0');
  transition: box-shadow 0.3s ease-in-out;

  &.router-link-active,
  &:hover {
    @apply text-primary;
    box-shadow: 0 0.125rem 0 theme('colors.primary/1');
  }
}
</style>
