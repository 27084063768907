export const en = {
  event_management: {
    titles: {
      event_management: 'Event Management',
      inclusion_events: 'Inclusion Events',
      exclusion_events: 'Exclusion Events',
      add_event: 'Add Event',
      edit_event: 'Edit Event',
      copy_event: 'Copy Event – {name}',
      routes: 'Routes',
      confirm_event_deletion: '{eventName} Deletion',
    },
    state: {
      loading_events: 'Loading Events',
      loading_routes: 'Loading Routes',
      editing_event: 'Editing Event',
      adding_event: 'Adding Event',
      assigning_routes: 'Assigning Routes',
      event_added: 'Event Added',
      event_edited: 'Event Edited',
      event_copied: 'Event Successfully Copied',
      routes_assigned: 'Routes Assigned',
      routes_selected: 'No routes selected | {n} route selected | {n} routes selected',
      checked: 'Checked',
      unchecked: 'Unchecked',
      event_deleted: 'Event successfully deleted',
      event_not_deleted: 'Failed to delete event',
    },
    info: {
      confirm_event_deletion:
        'Would you like to delete this event? | This event is assigned to one route. Would you like to delete this event? | This event is assigned to {n} routes. Would you like to delete this event?',
    },
    labels: {
      events: 'Events',
      inclusion_events: 'Inclusion Events',
      exclusion_events: 'Exclusion Events',
      event_name: 'Event Name',
      event_names: 'Event Names',
      event_cluster: 'Event Cluster',
      event_clusters: 'Event Clusters',
      event_departure_date_range: 'Event Departure Date Range',
      impact_departure_date_range: 'Impact Departure Date Range',
      sale_date_range: 'Sale Date Range',
      search_routes: 'Search Routes',
      assigned_events: 'Assigned Events',
      with_events: 'With Events',
      without_events: 'Without Events',
      calendar_event_cluster: {
        major_holiday: 'Major Holiday',
        minor_holiday: 'Minor Holiday',
        major_event: 'Major Event',
        minor_event: 'Minor Event',
        school_break: 'School Break',
        sale_date_exclusion: 'Sale Date Exclusion',
        departure_date_exclusion: 'Departure Date Exclusion',
        sale_and_departure_date_exclusion: 'Sale And Departure Date Exclusion',
      },
      calendar_event_cluster_info: {
        demand_booking: 'The event is expected to have impact on the demand volume and booking curve shape',
        sale_dates: 'Sale dates will be excluded',
        departure_dates: 'Departure dates will be excluded',
        sale_departure_dates: 'Sale and departure dates will be excluded',
      },
      keep_routes_assigned: 'Keep the routes assigned',
      copy: ' (Copy)',
    },
    tooltips: {
      calendar_event_cluster_info: {
        major_holiday:
          'There is a significant impact on bookings and revenue performance of a route. A holiday is a period of celebration, or where no work is done.',
        minor_holiday:
          'There is a noticeable impact on bookings and revenue performance of a route. A holiday is a period of celebration, or where no work is done.',
        major_event:
          'There is a significant impact on bookings and revenue performance of a route. An event is something that happens, especially something noteworthy or unusual.',
        minor_event:
          'There is a noticeable impact on bookings and revenue performance of a route. An event is something that happens, especially something noteworthy or unusual.',
        school_break:
          'The impact on bookings and revenue performance of a route can be noticeable or significant. During a school break, schools are closed, or no classes are held.',
        sale_date_exclusion: 'Exclude the selected sale date(s) from the forecast.',
        departure_date_exclusion: 'Exclude the selected departure date(s) from the forecast.',
        sale_and_departure_date_exclusion: 'Exclude the selected sale and departure date(s) from the forecast.',
      },
    },
    actions: {
      apply_changes: 'Apply Changes',
      add_event: 'Add Event',
      save_event: 'Save Event',
      cancel: 'Cancel',
      edit: 'Edit',
      copy: 'Copy',
      delete: 'Delete',
      assign_routes: 'Assign Routes',
      view_assigned_routes: 'View Assigned Routes',
    },
    errors: {
      display_name_required: 'Display Name is required',
      event_cluster_required: 'Event Cluster is required',
      event_date_range_required: 'The Event Date Range is required',
      impact_date_range_required: 'The Impact Date Range is required',
      sale_date_range_required: 'The Sale Date Range is required',
      impact_date_range_out_of_bounds: 'The Impact Date Range should cover the Event Date Range',
    },
    routes_table_columns: {
      flight_key: 'Route Key',
      carrier_code: 'Carrier Code',
      origin: 'Origin',
      destination: 'Destination',
      origin_country_code: 'Origin Country Code',
      destination_country_code: 'Destination Country Code',
    },
    overview_table_columns: {
      event_name: 'Event Name',
      event_cluster: 'Event Cluster',
      event_start_date: 'Event Start Date',
      event_end_date: 'Event End Date',
      impact_start_date: 'Impact Start Date',
      impact_end_date: 'Impact End Date',
      sales_start_date: 'Sale Start Date',
      sales_end_date: 'Sale End Date',
      modified_by: 'Modified By',
      modified: 'Modified',
      assigned_routes: 'Assigned Routes',
    },
    plurals: {
      routes: '{n} route | {n} routes',
    },
  },
};
