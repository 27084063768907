import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const CONTROL_ROUTES: RouteRecordRaw[] = [
  {
    path: 'flights',
    redirect: { name: RouteName.control },
    children: [
      {
        path: 'control',
        name: RouteName.control,
        meta: {
          authorities: [Authority.FlightRead],
          title: t('control.titles.flight_control'),
        },
        component: () => import('@/modules/control/views/FlightControl.vue'),
      },
      {
        path: 'control/review',
        name: RouteName.review,
        meta: {
          authorities: [Authority.FlightRead],
          title: t('control.titles.flight_review'),
        },
        component: () => import('@/modules/control/views/FlightReview.vue'),
      },
      // Redirect in place so user's bookmarks still work
      {
        path: 'details',
        redirect: { name: RouteName.details },
      },
    ],
  },
];
