import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const DETAILS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'details',
    name: RouteName.details,
    meta: {
      authorities: [Authority.FlightRead],
      title: t('details.titles.flight_details'),
    },
    component: () => import('../views/FlightDetailsView.vue'),
  },
];
