export enum DefaultRouteManagementActionType {
  AssignUser = 'AssignUser',
  ReassignUser = 'ReassignUser',
  UnassignUser = 'UnassignUser',
  SetCFWindow = 'SetCFWindow',
  SetFareCurrency = 'SetFareCurrency',
  SetDirection = 'SetDirection',
  AssignRouteGroup = 'AssignRouteGroup',
  UnassignRouteGroup = 'UnassignRouteGroup',
  SetPriceRanges = 'SetPriceRanges',
  UserAssignment = 'UserAssignment',
  RouteAssignment = 'RouteAssignment',
  ClusterBulkAssign = 'ClusterBulkAssign',
  ForecastAndOptimisation = 'ForecastAndOptimisation',
  EnableForecastGeneration = 'EnableForecastGeneration',
  DisableForecastGeneration = 'DisableForecastGeneration',
  EnableForecastLearning = 'EnableForecastLearning',
  DisableForecastLearning = 'DisableForecastLearning',
}

export enum OpLevelAdjustmentIncrementActionType {
  BYOROpLevelAdjustIncrement = 'BYOROpLevelAdjustIncrement',
  DynamicProgramOpLevelAdjustIncrement = 'DynamicProgramOpLevelAdjustIncrement',
}

export const RouteManagementActions = { ...DefaultRouteManagementActionType, ...OpLevelAdjustmentIncrementActionType };
export type RouteManagementActionTypeKey = keyof typeof RouteManagementActions;
export type RouteManagementActionType = (typeof RouteManagementActions)[RouteManagementActionTypeKey];
