import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { FeaturesModel } from '@/modules/features';
import { featuresService } from '@/modules/features/api/features/features.service';
import { MessageService } from '@/modules/shared';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const useFeaturesStore = defineStore('features', () => {
  const features: Ref<FeaturesModel> = ref({});

  async function get(): Promise<void> {
    try {
      features.value = await featuresService.get();
    } catch {
      MessageService.error(t('features.errors.failed_to_fetch_features'));
    }
  }

  function $reset(): void {
    features.value = {};
  }

  return {
    features,
    get,
    $reset,
  };
});
