import { loadErrorCodeMessages } from '@/modules/shared/types/error-codes';

export * from './services/message.service';
export * from './types';
export * from './utils/api-errors.util';
export * from './utils/format-number.util';

export function initSharedModule(): void {
  loadErrorCodeMessages();
}
