import { ApiClient } from '@/modules/api/base-client';

import { ICompetitorFaresService } from './competitor-fares-contracts';

export class CompetitorFaresService implements ICompetitorFaresService {
  private basePath = 'competitive-fares';

  public async getAllCarriers(): Promise<string[]> {
    return await ApiClient.get<string[]>(`${this.basePath}/carriers`);
  }
}

/**
 * CompetitorFaresService is a service that calls the '/competitive-fares' part of the api
 */
export const competitorFaresService = new CompetitorFaresService();
