import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FlightLineCabin } from '@/modules/api/flight/flight-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import {
  AvailabilityDistribution,
  BaseFlightActionPayload,
  FlightAction,
  FlightActionType,
  MoveToCompetitorClass,
  MoveToCompetitorType,
  PriceAdjustmentType,
} from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';
import { FlightService } from '@/services/flight.service';

const { t } = i18n.global;

export interface MatchCompetitorPayload extends BaseFlightActionPayload {
  cabinCode: CabinCode;
  matchRule: MoveToCompetitorClass;
  matchType: MoveToCompetitorType;
  carrier?: string;
  availabilityDistribution: AvailabilityDistribution;
  seatsAvailable: number;
  priceAdjustmentType: PriceAdjustmentType;
  // absolute and relative price adjustments are based on the priceAdjustmentType
  absolutePriceAdjustments?: AbsolutePriceAdjustment[];
  relativePriceAdjustment?: number;
  fareValueBoundaries?: FareValueBoundary[];
  maxClassBoundary?: string;
  minClassBoundary?: string;
}

export interface AbsolutePriceAdjustment {
  currency: string;
  value: number;
}

export interface FareValueBoundary {
  currency: string;
  min?: number | null;
  max?: number | null;
}

export class MatchCompetitorAction implements FlightAction<MatchCompetitorPayload> {
  public actionType: FlightActionType;
  public filterFieldFieldType: FilterFieldField;
  public label = t('dynamic_filters.set_laf_based_on_competitor');
  public cabinCode: CabinCode;
  public value: MatchCompetitorPayload;

  constructor(cabinCode?: CabinCode) {
    this.cabinCode = cabinCode;
    this.actionType = FlightActionType.matchCompetitor;
    this.filterFieldFieldType = FilterFieldField.matchCompetitor;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      value: this.cabinCode + this.filterFieldFieldType,
      label: this.label,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: this.actionType,
        field: this.filterFieldFieldType,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + this.filterFieldFieldType,
        uniqueField: this.cabinCode,
        value: this.getCascaderMetaValues(),
        componentName: ComponentName.MatchCompetitorField,
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/MatchCompetitorActionRender.vue'),
        ),
        isValueValid: () => true,
      },
    };
  }

  public getCascaderMetaValues(): MatchCompetitorPayload {
    return {
      actionType: this.actionType,
      matchRule: MoveToCompetitorClass.CLOSEST,
      matchType: MoveToCompetitorType.MINCF,
      cabinCode: this.cabinCode,
      availabilityDistribution: AvailabilityDistribution.MAINTAIN_PROTECTIONS,
      seatsAvailable: 1,
      priceAdjustmentType: PriceAdjustmentType.RELATIVE,
      relativePriceAdjustment: 0,
      absolutePriceAdjustments: [],
      // Overwrite the above default values with the actual set value
      ...this.value,
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
    this.cabinCode = action.uniqueField as CabinCode;
  }

  public setPayload(payload: MatchCompetitorPayload): void {
    this.value = payload;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): MatchCompetitorPayload {
    const { relativePriceAdjustment, absolutePriceAdjustments, ...destructuredObject } = this.value;

    return {
      ...destructuredObject,
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      ...(this.value.priceAdjustmentType === PriceAdjustmentType.ABSOLUTE && { absolutePriceAdjustments }),
      ...(this.value.priceAdjustmentType === PriceAdjustmentType.RELATIVE && { relativePriceAdjustment }),
    };
  }

  private getLowestFlightFare(type: MoveToCompetitorType | string, cabin: FlightLineCabin, carrier?: string): number | undefined {
    switch (type) {
      case MoveToCompetitorType.MINCF:
        return FlightService.getMinCompetitiveFareOfCabin(cabin);
      case MoveToCompetitorType.MINRIVALCF:
        return FlightService.getMinRivalFareOfCabin(cabin);
      case MoveToCompetitorType.CARRIER:
        return FlightService.getMinSpecificCarrierFareOfCabin(cabin, carrier);
    }
  }
}
