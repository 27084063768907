<template>
  <div class="breadcrumb" data-test="breadcrumb">
    <div v-for="(crumb, index) in crumbs" :key="index" class="breadcrumb__item" data-test="crumb">
      <div v-if="isLastCrumb(index) && isNameEditable" class="breadcrumb__item--editable">
        <input
          ref="input"
          v-autowidth="{ maxWidth: '960px', minWidth: '20px', comfortZone: 0 }"
          type="text"
          class="breadcrumb__name-input"
          :class="{ invalid: !valid }"
          data-test="breadcrumb-input"
          :placeholder="crumb.displayName"
          :value="crumb.displayName"
          :size="`${crumb.displayName.length || 1}`"
          @focus="onFocus"
          @input="onNameChange"
          @keyup.enter="onLoseFocus"
          @keyup.esc="onLoseFocus"
        />
        <div class="breadcrumb__edit-icon" @click="onFocus">
          <font-awesome-icon :icon="['fas', 'pen']" />
        </div>
      </div>

      <div v-else-if="isLastCrumb(index)" class="text-lg font-bold">
        {{ crumb.displayName }}
      </div>

      <router-link v-else class="breadcrumb__item--link text-lg font-bold" :to="crumb.to">
        {{ crumb.displayName }}
      </router-link>

      <font-awesome-icon v-if="!isLastCrumb(index)" :icon="['far', 'chevron-right']" class="mx-1" size="xs" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

import { BreadCrumbInterface } from '@/modules/shared';

const props = withDefaults(
  defineProps<{
    crumbs?: BreadCrumbInterface[];
    isNameEditable?: boolean;
    valid?: boolean;
  }>(),
  {
    crumbs: (): BreadCrumbInterface[] => [],
    valid: true,
    isNameEditable: false,
  },
);

const emit = defineEmits<{ (e: 'nameChange', v: string): void }>();

const input = ref<HTMLInputElement[] | undefined>();

function onFocus() {
  input.value?.[0].select();
}

function onLoseFocus() {
  input.value?.[0].blur();
}

function isLastCrumb(index: number) {
  return index === props.crumbs.length - 1;
}

function onNameChange($event: any) {
  const value = $event.target?.value ?? '';
  emit('nameChange', value);
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  justify-content: flex-start;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--link {
      @apply text-primary;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &--editable {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__edit-icon {
    margin-left: 5px;
    @apply text-primary;
    font-size: 12px;
    cursor: pointer;
  }

  &__name-input {
    @apply border-transparent border rounded font-bold text-lg;
    transition: border-color 0.6s;

    &.invalid {
      @apply border-red;
    }

    &:hover {
      @apply border-primary text-primary;
    }
  }
}
</style>
