import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue3';
import { App, Plugin } from 'vue';

export const AgGrid: Plugin = {
  install(app: App) {
    LicenseManager.setLicenseKey(
      'Using_this_AG_Grid_Enterprise_key_( AG-042019 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Kambr )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Eddy )_only_for_( 4 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Eddy )_need_to_be_licensed___( Eddy )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 1 June 2024 )____[v2]_MTcxNzE5NjQwMDAwMA==1c524b9ec0040a927ed541014c80ea72',
    );

    app.component('AgGridVue', AgGridVue);
  },
};
