import { UiPssCapabilities } from '../pss-capabilities';

export const PssCapabilities1a: UiPssCapabilities = {
  hasRevenue: false,
  hasFlightClosed: true,
  hasFlags: true,
  hasEffectiveDate: true,
  hasDiscontinueDate: true,
  hasWaitlist: true,
  hasGroupWaitlist: true,
  hasGroupPending: true,
  hasFareValue: false,
  hasMinThreshold: true,
  hasSegmentLimit: true,
  hasUnbalancedAdjustments: true,
  hasLeftover: false,
  hasDynamicPricing: false,
};
