<template>
  <div class="flex flex-col top-0 bg-white h-full">
    <header class="flex justify-between items-center grow-0 shrink-0 p-4 z-40 border-b-gray-100 border-b">
      <div class="h-full flex items-center">
        <slot name="title">
          <h1 class="text-lg font-bold">{{ title }}</h1>
        </slot>
      </div>

      <div class="h-full flex items-center justify-end">
        <slot name="menu"></slot>
      </div>
    </header>

    <main ref="contentRef" class="p-4 grow overflow-y-auto">
      <slot name="content"></slot>
    </main>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';

const contentRef = ref<Element>();

defineProps<{
  /**
   * Provide a title or use #title slot.
   */
  title?: string;
}>();

const emit = defineEmits<{ (e: 'contentMounted', v: Element): void }>();

onMounted(() => {
  if (contentRef.value) emit('contentMounted', contentRef.value);
});
</script>
