import { RouteAction, RouteActionPayload, RouteActionType } from './route-actions-contracts';

export interface SetForecastGenerationPayload extends RouteActionPayload {
  forecastGeneration: boolean;
}

export class SetForecastGenerationAction implements RouteAction<SetForecastGenerationPayload> {
  public actionType: RouteActionType = RouteActionType.setForecastGeneration;

  public forecastGeneration: boolean;

  constructor(forecastGeneration: boolean) {
    this.forecastGeneration = forecastGeneration;
  }

  public setPayload(payload: SetForecastGenerationPayload): void {
    this.forecastGeneration = payload.forecastGeneration;
  }

  public getPayload(): SetForecastGenerationPayload {
    return {
      actionType: this.actionType,
      forecastGeneration: this.forecastGeneration,
    };
  }
}
