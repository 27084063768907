export const en = {
  tour_operator_details: {
    titles: {
      tour_operator_details: 'Tour Operator Details',
    },
    table_columns: {
      tour_operator_name: 'Tour Operator Name',
      cabin_code: 'Cabin',
      contract_type: 'Contract Type',
      contract_code: 'Contract Code',
      initial: 'Initial',
      confirmed: 'Confirmed',
      sold: 'Sold',
      available: 'Available',
      initial_utilization: 'Initial Utilization',
      confirmed_utilization: 'Confirmed Utilization',
      initial_rate: 'Initial Rate',
      confirmed_rate: 'Confirmed Rate',
      sold_revenue: 'Sold Revenue',
      sold_average_rate: 'Sold Average Rate',
      total_avg: 'Total/Avg',
    },
  },
};
